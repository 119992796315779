import { Actions, ofType, createEffect } from '@ngrx/effects';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Injectable } from '@angular/core';
import { SnackBarServiceService } from '@trakr-safety/core/services/snack-bar-service.service';
import * as QuestionActions from '@trakr-safety/questions/store/questions.actions';
import { QuestionsHttpClient } from '../httpclients/questions.http-client';

@Injectable()
export class QuestionsEffects {
  constructor(
    private snackBarService: SnackBarServiceService,
    private actions$: Actions,
    private httpClient: QuestionsHttpClient) { }

  /** move question */
  postMoveQuestion$ = createEffect(() => this.actions$.pipe(
    ofType(QuestionActions.moveQuestion),
    switchMap(({ sortData, langCode }) =>
      this.httpClient.updateQuestionOrderIndex(sortData, langCode)
        .pipe(
          map((resData: any) => resData.data),
          map((questions) => {
            //return QuestionActions.setQuestions({ questions });
            return QuestionActions.fetchAllQuestions({ langCode: langCode });
          }),
          catchError(error => {
            return of(QuestionActions.error({ error: error.error.message }));
          })
        ))
  ));

  /** add question */
  addQuestion$ = createEffect(() => this.actions$.pipe(
    ofType(QuestionActions.addQuestion),
    switchMap(({ questionData }) =>
      this.httpClient.createQuestion(questionData)
        .pipe(
          map((resData: any) => resData.data),
          map((questions) => {
            this.snackBarService.popSnack('alerts.created.success.text', true);
            //  return QuestionActions.setQuestions({ questions });
            return QuestionActions.fetchAllQuestions({ langCode: questionData.languageCode });
          }),
          catchError(error => {
            return of(QuestionActions.error({ error: error.error.message }));
          })
        ))
  ));

  /** update question */
  updateQuestion$ = createEffect(() => this.actions$.pipe(
    ofType(QuestionActions.updateQuestion),
    switchMap(({ questionData }) =>
      this.httpClient.updateQuestion(questionData)
        .pipe(
          map(_ => {
            this.snackBarService.popSnack('alerts.updated.success.text', true);
            return QuestionActions.fetchAllQuestions({ langCode: questionData.languageCode });
          }),
          catchError(error => {
            return of(QuestionActions.error({ error: error.error.message }));
          })
        ))
  ));

  /** delete question */
  deleteQuestion$ = createEffect(() => this.actions$.pipe(
    ofType(QuestionActions.deleteQuestion),
    switchMap(({ questionId, langCode }) =>
      this.httpClient.softDeleteQuestion(questionId)
        .pipe(
          map(_ => {
            this.snackBarService.popSnack('alerts.deleted.success.text', true);
            return QuestionActions.fetchAllQuestions({ langCode });
          }),
          catchError(error => {
            return of(QuestionActions.error({ error: error.error.message }));
          })
        ))
  ));

  /** fetch questions */
  fetchAllQuestions$ = createEffect(() => this.actions$.pipe(
    ofType(QuestionActions.fetchAllQuestions),
    switchMap(({ langCode }) =>
      this.httpClient.fetchAllQuestions(langCode)
        .pipe(
          map((resData: any) => resData.data),
          map(({ questions, usingDefaultLanguageSet, defaultLanguage }) => {
            return QuestionActions.setDefaultQuestions({ questions, usingDefaultLanguageSet, defaultLanguage });
          }),
          catchError(error => {
            if (error.status && error.status === 401) {
              return of(QuestionActions.nothing());
            } else {
              return of(QuestionActions.error({ error: error.error.message }));
            }
          })
        ))
  ));
}
