import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

export enum Icons {
  ADMIN = 'admin',
  ATTENDANCE = 'attendance',
  CAR = 'car',
  COMPANY_SETTINGS = 'company_settings',
  CSV = 'csv',
  EXCEL = 'excel',
  EXPORT = 'export',
  GUEST_TRACING = 'guest_tracing',
  HEALTH_SCREENING = 'health_screening',
  HELP = 'help',
  MANAGE_USERS = 'manage_users',
  NEW_USER = 'new_user',
  PASSWORD_RESET = 'password_reset',
  PDF = 'pdf',
  REPORTS = 'reports',
  REPORT_DATA = 'report_data',
  SCREENING_AREAS2 = 'screening_areas2',
  SCREENING_QUESTIONS = 'screening_questions',
  SUPPORT = 'support'
}

@Injectable({
  providedIn: 'root'
})
export class IconsService {

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer) {

  }
  public registerIcons(): void {
    this.loadIcons(Object.values(Icons), '../assets/site/svg/icons');
  }

  private loadIcons(iconKeys: string[], iconUrl: string): void {
    iconKeys.forEach(key => {
      this.matIconRegistry.addSvgIcon(key, this.domSanitizer.bypassSecurityTrustResourceUrl(`${iconUrl}/${key}.svg`));
    });
  }
}
