import { NgModule } from '@angular/core';
import { CustomDatePipe } from './custom-date.pipe';
import { RemoteImagePipe } from './remote-image.pipe';
import { SanitizerUrlPipe } from './sanitizeurl.pipe';
import { TenantKeyPrependPipe } from './tenant-key-prepend.pipe';
import { TypeofPipe } from './Typeof.pipe';
import { ReplaceLineBreaksPipe } from './replace-line-breaks.pipe';
import { TruncatePipeModule } from './truncate/truncate-pipe.module';
import { SafePipeModule } from './safe-pipe/safe-pipe.module';
import { StripHtmlPipeModule } from './strip-html-pipe/strip-html-pipe.module';

const declarations = [
  CustomDatePipe,
  RemoteImagePipe,
  SanitizerUrlPipe,
  TenantKeyPrependPipe,
  TypeofPipe,
  ReplaceLineBreaksPipe
];

const modules = [
  TruncatePipeModule,
  SafePipeModule,
  StripHtmlPipeModule
]

@NgModule({
  declarations: [...declarations],
  imports: [...modules],
  providers: [
    TenantKeyPrependPipe,
    RemoteImagePipe
  ],
  exports: [...declarations, ...modules]
})
export class PipesModule { }
