import { Actions, ofType, createEffect } from '@ngrx/effects';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Injectable } from '@angular/core';
import * as AppSettingsActions from './app-settings.actions';
import { AppSettingsHttpClient } from '@trakr-safety/core/app-settings/httpclients/app-settings.http-client';
import { LanguagesHttpClient } from '@trakr-safety/core/language-selector/httpclients/language.http-client';

@Injectable()
export class AppSettingsEffects {
  constructor(
    private actions$: Actions,
    private languagesHttpClient: LanguagesHttpClient,
    private appSettingsHttpClient: AppSettingsHttpClient) { }

  fetchAppSettings$ = createEffect(() => this.actions$.pipe(
    ofType(AppSettingsActions.fetchAppSettings),
    switchMap(({ languageCode, locationId }) =>
      this.appSettingsHttpClient.fetchAppSettings(languageCode, locationId)
        .pipe(
          map((settings) => {
            return AppSettingsActions.setAppSettings({ settings });
          }),
          catchError(error => of(AppSettingsActions.error({ error: error.error.message })))
        ))
  ));

  fetchCompanies$ = createEffect(() => this.actions$.pipe(
    ofType(AppSettingsActions.fetchCompanies),
    switchMap(() =>
      this.appSettingsHttpClient.fetchCompanies()
        .pipe(
          map((companies) => {
            return AppSettingsActions.setCompanies({ companies });
          }),
          catchError(error => of(AppSettingsActions.error({ error: error.error.message })))
        ))
  ));

  fetchLanguages$ = createEffect(() => this.actions$.pipe(
    ofType(AppSettingsActions.fetchLanguages),
    switchMap(() =>
      this.languagesHttpClient.fetchLanguages()
        .pipe(
          map((languages) => AppSettingsActions.setLanguages({ languages })),
          catchError(error => of(AppSettingsActions.error({ error: error.error.message })))
        ))
  ));
}

