import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { first, timeout, retry } from 'rxjs/operators';
import { FCMToken } from '../models/fcm-token.model';

@Injectable({
  providedIn: 'root'
})
export class FcmTokenService {

  constructor(private _httpClient: HttpClient) { }

  saveFCMToken(fcmToken: FCMToken): Observable<FCMToken> {
    return this._httpClient.post<any>(
      `${environment.baseS2SApiUrl}fcm_tokens`,
      fcmToken,
      {
        headers: new HttpHeaders({
          'api-token': environment.s2sApiToken
        })
      }
    ).pipe(
      first(),
      timeout(10000),
      retry(4));
  }
}
