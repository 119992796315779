import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as fromApp from 'app/store/app.reducer';
import * as TenantActions from '@trakr-safety/tenants/store/tenant.actions';
import * as TenantSelectors from '@trakr-safety/tenants/store/tenant.selectors';
import { TenantModel } from '@trakr-safety/tenants/models/tenant.model';
import { filter, map } from 'rxjs/operators';
import { DataKeys, LocalstorageService } from '@trakr-safety/core/services/localstorage.service';

@Injectable({
  providedIn: 'root'
})
export class TenantFacade {

  constructor(
    private _store: Store<fromApp.AppState>,
    private _localStorageService: LocalstorageService) { }

  get tenant(): TenantModel {
    return JSON.parse(this._localStorageService.get(DataKeys.TENANT));
  }

  get tenantId(): number {
    return Number(this._localStorageService.getNestKeyValue(DataKeys.TENANT, 'orgId'));
  }

  get locationId(): number {
    return Number(this._localStorageService.getNestKeyValue(DataKeys.TENANT, 'locationId'));
  }

  get tenantKey(): string {
    return this._localStorageService.getNestKeyValue(DataKeys.TENANT, 'tenantKey');
  }

  get tenantKeyLive$(): Observable<string> {
    return this._store.select(TenantSelectors.tenantKey).pipe(
      filter((tenantKey) => tenantKey !== undefined && tenantKey !== null));
  }

  get tenantIdLive$(): Observable<number> {
    return this._store.select(TenantSelectors.orgId).pipe(
      filter((orgId) => orgId !== undefined && orgId !== null));
  }

  // actions
  fetchTenant(tenantKey: string) {
    this._store.dispatch(TenantActions.fetchTenant({ tenantKey }));
  }

  setTenant(tenantData: TenantModel) {
    this._store.dispatch(TenantActions.setTenant({
      tenantData
    }));
  }
}
