import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromApp from 'app/store/app.reducer';
import * as AreaSelectors from '@trakr-safety/areas/store/areas.selectors';
import * as AreaActions from '@trakr-safety/areas/store/areas.actions';
import { AreaModel } from '../models/area.model';
import { DataKeys, LocalstorageService } from '@trakr-safety/core/services/localstorage.service';


@Injectable({
  providedIn: 'root'
})
export class AreasFacade {

  // selectors
  get currentTraceArea(): AreaModel {
    return this.pLocalStorageService.check(DataKeys.TRACEAREA) ? JSON.parse(this.pLocalStorageService.get(DataKeys.TRACEAREA)) : null;
  };

  areas$ = this.pStore.select(AreaSelectors.selectAreas);
  currentAreaTrace$ = this.pStore.select(AreaSelectors.selectAreaTrace);
  selectorAreas$ = this.pStore.select(AreaSelectors.selectAreas);

  constructor(
    private pLocalStorageService: LocalstorageService,
    private pStore: Store<fromApp.AppState>) {
  }

  // actions
  fetchAreas() {
    this.pStore.dispatch(AreaActions.fetchAreas());
  }

  fetchSelectorAreas(locationId: number): void {
    this.pStore.dispatch(AreaActions.fetchAreasPublic({ locationId }));
  }

  fetchAreaTracingMonthReport(): void {
    this.pStore.dispatch(AreaActions.fetchTraceAreasMonthReport({ page: 1, pageSize: 10 }));
  }

  fetchTraceArea(areaId: number, locationId: number, tenantKey: string) {
    this.pStore.dispatch(AreaActions.fetchTraceArea({
      id: Number(areaId),
      locationId: locationId,
      tenantKey: tenantKey
    }));
  }

  replaceTraceArea(area: AreaModel) {
    this.pStore.dispatch(AreaActions.replaceState({ payload: { areaTrace: area } }));
  }

  deleteArea(id: number): void {
    this.pStore.dispatch(AreaActions.deleteArea({ id }));
  }

}

