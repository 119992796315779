import { AttendAreaModel } from '@trakr-safety/areas/models/attend-area.model';
import { NewPagingModel, PagingModel } from '@trakr-safety/core/models/paging.model';
import { Action, createReducer, on } from '@ngrx/store';
import { AreaModel } from '../models/area.model';
import * as AreaActions from '../store/areas.actions';

export const areasFeatureName = 'areas';

export interface AreasState {
  areas: AreaModel[];
  area: AreaModel;
  areaTrace: AreaModel;
  loading: boolean;
  loaded: boolean;
  traceAreasDashboard: AreaModel[];
  traceAreaReportData: AttendAreaModel[];
  fullExportReportData: AttendAreaModel[];
  pagingData: PagingModel;
  error: string;
}

export const initialState: AreasState = {
  areas: [],
  area: null,
  areaTrace: null,
  loading: false,
  loaded: false,
  traceAreasDashboard: [],
  traceAreaReportData: [],
  fullExportReportData: [],
  pagingData: { ...NewPagingModel },
  error: null
};

const areasReducer = createReducer(
  initialState,
  on(AreaActions.replaceState, (state, { payload }) => ({
    ...state,
    ...payload
  })),
  on(AreaActions.fetchAreas, (state) => ({
    ...state,
    loading: true,
    loaded: false
  })),
  on(AreaActions.fetchTraceAreasMonthReport, (state) => ({
    ...state,
    loading: true,
    loaded: false
  })),
  on(AreaActions.setAreasTraceReport, (state, { traceAreaReportData, pagingData }) => ({
    ...state,
    traceAreaReportData,
    pagingData,
    loading: false,
    loaded: true
  })),
  on(AreaActions.isLoaded, (state) => ({
    ...state,
    loading: false,
    loaded: true
  })),
  on(AreaActions.fetchArea, (state) => ({
    ...state,
    loading: true,
    loaded: false
  })),
  on(AreaActions.setAreas, (state, { areas }) => ({
    ...state,
    areas,
    loading: false,
    loaded: true
  })),
  on(AreaActions.setArea, (state, { area }) => ({
    ...state,
    area,
    loading: false,
    loaded: true
  })),
  on(AreaActions.unloaded, (state) => ({
    ...state,
    loading: false,
    loaded: false
  })),
  on(AreaActions.newArea, (state) => ({
    ...state,
    area: undefined
  })),
  on(AreaActions.error, (state, { error }) => ({
    ...state,
    error,
    loading: false,
    loaded: true
  }))
);

export function reducer(state: AreasState | undefined, action: Action) {
  return areasReducer(state, action);
}
