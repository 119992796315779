

import { createAction, props } from '@ngrx/store';
import { CriteriaTypeModel } from '@trakr-safety/criteria-types/models/criteria-type.model';

/** criteria types */
export const unloaded = createAction('[Criteria Types] Un-Loaded');
export const fetchCriteriaTypes = createAction('[Criteria Types] Fetch Types');
export const setCriteriaTypes = createAction('[Criteria Types] Set Types',
  props<{
    criteriaTypes: CriteriaTypeModel[]
  }>()
);

/** error handling */
export const error = createAction('[Criteria Types] Error',
  props<{ error: string }>()
);



