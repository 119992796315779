import { AttendAreaModel } from '@trakr-safety/areas/models/attend-area.model';
import { PagingModel } from '@trakr-safety/core/models/paging.model';
import { createAction, props } from '@ngrx/store';
import { AreaModel } from '../models/area.model';

export const replaceState = createAction('[Areas] Replace State',
  props<{
    payload: {
      areas?: AreaModel[],
      area?: AreaModel,
      areasLoading?: false,
      areasLoaded?: false,
      areaTrace?: AreaModel,
      traceAreasDashboard?: AreaModel[],
      traceAreaReportData?: AttendAreaModel[],
      fullExportReportData?: AttendAreaModel[],
      pagingData?: PagingModel,
      error?: string
    }
  }>()
);

export const newArea = createAction('[Areas] New Area');

export const addArea = createAction('[Areas] Add Area',
  props<{
    area: AreaModel
  }>()
);

export const updateArea = createAction('[Areas] Update Area',
  props<{
    area: AreaModel
  }>()
);

export const deleteArea = createAction('[Areas] Delete Area',
  props<{
    id: number
  }>()
);

export const fetchArea = createAction('[Areas] Fetch Area',
  props<{
    id: number
  }>()
);

export const fetchTraceArea = createAction('[Areas] Fetch Trace Area',
  props<{
    id: number,
    locationId: number,
    tenantKey: string,
  }>()
);

export const fetchTraceAreasDashboard = createAction('[Areas] Fetch Trace Areas Dashboard');

export const fetchTraceAreasAllTimeReport = createAction('[Areas] Fetch Trace Areas AllTime Report');

export const fetchTraceAreasMonthReport = createAction('[Areas] Fetch Trace Areas Month Report',
  props<{
    date?: string,
    fromTime?: string,
    toTime?: string,
    filter?: string,
    search?: string,
    sortCol?: string,
    sortOrder?: string,
    page: number,
    pageSize: number
  }>()
);

export const setAreasTraceReport = createAction('[Areas] Set Trace Report',
  props<{
    traceAreaReportData: AttendAreaModel[],
    pagingData: PagingModel
  }>()
);

export const isLoaded = createAction('[Areas] Is Loaded');

export const error = createAction('[Areas] Error',
  props<{ error: string }>()
);

export const unloaded = createAction('[Areas] Un-Loaded');
export const fetchAreas = createAction('[Areas] Fetch Areas');
export const fetchAreasPublic = createAction('[Areas] Fetch Public',
  props<{
    locationId: number
  }>()
);
export const setAreas = createAction('[Areas] Set',
  props<{
    areas: AreaModel[]
  }>()
);
export const setArea = createAction('[Area] Set',
  props<{
    area: AreaModel
  }>()
);


