import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import moment from 'moment';
import { DOCUMENT } from '@angular/common';
import { select, Store } from '@ngrx/store';
import * as fromApp from 'app/store/app.reducer';
import { LanguageModel } from '@trakr-safety/core/language-selector/models/language.model';
import * as AppSettingsSelectors from '@trakr-safety/core/app-settings/store/app-settings.selectors';
import * as AppSettingsActions from '@trakr-safety/core/app-settings/store/app-settings.actions';
import { DataKeys, LocalstorageService } from '../../services/localstorage.service';

@Injectable({
  providedIn: 'root'
})
export class LanguageFacade {

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private store: Store<fromApp.AppState>,
    private mLocalStorageService: LocalstorageService,
    public translateService: TranslateService) { }

  // removed en-us may need to readd later
  init(languages: string[] = ['ar-ae', 'en-ca', 'fr-ca', 'es-us'], defaultLanguage = 'en-ca') {
    this.translateService.addLangs(languages);

    this.translateService.setDefaultLang(defaultLanguage);

    let browserLang = this.translateService.getBrowserCultureLang().toLocaleLowerCase();
    browserLang = browserLang.match(/ar-ae|en-ca|fr-ca|es-us/) ? browserLang : defaultLanguage;

    this.translateService.use(this.mLocalStorageService.get(DataKeys.LANGUAGE) || browserLang || defaultLanguage);
    this.setLang(this.translateService.currentLang);
  }

  setLang(lang: string) {
    this.mLocalStorageService.set(DataKeys.LANGUAGE, lang);
    this.translateService.use(lang);
    let htmlTag = this.document.getElementsByTagName("html")[0] as HTMLElement;
    htmlTag.dir = lang === "ar-ae" ? "rtl" : "ltr";
    moment.locale(lang);
  }

  getLang(): string {
    const lang = this.mLocalStorageService.get(DataKeys.LANGUAGE);
    if (lang) {
      moment.locale(lang);
    }
    return lang;
  }

  getLangCode(): string {
    return this.mLocalStorageService.get(DataKeys.LANGUAGE)
  }

  direction(): string {
    return this.mLocalStorageService.get(DataKeys.LANGUAGE) === 'ar-ae' ? 'rtl' : 'ltr'
  }

  translate(key: string): Observable<any> {
    return this.translateService.get(key);
  }

  getLanguages(): Observable<LanguageModel[]> {
    return this.store.select(AppSettingsSelectors.selectLanguages);
  }

  fetchLanguages(): void {
    this.store.dispatch(AppSettingsActions.fetchLanguages());
  }
}
