import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { UserModel } from '@trakr-safety/core/auth/models/user.model';
import { map, tap } from 'rxjs/operators';
import { RegistrationModel } from '../models/registration.model';

@Injectable({
  providedIn: 'root'
})
export class AuthHttpClient {

  private env = environment;

  constructor(private http: HttpClient) { }

  // login
  login(username: string, password: string, tenantKey: string): Observable<any> {

    return this.http.post<any>(`${environment.baseSharedApiURL}login`, {
      username,
      password,
      tenantKey
    });
  }

  // logout
  logout(csrfToken: string): Observable<any> {
    return this.http.post<any>(`${environment.baseSharedApiURL}logout`, null, {
      headers: new HttpHeaders({
        'csrf-token': csrfToken
      })
    });
  }

  registerUser(registration: RegistrationModel): Observable<string> {
    return this.http.post<string>(environment.baseSharedApiURL +
      'registration', { ...registration.loginDetails, ...registration.contactDetails, ...registration.userTypeDetails }).pipe(map(() => 'pages.registration.register_user.success_response.text'));
  }

  validateRegisterUserToken(token: string): Observable<boolean> {
    return this.http.post<boolean>(`${environment.baseSharedApiURL}registration/validate_token`, ({ token })).pipe(map((response: any) => response.data.validToken));
  }

  refreshUserData(user: UserModel): Observable<UserModel> {
    return this.http.post<UserModel>(`${environment.baseSharedApiURL}auth/user/refresh`, user);
  }

  checkPasswordRequired(tenantKey: string, username: string): Observable<boolean> {
    return this.http.get<boolean>(`${environment.baseSharedApiURL}auth/user/password_required/${tenantKey}/${username}`);
  }

  requestResetPasswordLink(username: string, tenantKey: string): Observable<string> {
    return this.http.post<string>(`${environment.baseSharedApiURL}forgot_password`, ({ username, tenantKey })).pipe(map(() => 'pages.login.reset_request.success_response.text'));
  }

  requestUserIdEmail(email: string, tenantKey: string): Observable<string> {
    return this.http.post<string>(`${environment.baseSharedApiURL}forgot_userid`, ({ email, tenantKey })).pipe(map(() => 'pages.login.request_userid.success_response.text'));
  }

  validateResetToken(token: string): Observable<boolean> {
    return this.http.post<boolean>(`${environment.baseSharedApiURL}forgot_password/validate_token`, ({ token })).pipe(map((response: any) => response.data.validToken));
  }

  changePassword(password: string, token: string): Observable<string> {
    return this.http.post<string>(`${environment.baseSharedApiURL}forgot_password/change_password`, ({ password, token })).pipe(map((response: any) => response.data));
  }

  convertNonPasswordAccount(password: string, username: string, tenantKey: string): Observable<UserModel> {
    return this.http.post<UserModel>(`${environment.baseSharedApiURL}auth/user/convert_account`, ({ password, username, tenantKey })).pipe(map((response: any) => response));
  }
}
