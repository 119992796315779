
import { ContactTracingSettingsModel } from '@trakr-safety/administration/models/ContactTracingSettings.model';

import { SystemSettingsModel } from '@trakr-safety/administration/models/systemSettings.model';
import { LanguageModel } from '@trakr-safety/core/language-selector/models/language.model';
import { EmployeeModel } from '@trakr-safety/employees/models/employee.model';
import { Action, createReducer, on } from '@ngrx/store';
import { FailedMessageModel } from 'app/administration/models/FailedMessageModel';
import * as AdministrationActions from './administration.actions';
import { DefaultLanguageModel } from '@trakr-safety/core/app-settings/models/default-language.model';

export const administrationFeatureName = 'administration';

export interface AdministrationState {
  failedAssessmentMessage: FailedMessageModel;
  error: string;
  uploading: boolean;
  uploadComplete: boolean;
  image: any;
  managers: EmployeeModel[];
  screeningContacts: EmployeeModel[];
  contactTracingSettings: ContactTracingSettingsModel;
  systemSettings: SystemSettingsModel;
  defaultScreeningLanguage: DefaultLanguageModel;
  languages: LanguageModel[];
  loading: boolean;
  loaded: boolean;
}

const initialState: AdministrationState = {
  failedAssessmentMessage: undefined,
  error: undefined,
  uploading: undefined,
  uploadComplete: undefined,
  image: undefined,
  managers: undefined,
  screeningContacts: undefined,
  contactTracingSettings: undefined,
  systemSettings: undefined,
  defaultScreeningLanguage: undefined,
  languages: undefined,
  loading: undefined,
  loaded: undefined
};

const adminReducer = createReducer(
  initialState,
  on(AdministrationActions.setFailedAssessmentMessage, (state, { failedAssessmentMessage }) => ({
    ...state,
    failedAssessmentMessage
  })),
  on(AdministrationActions.setContactTracingSettings, (state, { contactTracingSettings }) => ({
    ...state,
    contactTracingSettings
  })),
  on(AdministrationActions.setDefaultScreeningLanguage, (state, { defaultScreeningLanguage }) => ({
    ...state,
    defaultScreeningLanguage
  })),
  on(AdministrationActions.setScreeningContacts, (state, { screeningContacts }) => ({
    ...state,
    screeningContacts
  })),
  on(AdministrationActions.setSystemSettings, (state, { systemSettings }) => ({
    ...state,
    systemSettings
  })),
  on(AdministrationActions.setManagers, (state, { managers }) => ({
    ...state,
    managers
  })),
  on(AdministrationActions.error, (state, { error }) => ({
    ...state,
    error
  })),
  on(AdministrationActions.uploadLogo, (state, { image }) => ({
    ...state,
    image,
    uploading: true,
    uploadComplete: false
  })),
  on(AdministrationActions.uploadReportLogo, (state, { reportLogo }) => ({
    ...state,
    uploading: true,
    uploadComplete: false
  })),
  on(AdministrationActions.previewLogo, (state, { image }) => ({
    ...state,
    image
  })),
  on(AdministrationActions.uploadComplete, (state) => ({
    ...state,
    uploading: false,
    uploadComplete: true
  })),
  on(AdministrationActions.isUploading, (state, { isUploading }) => ({
    ...state,
    uploading: isUploading,
    uploadComplete: false
  })),
  on(AdministrationActions.fetchAllSettings, (state) => ({
    ...state,
    loading: true,
    loaded: false
  })),
  on(AdministrationActions.setAllSettings, (state, {
    managers,
    screeningContacts,
    systemSettings,
    failedAssessmentMessage,
    contactTracingSettings,
    defaultScreeningLanguage,
  }) => ({
    ...state,
    managers,
    screeningContacts,
    systemSettings,
    failedAssessmentMessage,
    contactTracingSettings,
    defaultScreeningLanguage,
    loading: false,
    loaded: true
  })),
);

export function reducer(state: AdministrationState | undefined, action: Action) {
  return adminReducer(state, action);
}



