import { Actions, ofType, createEffect } from '@ngrx/effects';
import { catchError, debounceTime, map, share, switchMap, tap } from 'rxjs/operators';
import { AuthHttpClient } from '@trakr-safety/core/auth/httpclients/auth.http-client';
import { of } from 'rxjs';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as AuthActions from '@trakr-safety/core/auth/store/auth.actions';
import { UserModel } from '@trakr-safety/core/auth/models/user.model';
import { TenantKeyPrependPipe } from '@trakr-safety/core/pipes/tenant-key-prepend.pipe';
import { AuthFacade } from '@trakr-safety/core/auth/facades/auth.facade';
import { DataKeys, LocalstorageService } from '@trakr-safety/core/services/localstorage.service';

@Injectable()
export class AuthEffects {
  private tenantKey = null;

  constructor(
    private pAuthFacade: AuthFacade,
    private pLocalStorageService: LocalstorageService,
    private tenantKeyPrependPipe: TenantKeyPrependPipe,
    private router: Router,
    private actions$: Actions,
    private authHttpClient: AuthHttpClient) {
    this.tenantKey = this.pLocalStorageService.getNestKeyValue('tenant', 'tenantKey');
  }

  checkAuth$ = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.checkAuth),
    switchMap(() => this.pAuthFacade.checkAuth().pipe(
      map((isLoggedIn) => AuthActions.checkAuthComplete({ isLoggedIn }))
    ))
  ));

  checkAuthComplete$ = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.checkAuthComplete),
    switchMap(({ isLoggedIn }) => {
      if (isLoggedIn) {
        return of(AuthActions.dummy);
      }
      else {
        return of(AuthActions.logout());
      }
    })
  ));

  loginStart$ = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.loginStart),
    switchMap((action) => this.authHttpClient.login(action.username, action.password, action.tenantKey).pipe(
      map(user => {
        this.handleAuthentication(user);
        user = this.pAuthFacade.authUser;
        return AuthActions.loginComplete({ user });
      }),
      catchError(error => {
        if (error.status === 420) {
          return of(AuthActions.error({ error: error.error.message }));
        }
        return of(AuthActions.error({ error: error.error.message }));
      }))
    )),
  );

  loginComplete$ = createEffect(
    () => this.actions$.pipe(
      ofType(AuthActions.loginComplete),
      tap((action) => {
        this.router.navigate([this.tenantKeyPrependPipe.transform('/home')]);
      })
    ), { dispatch: false }
  );

  logout$ = createEffect(
    () => this.actions$.pipe(
      ofType(AuthActions.logout),
      tap(() => {

        this.pLocalStorageService.delete(DataKeys.CURRENTUSER);

        // check if kioskmode and if not then delete any outstanding area trace
        if (!this.pLocalStorageService.check(DataKeys.KIOSKMODE) || Boolean(!this.pLocalStorageService.get(DataKeys.KIOSKMODE))) {
          this.pLocalStorageService.delete(DataKeys.TRACEAREA);
        }

        this.router.navigate([this.tenantKeyPrependPipe.transform('/login')]);
      })
    ), { dispatch: false }
  );

  // change this to refresh user
  autoLoginStart$ = createEffect(
    () => this.actions$.pipe(
      ofType(AuthActions.autoLoginStart),
      map(() => {
        if (this.pLocalStorageService.check(DataKeys.CURRENTUSER)) {
          return AuthActions.autoLogin({ user: JSON.parse(this.pLocalStorageService.get(DataKeys.CURRENTUSER)) });
        } else {
          return { type: 'DUMMY' };
        }
      })
    )
  );

  // fetches current user and refreshes session data
  autoLogin$ = createEffect(
    () => this.actions$.pipe(
      ofType(AuthActions.autoLogin),
      debounceTime(300),
      switchMap((action) => this.authHttpClient.refreshUserData(action.user).pipe(
        map(user => {
          this.handleAuthentication(user);
          user = this.pAuthFacade.authUser;
          return AuthActions.autoLoginComplete({ user });
        }),
        catchError(error => {
          if (error.status === 420) {
            return of(AuthActions.error({ error: error.error.message }));
          }
          return of(AuthActions.error({ error: error.error.message }));
        }))
      ),
      share()),
  );

  // checks if password is required
  checkPasswordMissing$ = createEffect(
    () => this.actions$.pipe(
      ofType(AuthActions.checkPasswordRequired),
      switchMap((action) => this.authHttpClient.checkPasswordRequired(action.tenantKey, action.username).pipe(
        map((response: any) => !response.passwordRequired),
        map(passwordMissing => {
          return AuthActions.setPasswordMissing({ passwordMissing });
        }),
        catchError(error => {
          if (error.status === 420) {
            return of(AuthActions.error({ error: error.error.message }));
          }
          return of(AuthActions.error({ error: error.error.message }));
        }))
      ))
  );

  registerUser$ = createEffect(
    () => this.actions$.pipe(
      ofType(AuthActions.registerUser),
      switchMap(({ registration }) =>
        this.authHttpClient.registerUser(registration).pipe(
          map((registerUserResponse: string) => AuthActions.registerUserCompleted({ registerUserResponse })),
          catchError(error => {
            if (error.status === 420) {
              return of(AuthActions.error({ error: error.error.message }));
            }
            return of(AuthActions.error({ error: error.error.message }));
          }))
      ),
    )
  );

  validateRegisterUserToken$ = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.validateRegisterUserToken),
    switchMap(({ token }) => this.authHttpClient.validateRegisterUserToken(token).pipe(
      map((registerUserTokenValid: boolean) => {
        const userEmailVerifiedResponse = registerUserTokenValid ? 'pages.login.activate_account.responses.success.text' : 'pages.login.activate_account.responses.token_expired.text';
        return AuthActions.setRegisterUserTokenValid({ registerUserTokenValid, userEmailVerifiedResponse });
      }),
      catchError(error =>
        of(AuthActions.errorPasswordResetRequest({ error: error.error.message }))
      ))
    ))
  );

  requestUserIdEmail$ = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.requestUserIdEmail),
    switchMap(({ email, tenantKey }) => this.authHttpClient.requestUserIdEmail(email, tenantKey).pipe(
      map((userIdRequestResponse: string) => AuthActions.requestUserIdComplete({ userIdRequestResponse })),
      catchError(error => of(AuthActions.requestUserIdComplete({ userIdRequestResponse: `error_codes.${error.error.errorCode}` }))
      ))
    ))
  );

  requestResetPasswordLink$ = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.requestResetPasswordLink),
    switchMap(({ username, tenantKey }) => this.authHttpClient.requestResetPasswordLink(username, tenantKey).pipe(
      map((passwordResetResponse: string) => AuthActions.requestResetPasswordLinkComplete({ passwordResetResponse })),
      catchError(error => of(AuthActions.requestResetPasswordLinkComplete({ passwordResetResponse: `error_codes.${error.error.errorCode}` }))
      ))
    ))
  );

  validateResetToken$ = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.validateResetToken),
    switchMap(({ token }) => this.authHttpClient.validateResetToken(token).pipe(
      map((resetTokenValid: boolean) => AuthActions.setResetTokenValid({ resetTokenValid })),
      catchError(error =>
        of(AuthActions.errorPasswordResetRequest({ error: error.error.message }))
      ))
    ))
  );

  changePassword$ = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.changePassword),
    switchMap(({ password, token }) => this.authHttpClient.changePassword(password, token).pipe(
      map(() => AuthActions.changePasswordComplete({ passwordChangeResponse: 'pages.login.change_password.responses.success.text' })),
      catchError(error =>
        of(AuthActions.changePasswordComplete({ passwordChangeResponse: error.error.message }))
      ))
    ))
  );

  convertNonPasswordAccount$ = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.convertNonPasswordAccount),
    switchMap(({ password, username, tenantKey }) => this.authHttpClient.convertNonPasswordAccount(password, username, tenantKey).pipe(
      map(() => {
        return AuthActions.loginStart({ username, password, tenantKey });
      }),
      catchError(error => {
        if (error.status === 420) {
          return of(AuthActions.error({ error: error.error.message }));
        }
        return of(AuthActions.error({ error: error.error.message }));
      }))
    ))
  );

  private handleAuthentication(user: UserModel) {
    this.pLocalStorageService.set(DataKeys.CURRENTUSER, JSON.stringify(user));
  }
}
