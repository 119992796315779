import { createAction, props } from '@ngrx/store';
import { MyTeamScreeningReportModel } from '@trakr-safety/reports/models/my-team-screening-report.model';

/** general */
export const unloaded = createAction('[Report] Un-Loaded');

/** my team screening report */
export const fetchMyTeamReport = createAction('[My Team Screening Report] Fetch');
export const setMyTeamReport = createAction('[My Team Screening Report] Set',
  props<{
    myTeamScreeningReport: MyTeamScreeningReportModel
  }>()
);

/** error handling */
export const error = createAction('[Report] Error',
  props<{ error: string }>()
);



