import { Actions, ofType, createEffect } from '@ngrx/effects';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Injectable } from '@angular/core';
import * as QuestionTypesActions from '@trakr-safety/question-types/store/question-types.actions';
import { QuestionTypesHttpClient } from '@trakr-safety/question-types/httpclients/question-types.http-client';
import { QuestionType } from 'app/question-types/models/question-type.model';

@Injectable()
export class QuestionTypesEffects {
  constructor(
    private actions$: Actions,
    private healthQuestionsHttpClient: QuestionTypesHttpClient) { }

  fetchQuestionTypes$ = createEffect(() => this.actions$.pipe(
    ofType(QuestionTypesActions.fetchQuestionTypes),
    switchMap(_ =>
      this.healthQuestionsHttpClient.getQuestionTypes()
        .pipe(
          map((questionTypes: QuestionType[]) => {
            return QuestionTypesActions.setQuestionTypes({ questionTypes });
          }),
          catchError(error => {
            return of(QuestionTypesActions.error({ error: error.error.message }));
          })
        ))
  ));
}
