import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { LocationModel } from '../models/location.model';
import { first, timeout, retry, shareReplay } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class LocationsHttpClient {

  constructor(private http: HttpClient) { }

  getLocation(id: number): Observable<LocationModel> {
    return this.http.get<LocationModel>(environment.baseWebApiURL + 'location/lookup/' + id).pipe(
      first(),
      timeout(10000),
      retry(4),
      shareReplay());
  }

}
