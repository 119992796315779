
import { QuestionType } from 'app/question-types/models/question-type.model';
import { createAction, props } from '@ngrx/store';

/** question types */
export const unloaded = createAction('[Question Types] Un-Loaded');
export const fetchQuestionTypes = createAction('[Question Types] Fetch Types');
export const setQuestionTypes = createAction('[Question Types] Set Types',
  props<{
    questionTypes: QuestionType[]
  }>()
);

/** error handling */
export const error = createAction('[Question Types] Error',
  props<{ error: string }>()
);



