import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { CheckinModel } from '../models/checkin.model';
import { ContactTracingSettingsModel } from '@trakr-safety/administration/models/ContactTracingSettings.model';
import { first, timeout, retry, shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class CheckinsHttpClient {

  constructor(private http: HttpClient) { }

  getCheckin(id: number): Observable<CheckinModel> {
    return this.http.get<CheckinModel>(environment.baseWebApiURL + 'contact_trace/public/' + id).pipe(
      first(),
      timeout(10000),
      retry(4),
      shareReplay());
  }

  getCheckins(): Observable<CheckinModel[]> {
    return this.http.get<CheckinModel[]>(environment.baseWebApiURL + 'contact_trace').pipe(
      first(),
      timeout(10000),
      retry(4),
      shareReplay());
  }

  getMonthReport(): Observable<CheckinModel[]> {
    return this.http.get<CheckinModel[]>(environment.baseWebApiURL + 'contact_trace/month-report').pipe(
      first(),
      timeout(10000),
      retry(4),
      shareReplay());
  }

  getAllTimeReport(): Observable<CheckinModel[]> {
    return this.http.get<CheckinModel[]>(environment.baseWebApiURL + 'contact_trace/all-report').pipe(
      first(),
      timeout(10000),
      retry(4),
      shareReplay());
  }

  getSaniPassSettings(locationId: number): Observable<ContactTracingSettingsModel> {
    return this.http.get<ContactTracingSettingsModel>(environment.baseWebApiURL + 'sanipass/settings/' + locationId).pipe(
      first(),
      timeout(10000),
      retry(4),
      shareReplay());
  }
  saveCheckin(contact: CheckinModel): Observable<any> {
    return this.http.post<CheckinModel>(environment.baseWebApiURL + 'contact_trace/public', contact);
  }

  tagAreaToCheckin(locationId: number, areaId: number, contactTraceId: number): Observable<any> {
    return this.http.post<any>(environment.baseWebApiURL + 'contact_trace/tag_area', { locationId, areaId, contactTraceId });
  }

  getDashSummary(): Observable<any> {
    return this.http.get<CheckinModel[]>(environment.baseWebApiURL + 'contact_trace/dash').pipe(
      first(),
      timeout(10000),
      retry(4),
      shareReplay());
  }
}
