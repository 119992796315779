
import { NewPagingModel, PagingModel } from '@trakr-safety/core/models/paging.model';
import { UserModel } from '@trakr-safety/core/auth/models/user.model';
import { NewSelfAssessmentsDashModel, SelfAssessmentsDashModel } from '@trakr-safety/health-screening/models/dash-summary.model';
import { NewSelfAssessmentModel, SelfAssessmentModel } from '@trakr-safety/health-screening/models/self-assessment.model';
import * as HealthAssessmentsActions from './heallth-assessments.actions';

export const healthAssessmentsFeatureName = 'healthAssessments';

export interface HealthAssessmentsState {
  selfAssessment: SelfAssessmentModel;
  selfAssessments: SelfAssessmentModel[];
  allTimeReportData: SelfAssessmentModel[];
  monthReportData: SelfAssessmentModel[];
  detailReportData: SelfAssessmentModel;
  historyReportData: SelfAssessmentModel[];
  dashSummary: SelfAssessmentsDashModel;
  nonScreenedEmployees: UserModel[];
  proxyUserFullName: string;
  filterString: string;
  error: string;
  pagingData: PagingModel;
  loading: boolean;
  loaded: boolean;
}

const initialState: HealthAssessmentsState = {
  selfAssessment: undefined,
  selfAssessments: [],
  allTimeReportData: [],
  monthReportData: [],
  detailReportData: { ...NewSelfAssessmentModel },
  historyReportData: [],
  dashSummary: { ...NewSelfAssessmentsDashModel },
  nonScreenedEmployees: [],
  proxyUserFullName: null,
  filterString: '',
  error: null,
  pagingData: { ...NewPagingModel },
  loading: false,
  loaded: false
};

export function healthAssessmentsReducer(state = initialState, action: HealthAssessmentsActions.HealthAssessmentActions) {
  switch (action.type) {
    case HealthAssessmentsActions.REPLACE_STATE:
      return {
        ...state,
        ...action.payload
      };
    case HealthAssessmentsActions.FETCH_ASSESSMENT:
      return {
        ...state,
        loading: true,
        loaded: false
      };
    case HealthAssessmentsActions.SET_ASSESSMENT:
      return {
        ...state,
        selfAssessment: action.assessmentData,
        loading: false,
        loaded: true
      };
    case HealthAssessmentsActions.SET_DASH_SUMMARY:
      return {
        ...state,
        dashSummary: action.dashSummary,
        loading: false,
        loaded: false
      };
    case HealthAssessmentsActions.IS_LOADED:
      return {
        ...state,
        loading: false,
        loaded: action.isLoaded
      };
    case HealthAssessmentsActions.DELETE_ASSESSMENT:
      return {
        ...state,
        selfAssessment: null,
        loading: false,
        loaded: false
      };
    case HealthAssessmentsActions.SAVE_ANSWERS_PROXY:
      return {
        ...state,
        proxyUserFullName: null,
      }
    case HealthAssessmentsActions.ERROR:
      return {
        ...state,
        error: action.payload
      };
    case HealthAssessmentsActions.SET_FILTER_STRING:
      return {
        ...state,
        filterString: action.payload
      };
    case HealthAssessmentsActions.SAVE_ANSWERS:
      return {
        ...state,
        selfAssessment: action.payload
      };
    case HealthAssessmentsActions.UNLOADED:
      return {
        ...state,
        loading: false,
        loaded: false
      }
    default:
      return state;
  }
}
