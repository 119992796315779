import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { AssessmentAnswerModel } from '@trakr-safety/health-screening/models/assessment-answer.model';
import { SelfAssessmentsDashModel } from '../models/dash-summary.model';
import { SelfAssessmentModel } from '../models/self-assessment.model';
import { EmployeeModel } from '@trakr-safety/employees/models/employee.model';
import { first, timeout, retry, shareReplay, map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class HealthAssessmentsHttpClient {

  constructor(private http: HttpClient) { }

  // assessment answers / summaries

  getAssessmentAnswers(): Observable<AssessmentAnswerModel[]> {
    return this.http.get<AssessmentAnswerModel[]>(environment.baseWebApiURL + 'assessment_answers').pipe(
      first(),
      timeout(10000),
      retry(4),
      shareReplay());
  }

  deleteAssessment(id: number) {
    return this.http.delete(environment.baseWebApiURL + 'self_assessments/' + id);
  }

  deleteReportAssessment(tableQuery: any, id: number) {
    return this.http.delete(environment.baseWebApiURL + 'self_assessments/' + id).pipe(take(1), map(() => tableQuery), shareReplay());
  }

  getAssessment(userId: number): Observable<SelfAssessmentModel[]> {
    return this.http.get<SelfAssessmentModel[]>(environment.baseWebApiURL + 'self_assessments/' + userId).pipe(
      first(),
      timeout(10000),
      retry(4),
      shareReplay());
  }

  getAssessments(): Observable<SelfAssessmentModel[]> {
    return this.http.get<SelfAssessmentModel[]>(environment.baseWebApiURL + 'self_assessments').pipe(
      first(),
      timeout(10000),
      retry(4),
      shareReplay());
  }

  getDashSummary(): Observable<SelfAssessmentsDashModel> {
    return this.http.get<SelfAssessmentsDashModel>(environment.baseWebApiURL + 'saniscreen/dash/screenings').pipe(
      first(),
      timeout(10000),
      retry(4),
      shareReplay());
  }

  tagAreaToAssessment(areaId: number): Observable<any> {
    return this.http.post<any>(environment.baseWebApiURL + 'self_assessments/tag_area', { areaId });
  }

  saveAssessmentAnswers(answersToSave: any): Observable<AssessmentAnswerModel[]> {
    return this.http.post<AssessmentAnswerModel[]>(environment.baseWebApiURL + 'self_assessments', answersToSave);
  }

  /** reports */
  getEmployeeAllTimeMonthReport(): Observable<SelfAssessmentModel[]> {
    return this.http.get<SelfAssessmentModel[]>(environment.baseWebApiURL + 'reports/employees/all-screenings').pipe(
      first(),
      timeout(10000),
      retry(4),
      shareReplay());
  }

  fetchProxyUser(id: number): Observable<EmployeeModel> {
    return this.http.get<EmployeeModel>(environment.baseWebApiURLV2 + 'users/' + id).pipe(
      first(),
      timeout(10000),
      retry(4),
      shareReplay());
  }

  getEmployeeMonthReport(): Observable<SelfAssessmentModel[]> {
    return this.http.get<SelfAssessmentModel[]>(environment.baseWebApiURL + 'reports/employees/month-screenings').pipe(
      first(),
      timeout(10000),
      retry(4),
      shareReplay());
  }

  getNonScreenedEmployees(
    filter = '',
    search = '',
    sortCol = '',
    sortOrder = 'desc',
    page = 0,
    pageSize = 10,
    date = '',
    fromTime = '',
    toTime = '') {
    return this.http.get(`${environment.baseWebApiURL}reports/non-screened`,
      {
        params: new HttpParams()
          .set('filter', filter)
          .set('search', search)
          .set('sortcol', sortCol)
          .set('sortorder', sortOrder)
          .set('page', page.toString())
          .set('pagesize', pageSize.toString())
          .set('date', date)
          .set('fromtime', fromTime)
          .set('totime', toTime)
      }).pipe(
        first(),
        timeout(10000),
        retry(4),
        shareReplay());
  }


  getAssessmentDetailsReport(id: number): Observable<SelfAssessmentModel> {
    return this.http.get<SelfAssessmentModel>(environment.baseWebApiURL + 'saniscreen/report/assessment-details/' + id).pipe(
      first(),
      timeout(10000),
      retry(4),
      shareReplay());
  }

  getAssessmentsHistoryReport(userId: number): Observable<SelfAssessmentModel> {
    return this.http.get<SelfAssessmentModel>(environment.baseWebApiURL + 'saniscreen/report/assessment-history/' + userId).pipe(
      first(),
      timeout(10000),
      retry(4),
      shareReplay());
  }

  getVisitorsAllTimeReport(): Observable<SelfAssessmentModel[]> {
    return this.http.get<SelfAssessmentModel[]>(environment.baseWebApiURL + 'saniscreen/report/visitors/all-screenings').pipe(
      first(),
      timeout(10000),
      retry(4),
      shareReplay());
  }

  getVisitorsMonthReport(): Observable<SelfAssessmentModel[]> {
    return this.http.get<SelfAssessmentModel[]>(environment.baseWebApiURL + 'saniscreen/report/visitors/month-screenings').pipe(
      first(),
      timeout(10000),
      retry(4),
      shareReplay());
  }

  getVisitorAssessmentReport(): Observable<SelfAssessmentModel> {
    return this.http.get<SelfAssessmentModel>(environment.baseWebApiURL + 'saniscreen/report/visitors/assessment-details').pipe(
      first(),
      timeout(10000),
      retry(4),
      shareReplay());
  }

}
