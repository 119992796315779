import { environment as currentVersion } from './environment.version';

/* prod settings */
export const environment = {
  isDev: false,
  saniTrakrAppDataStorageKey: 'sanitrakr-data',
  siteDomain: 'https://saniapp.trakrsuite.com/',
  defaultCookieDomain: 'saniapp.trakrsuite.com',
  areaScanCardUrl: 'https://saniapp.trakrsuite.com/displayprint/generateAreaSign.php?tenantkey=',
  areaLoginScanCardUrl: 'https://saniapp.trakrsuite.com/displayprint/generateAreaLoginSign.php',
  areaTracingScanCardUrl: 'https://saniapp.trakrsuite.com/displayprint/generateAreaTracingSign.php',
  remoteURLPath: 'https://saniapp.trakrsuite.com/clientmedia/',
  baseSharedApiURL: '/api/shared/v1/',
  baseS2SApiUrl: '/api/s2s/v1/',
  s2sApiToken: '52f6cf33-c23e-478c-b0bd-54ed990da16d',
  baseWebApiURL: '/api/web/v1/',
  baseWebApiURLV2: '/api/web/v2/',
  maxMobileWidth: '(max-width: 959px)',
  whitelistedDomains: [
    'saniapp.trakrsuite.com/', 'saniapp.trakrsuite.com/api/web/v1/'],
  blacklistedRoutes: [
    'saniapp.trakrsuite.com/api/shared/v1/auth'],
  production: true,
  log: false,
  flags: {
    useNewHeader: false
  },
  ...currentVersion,
  firebase: {
    databaseURL: "config data from general tab",
    apiKey: "AIzaSyBhwJRlOURVYMKygEwlMXNJaPAAvMs2YhE",
    authDomain: "trakrsuitepushnotifications.firebaseapp.com",
    projectId: "trakrsuitepushnotifications",
    storageBucket: "trakrsuitepushnotifications.appspot.com",
    messagingSenderId: "15664064963",
    appId: "1:15664064963:web:230a6ca1a4941d5d897576",
    measurementId: "G-SV5J6QVW69",
    vapidKey: "BBGtTGoJ1Hj8EZV1yDvKXY8k9X-4pthBll9l4iGfiQcEFZicxGX3Hep598CGHHNP4fSQElmEImiRcaKtAopjXTA"
  },
};
