import { InjectionToken, NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { QuicklinkStrategy } from 'ngx-quicklink';
import { LogoutComponent } from './public/login/logout.component';
import { MainComponent } from './main/main.component';
import { LoadAppSettingsGuard } from '@trakr-safety/core/app-settings/guards/load-app-settings.guard';
import { LoadLanguagesGuard } from './core/language-selector/guards/load-languages.guard';
import { AuthGuard } from './core/auth/guards/auth.guard';
import { RoleGuard } from './core/auth/guards/role.guard';

// import { TokenInterceptor } from './httpinterceptors/token.interceptor';

const deactivateGuard = new InjectionToken('deactivateGuard');
export const routes: Routes = [

  {
    path: '',
    runGuardsAndResolvers: 'always',
    canActivate: [LoadLanguagesGuard],
    component: MainComponent,
    loadChildren: () => import('./company-key/company-key.module').then(m => m.CompanyKeyModule)
  },

  {
    path: ':tenantKey',
    runGuardsAndResolvers: 'always',
    canActivate: [LoadAppSettingsGuard],
    data: { animation: 'MainEntry' },
    component: MainComponent,
    children: [
      {
        path: 'login', loadChildren: () => import('./public/login/login.module').then(m => m.LoginModule),
      },
      {
        path: 'signup', loadChildren: () => import('./public/registration/registration.module').then(m => m.RegistrationModule)
      },
      {
        path: 'login/register', loadChildren: () => import('./public/registration/registration.module').then(m => m.RegistrationModule)
      },
      {
        path: 'logout', component: LogoutComponent
      },
      {
        path: 'public',
        data: { animation: 'PublicLayout' },
        loadChildren: () => import('./layouts/public-layout/public-layout.module').then(m => m.PublicLayoutModule),
      },
      {
        path: 'user',
        runGuardsAndResolvers: 'always',
        canLoad: [AuthGuard, RoleGuard],
        data: { animation: 'VisitorsLayout', roles: ['ROLE_EMPLOYEE', 'ROLE_USER'] },
        loadChildren: () => import('./layouts/visitors-layout/visitors-layout.module').then(m => m.VisitorsLayoutModule),
      },
      {
        path: 'passports',
        runGuardsAndResolvers: 'always',
        canLoad: [RoleGuard],
        data: { animation: 'NoLayout', roles: ['ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_EMPLOYEE', 'ROLE_USER', 'ROLE_PUBLIC'] },
        loadChildren: () => import('./layouts/no-layout/no-layout.module').then(m => m.NoLayoutModule),
      },
      {
        path: 'app',
        runGuardsAndResolvers: 'always',
        canLoad: [AuthGuard, RoleGuard],
        data: { animation: 'MainLayout', roles: ['ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_REPORTS', 'ROLE_EMPLOYEE', 'ROLE_USER'] },
        loadChildren: () => import('./layouts/main/main-layout.module').then(m => m.MainLayoutModule)
      },
      { path: 'manage-console', redirectTo: 'app', pathMatch: 'prefix' },
    ],
  },

  /** legacy route redirects */
  /* management console*/
  { path: ':tenantKey', redirectTo: ':tenantKey/home', pathMatch: 'full' },
  { path: ':tenantKey/home', redirectTo: ':tenantKey/app/home', pathMatch: 'full' },
  { path: ':tenantKey/reports', redirectTo: ':tenantKey/app/reports', pathMatch: 'prefix' },
  { path: ':tenantKey/administration', redirectTo: ':tenantKey/app/administration', pathMatch: 'prefix' },
  { path: ':tenantKey/health-screening', redirectTo: ':tenantKey/app/health-screening', pathMatch: 'prefix' },
  { path: ':tenantKey/areas', redirectTo: ':tenantKey/app/areas', pathMatch: 'prefix' },
  { path: ':tenantKey/area-tracing/dashboard', redirectTo: ':tenantKey/app/area-tracing/dashboard', pathMatch: 'full' },

  { path: ':tenantKey/saniscreen/screening', redirectTo: ':tenantKey/app/health-screening/questionaire', pathMatch: 'full' },

  /** users (reg employees, external) */
  { path: ':tenantKey/screening/questionaire', redirectTo: ':tenantKey/user/screening/questionaire', pathMatch: 'full' },
  { path: ':tenantKey/saniscreen/guest/screening', redirectTo: ':tenantKey/user/screening/questionaire', pathMatch: 'full' },
  { path: ':tenantKey/saniscreen/visitors/screening', redirectTo: ':tenantKey/user/screening/questionaire', pathMatch: 'full' },

  /** passports */
  { path: ':tenantKey/screening-passport', redirectTo: ':tenantKey/passports/screening-passport', pathMatch: 'full' },
  { path: ':tenantKey/saniscreen/health-day-pass', redirectTo: ':tenantKey/passports/screening-passport', pathMatch: 'full' },
  { path: ':tenantKey/checkin-passport', redirectTo: ':tenantKey/passports/checkin-passport', pathMatch: 'full' },
  { path: ':tenantKey/visitors/sanipass/day-pass', redirectTo: ':tenantKey/passports/checkin-passport', pathMatch: 'full' },


  /* public routes */
  { path: ':tenantKey/checkin', redirectTo: ':tenantKey/public/checkin', pathMatch: 'full' },
  { path: ':tenantKey/area-selector', redirectTo: ':tenantKey/public/area-selector', pathMatch: 'full' },
  { path: ':tenantKey/register', redirectTo: ':tenantKey/login/register', pathMatch: 'full' },

  { path: ':tenantKey/visitors/sanipass/checkin', redirectTo: ':tenantKey/public/checkin', pathMatch: 'full' },
  { path: ':tenantKey/visitors/sanipass/signin', redirectTo: ':tenantKey/public/checkin', pathMatch: 'full' },

  { path: 'splash', redirectTo: '', pathMatch: 'full' },
  { path: '**', redirectTo: '', pathMatch: 'full' }
];

@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forRoot(routes, { enableTracing: true, onSameUrlNavigation: 'reload', preloadingStrategy: QuicklinkStrategy, relativeLinkResolution: 'legacy' })],
  providers: [
    {
      provide: deactivateGuard,
      useValue: () => {
        return false;
      }
    }]
})
export class AppRoutingModule { }
