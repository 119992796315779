import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { CriteriaTypeModel } from '@trakr-safety/criteria-types/models/criteria-type.model';
import { first, timeout, retry, shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CriteriaTypesHttpClient {

  constructor(private http: HttpClient) { }

  /** criteria types */
  getCriteriaTypes(): Observable<CriteriaTypeModel[]> {
    return this.http.get<CriteriaTypeModel[]>(environment.baseWebApiURL + 'criteria_types').pipe(
      first(),
      timeout(10000),
      retry(4),
      shareReplay());
  }
}
