import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'environments/environment';
import { AppSettingsModel } from '@trakr-safety/core/app-settings/models/app-settings.model';
import { catchError, first, map, retry, shareReplay, timeout } from 'rxjs/operators';
import { CompanyModel } from '../../models/company.model';

@Injectable({ providedIn: 'root' })

export class AppSettingsHttpClient {
  constructor(private http: HttpClient) { }

  fetchAppSettings(languageCode: string, locationId: number): Observable<AppSettingsModel> {
    return this.http.post(`${environment.baseWebApiURL}app/settings`, ({ languageCode, locationId })).pipe(
      first(),
      timeout(10000),
      retry(4),
      shareReplay(),
      map((response: any) => response.data),
      map((settings) => {

        return ({
          languages: settings.languages,
          userCategories: settings.userCategories,
          managers: settings.managers,
          userTypes: settings.userTypes,
          roles: settings.roles,
          companies: settings.companies,
          language_id: settings.defaultLanguage ? settings.defaultLanguage.language_id : 1,
          language: settings.defaultLanguage ? settings.defaultLanguage.language : 'en-ca',
          enableRegistration: settings.systemSettings ? settings.systemSettings.enableRegistration : false,
          enableVaccinationVerify: settings.systemSettings ? settings.systemSettings.enableVaccinationVerify : false,
          enableVaccinationAttestation: settings.systemSettings ? settings.systemSettings.enableVaccinationAttestation : false,
          enableShowVerifiedOnPass: settings.systemSettings ? settings.systemSettings.enableShowVerifiedOnPass : false,
          enableVaccinationTracking: settings.systemSettings ? settings.systemSettings.enableVaccinationTracking : false,
          vaccinationTrackingLink: settings.systemSettings ? settings.systemSettings.vaccinationTrackingLink : null,
          enableContactTracing: settings.systemSettings ? settings.systemSettings.enableContactTracing : false,
          enableScreenings: settings.systemSettings ? settings.systemSettings.enableScreenings : false,
          healthAffirmation: settings.contactTracingSettings ? settings.contactTracingSettings.healthAffirmation : null,
          enableHealthAffirmation: settings.contactTracingSettings ? settings.contactTracingSettings.enableHealthAffirmation : false,
          enableAdditionalGuestInfo: settings.contactTracingSettings ? settings.contactTracingSettings.enableAdditionalGuestInfo : false,
          failedScreeningMessage: settings.screeningsSettings ? settings.screeningsSettings.failedScreeningMessage : null
        })
      }),
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          console.error(`Error: ${error.error.message}`);
        } else {
          console.error(`Error: ${error.message}`);
        }
        return of(null);
      })
    );
  }

  fetchCompanies(): Observable<CompanyModel[]> {
    return this.http.get(`${environment.baseWebApiURL}companies`).pipe(
      first(),
      timeout(10000),
      retry(4),
      shareReplay(),
      map((response: any) => response.data),
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          console.error(`Error: ${error.error.message}`);
        } else {
          console.error(`Error: ${error.message}`);
        }
        return of([]);
      })
    );
  }
}
