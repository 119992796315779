import { AssessmentQuestionModel, NewAssessmentQuestionModel } from '@trakr-safety/health-screening/models/assessment-question.model';
import { Action, createReducer, on } from '@ngrx/store';
import * as HealthQuestionsActions from '@trakr-safety/health-screening/store/questions/health-questions.actions';

export const healthQuestionsFeatureName = 'healthQuestions';

export interface HealthQuestionsState {
  questions: AssessmentQuestionModel[];
  selectedQuestion: AssessmentQuestionModel;
  usingDefaultLanguageSet: boolean,
  defaultLanguage: string,
  loading: boolean;
  loaded: boolean;
  error: string;
}

export const initialState: HealthQuestionsState = {
  questions: undefined,
  selectedQuestion: undefined,
  usingDefaultLanguageSet: false,
  defaultLanguage: null,
  loading: false,
  loaded: false,
  error: null
};

const healthQuestionsReducer = createReducer(
  initialState,
  on(HealthQuestionsActions.fetchAllQuestions, (state) => ({
    ...state,
    loading: true,
    loaded: false
  })),
  on(HealthQuestionsActions.fetchQuestion, (state) => ({
    ...state,
    loading: true,
    loaded: false
  })),
  on(HealthQuestionsActions.setQuestions, (state, { questions }) => ({
    ...state,
    questions,
    loading: false,
    loaded: true
  })),
  on(HealthQuestionsActions.setQuestions, (state, { questions }) => ({
    ...state,
    questions,
    usingDefaultLanguageSet: false,
    defaultLanguage: null,
    loading: false,
    loaded: true
  })),
  on(HealthQuestionsActions.setDefaultQuestions, (state, { questions, usingDefaultLanguageSet, defaultLanguage }) => ({
    ...state,
    questions,
    usingDefaultLanguageSet,
    defaultLanguage,
    loading: false,
    loaded: true
  })),
  on(HealthQuestionsActions.setQuestion, (state, { question }) => ({
    ...state,
    question,
    loading: false,
    loaded: true
  })),
  on(HealthQuestionsActions.unloaded, (state) => ({
    ...state,
    loading: false,
    loaded: false
  })),
  on(HealthQuestionsActions.newQuestion, (state) => ({
    ...state,
    area: { ...NewAssessmentQuestionModel }
  })),
  on(HealthQuestionsActions.error, (state, { error }) => ({
    ...state,
    error,
    loading: false,
    loaded: true
  }))
);

export function reducer(state: HealthQuestionsState | undefined, action: Action) {
  return healthQuestionsReducer(state, action);
}
