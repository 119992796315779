<h1 mat-dialog-title>New trakr Safety Update</h1>
<mat-dialog-content>
  <p>
    A new version of trakr Safety is available. Please click 'Update App' to
    continue.
  </p>
</mat-dialog-content>
<mat-dialog-actions
  fxLayout="row"
  fxLayoutAlign="center center"
  fxLayoutGap="20px"
  class="pb-30"
>
  <button mat-raised-button color="accent" [mat-dialog-close]="true">
    Update App
  </button>
</mat-dialog-actions>
