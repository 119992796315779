import { Injectable } from '@angular/core';
import { AreaModel } from '@trakr-safety/areas/models/area.model';
import { TenantModel } from '@trakr-safety/tenants/models/tenant.model';
import { environment } from 'environments/environment';
import { UserModel } from '../auth/models/user.model';

export enum DataKeys {
  CURRENTUSER = 'currentUser',
  KIOSKMODE = 'kioskMode',
  LANGUAGE = 'language',
  TENANT = 'tenant',
  THEME = 'theme',
  TRACEAREA = 'traceArea'
}

interface AppData {
  language: string;
  theme: string;
  kioskMode: string;
  currentUser: UserModel;
  traceArea: AreaModel;
  tenant: TenantModel;
}

@Injectable({
  providedIn: 'root'
})
export class LocalstorageService {

  private readonly mStorageKey = environment.saniTrakrAppDataStorageKey;

  constructor() { }

  check(key: string): boolean {
    return this.getStorageObject()[key] ? true : false;
  }

  get(key: string): string {
    const storageObj = this.getStorageObject();
    return storageObj && storageObj[key] ? storageObj[key] : null;
  }

  getNestKeyValue(key1: string, key2: string): string {
    const storageObj = this.getStorageObject();
    return storageObj && storageObj[key1] && JSON.parse(storageObj[key1])[key2] ? JSON.parse(storageObj[key1])[key2] : null;
  }

  delete(key: string): void {
    const storageObj = this.getStorageObject();
    delete storageObj[key];
    this.replaceStorageObject({ ...storageObj });
  }

  set(key: string, value: string): void {
    const storageObj = this.getStorageObject();
    storageObj[key] = value;
    this.replaceStorageObject({ ...storageObj });
  }

  private getStorageObject(): AppData {
    return { ...JSON.parse(localStorage.getItem(this.mStorageKey)) };
  }

  private replaceStorageObject(storageObj: AppData) {
    localStorage.setItem(this.mStorageKey, JSON.stringify(storageObj));
  }
}
