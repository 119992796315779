import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AppErrorService } from '../services/app-error.service';
import { AuthFacade } from '../auth/facades/auth.facade';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private pAuthFacade: AuthFacade,
    private _appErrorService: AppErrorService) { }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(err => {

      switch (err.status) {
        case 401:
          // auto logout if 401 response returned from api
          this.pAuthFacade.logout();
          break;
        case 400:
        case 409:
        case 422:
          this._appErrorService.emitError(err.error);
          break;
      }
      return throwError(err);
    }));
  }
}
