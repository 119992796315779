import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { first, timeout, retry, shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TenantHttpClient {

  constructor(private http: HttpClient) { }

  fetchTenant(tenantKey: string): Observable<any> {
    return this.http.get(`${environment.baseSharedApiURL}tenants/${tenantKey}`).pipe(
      first(),
      timeout(10000),
      retry(4),
      shareReplay());
  }

}
