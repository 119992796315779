import { Action, ActionReducer } from '@ngrx/store';

export function clearState(reducer) {
  return function (state, action: Action) {
    const currentState = { ...state };


    if (action.type === '[Auth] Logout' && !currentState.appSettings.kioskMode) {
      state = undefined;
      state = { appSettings: { ...currentState.appSettings } };
    }

    if (action.type === '[Auth] Logout' && currentState.appSettings.kioskMode) {
      state = undefined;
      state = { appSettings: { ...currentState.appSettings }, areas: { ...currentState.areas } };
    }

    return reducer(state, action);
  };
}

// console.log all actions
export function debug(reducer: ActionReducer<any>): ActionReducer<any> {
  return function (state, action) {

    return reducer(state, action);
  };
}
