import { Action, createReducer, on } from '@ngrx/store';
import * as QRCardsActions from './qr-cards.actions';
import { QRCardModel } from '../models/qr-card.model';

export const qrCardsFeatureName = 'qrCards';

export interface QRCardsState {
  qrCardTemplates: QRCardModel[];
  saving: boolean;
  saved: boolean;
  loading: boolean;
  loaded: boolean;
  error: string;
}

export const initialState: QRCardsState = {
  qrCardTemplates: [],
  saving: false,
  saved: false,
  loading: false,
  loaded: false,
  error: null
};

const qrCardsReducer = createReducer(
  initialState,
  on(QRCardsActions.unloaded, (state) => ({
    ...state,
    loading: false,
    loaded: false
  })),
  on(QRCardsActions.fetchQRCardTemplates, (state) => ({
    ...state,
    loading: true,
    loaded: false
  })),
  on(QRCardsActions.setQRCardTemplates, (state, { qrCardTemplates }) => ({
    ...state,
    qrCardTemplates,
    loading: false,
    loaded: true
  })),
  /** 5x7 */
  on(QRCardsActions.saveQRCardTemplate5x7, (state) => ({
    ...state,
    saving: true,
    saved: false
  })),
  on(QRCardsActions.setSavedQRCardTemplate5x7, (state, { qrCardTemplates }) => ({
    ...state,
    qrCardTemplates,
    saving: false,
    saved: true
  })),
  /** 8x11 */
  on(QRCardsActions.saveQRCardTemplate8x11, (state) => ({
    ...state,
    saving: true,
    saved: false
  })),
  on(QRCardsActions.setSavedQRCardTemplate8x11, (state, { qrCardTemplates }) => ({
    ...state,
    qrCardTemplates,
    saving: false,
    saved: true
  })),
  on(QRCardsActions.error, (state, { error }) => ({
    ...state,
    error,
    loading: false,
    loaded: true
  })),
);

export function reducer(state: QRCardsState | undefined, action: Action) {
  return qrCardsReducer(state, action);
}
