import { Injectable, OnDestroy } from '@angular/core';
import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppSettingsFacade } from '@trakr-safety/core/app-settings/facades/app-settings.facade';
import { AuthFacade } from '@trakr-safety/core/auth/facades/auth.facade';
import { TenantFacade } from '@trakr-safety/tenants/facades/tenant.facade';
import { UserModel } from '@trakr-safety/core/auth/models/user.model';

@Injectable()
export class LoginFacade implements OnDestroy {

  constructor(
    private _authFacade: AuthFacade,
    private _appSettingsFacade: AppSettingsFacade,
    private _tenantFacade: TenantFacade,) { }

  get authUser(): UserModel {
    return this._authFacade.authUser;
  }

  login(user: any): void {
    this._authFacade.loginStart(user);
  }

  boostrapLoginPage(): Observable<any> {
    const tenantData = this._tenantFacade.tenant;

    return combineLatest([
      this._appSettingsFacade.getSystemSettings(),
      this._authFacade.checkLoginAttempt()
    ]).pipe(
      map(([
        appSettings,
        loginAttempt
      ]) => ({
        tenantData,
        appSettings,
        loginAttempt
      }))
    );
  }

  ngOnDestroy(): void {
    this._authFacade.clearError();
  }
}
