
import { createAction, props } from '@ngrx/store';
import { FeatureStore } from 'app/store/feature-store.enum';
import { Question } from '../models/question.model';
import { SortQuestion } from '../models/sort-question.model';

const featureName = FeatureStore.QUESTIONS;

export const nothing = createAction(`[${featureName}]  Do Nothing`);
export const clearQuestions = createAction(`[${featureName}]  Clear Questions`);
export const newQuestion = createAction(`[${featureName}]  New Question`);

export const addQuestion = createAction(`[${featureName}]  Add Question`,
  props<{
    questionData: Question
  }>()
);

export const updateQuestion = createAction(`[${featureName}]  Update Question`,
  props<{
    questionData: Question,
  }>()
);

export const moveQuestion = createAction(`[${featureName}]  Move Question`,
  props<{
    sortData: SortQuestion,
    langCode: string
  }>()
);

export const deleteQuestion = createAction(`[${featureName}]  Delete Question`,
  props<{
    questionId: number
    langCode: string
  }>()
);

export const fetchQuestion = createAction(`[${featureName}]  Fetch Question`,
  props<{
    questionId: number
  }>()
);

export const fetchAllQuestions = createAction(`[${featureName}]  Fetch All`,
  props<{
    langCode: string
  }>()
);

export const setQuestions = createAction(`[${featureName}]  Set Questions`,
  props<{
    questions: Question[]
  }>()
);

export const setDefaultQuestions = createAction(`[${featureName}]  Set Default Questions`,
  props<{
    questions: Question[],
    usingDefaultLanguageSet: boolean,
    defaultLanguage: string
  }>()
);

export const unloaded = createAction(`[${featureName}]  Un-Loaded`);

export const setQuestion = createAction(`[${featureName}]  Set Question`,
  props<{
    question: Question
  }>()
);

/** error handling */
export const error = createAction(`[${featureName}]  Error`,
  props<{ error: string }>()
);
