import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { QuestionType } from 'app/question-types/models/question-type.model';
import { first, timeout, retry, shareReplay } from 'rxjs/operators';
import { Question } from '../models/question.model';
import { SortQuestion } from '../models/sort-question.model';

@Injectable({
  providedIn: 'root'
})
export class QuestionsHttpClient {

  private baseUrl: string = environment.baseWebApiURL;

  constructor(private http: HttpClient) { }

  getQuestion(id: number): Observable<Question> {
    return this.http.get<Question>(`${this.baseUrl}assessment_questions/${id}`).pipe(
      first(),
      timeout(10000),
      retry(4),
      shareReplay());
  }

  fetchAllQuestions(langCode: string): Observable<Question[]> {

    return this.http.get<Question[]>(`${this.baseUrl}self_assessments/questions/lang/${langCode}`).pipe(
      first(),
      timeout(10000),
      retry(4),
      shareReplay());
  }

  updateQuestion(questionToUpdate: Question) {
    return this.http.put(`${this.baseUrl}assessment_questions/${questionToUpdate.id}`, questionToUpdate);
  }

  updateQuestionOrderIndex(sortQuestion: SortQuestion, languageCode: string): Observable<Question[]> {
    return this.http.post<Question[]>
      (`${this.baseUrl}assessment_questions/update_sequence`, { sortQuestion, languageCode });
  }

  softDeleteQuestion(id: number) {
    return this.http.delete<Question>(`${this.baseUrl}assessment_questions/${id}`);
  }

  createQuestion(questionToCreate: Question): Observable<Question[]> {
    return this.http.post<Question[]>(`${this.baseUrl}assessment_questions`, questionToCreate);
  }

  /** question types */
  getQuestionTypes(): Observable<QuestionType[]> {
    return this.http.get<QuestionType[]>(`${this.baseUrl}question_types`).pipe(
      first(),
      timeout(10000),
      retry(4),
      shareReplay());
  }
}
