import { Actions, ofType, createEffect } from '@ngrx/effects';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Injectable } from '@angular/core';
import * as QRCardsActions from './qr-cards.actions';
import { QRCardsHttpClient } from '../httpclients/qr-cards.http-client';
import { QRCardModel } from '@trakr-safety/qr-cards/models/qr-card.model';
import { SnackBarServiceService } from '@trakr-safety/core/services/snack-bar-service.service';


@Injectable()
export class QRCardsEffects {
  constructor(
    private snackBarService: SnackBarServiceService,
    private actions$: Actions,
    private qrCardHttpClient: QRCardsHttpClient) { }

  fetchQRCardTemplates$ = createEffect(() => this.actions$.pipe(
    ofType(QRCardsActions.fetchQRCardTemplates),
    switchMap(_ =>
      this.qrCardHttpClient.fetchQRCardTemplates()
        .pipe(
          map((result: any) => result.data),
          map((qrCardTemplates: QRCardModel[]) => {
            return QRCardsActions.setQRCardTemplates({ qrCardTemplates });
          }),
          catchError(error => {
            return of(QRCardsActions.error({ error: error.error.message }));
          })
        ))
  ));


  saveQRCardTemplate5x7$ = createEffect(() => this.actions$.pipe(
    ofType(QRCardsActions.saveQRCardTemplate5x7),
    switchMap(({ qrCardTemplate }) => this.qrCardHttpClient.saveQRCardTemplate(qrCardTemplate).pipe(
      map((result: any) => result.data),
      map((qrCardTemplates: QRCardModel[]) => {
        this.snackBarService.popSnack('alerts.saved.text', true);
        return QRCardsActions.setSavedQRCardTemplate5x7({ qrCardTemplates });
      }),
      catchError(error => {
        return of(QRCardsActions.error({ error: error.error.message }));
      })
    ))
  ));

  saveQRCardTemplate8x11$ = createEffect(() => this.actions$.pipe(
    ofType(QRCardsActions.saveQRCardTemplate8x11),
    switchMap(({ qrCardTemplate }) => this.qrCardHttpClient.saveQRCardTemplate(qrCardTemplate).pipe(
      map((result: any) => result.data),
      map((qrCardTemplates: QRCardModel[]) => {
        this.snackBarService.popSnack('alerts.saved.text', true);
        return QRCardsActions.setSavedQRCardTemplate8x11({ qrCardTemplates });
      }),
      catchError(error => {
        return of(QRCardsActions.error({ error: error.error.message }));
      })
    ))
  ));
}
