import { AreasState, areasFeatureName } from './areas.reducer';

import { createFeatureSelector, createSelector } from '@ngrx/store';

export const getAreasFeatureState = createFeatureSelector(areasFeatureName);

export const selectCurrentArea = createSelector(
  getAreasFeatureState,
  (state: AreasState) => state.area
);

export const selectAreaTrace = createSelector(
  getAreasFeatureState,
  (state: AreasState) => state.areaTrace
);

export const selectAreas = createSelector(
  getAreasFeatureState,
  (state: AreasState) => state.areas
);

export const selectLoading = createSelector(
  getAreasFeatureState,
  (state: AreasState) => state.loading
);

export const selectLoaded = createSelector(
  getAreasFeatureState,
  (state: AreasState) => state.loaded
);

export const selectTraceAreasDashboard = createSelector(
  getAreasFeatureState,
  (state: AreasState) => state.traceAreasDashboard
);

export const selectTraceAreasMonthReport = createSelector(
  getAreasFeatureState,
  (state: AreasState) => {
    return { data: state.traceAreaReportData, pagingData: state.pagingData };
  }
);

export const selectFullReportExport = createSelector(
  getAreasFeatureState,
  (state: AreasState) => state.fullExportReportData
);


