export interface CheckinModel {
  id: number;
  location_id: number;
  franchise_type_id: number;
  firstName: string;
  lastName: string;
  host: string;
  licensePlate: string;
  email: string;
  phone: string;
  isRiskFree: boolean;
  numberInParty: number;
  partyMembers: string;
  created_at: Date;
  company: string;
}

export let NewCheckinModel = {
  id: 0,
  location_id: 0,
  franchise_type_id: 0,
  firstName: '',
  lastName: '',
  host: '',
  licensePlate: '',
  email: '',
  phone: '',
  isRiskFree: true,
  numberInParty: 1,
  partyMembers: null,
  created_at: null,
  company: ''
};
