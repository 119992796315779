import { state } from '@angular/animations';
import { Action, createReducer, on } from '@ngrx/store';
import { UserModel } from '@trakr-safety/core/auth/models/user.model';
import * as AuthActions from './auth.actions';

export const authFeatureName = 'auth';

export interface AuthState {
  user: UserModel;
  username: string;
  passwordMissing: boolean;
  userIdRequested: boolean;
  userIdRequestCompleted: boolean;
  userIdRequestResponse: string;
  passwordResetRequestCompleted: boolean;
  passwordResetRequestRequested: boolean;
  passwordResetResponse: string;
  passwordChangeResponse: string;
  convertNonPasswordAccountResponse: string;
  convertNonPasswordAccountCompleted: boolean;
  resetTokenValid: boolean;
  passwordChangeCompleted: boolean;
  registerUserStarted: boolean;
  registerUserCompleted: boolean;
  registerUserResponse: string;
  registerUserTokenValid: boolean;
  userEmailVerified: boolean;
  userEmailVerifiedResponse: string;
  isLoggedIn: boolean;
  isLoggingIn: boolean;
  error: string;
}

export const initialState: AuthState = {
  user: null,
  username: undefined,
  passwordMissing: false,
  userIdRequested: false,
  userIdRequestCompleted: false,
  userIdRequestResponse: undefined,
  passwordResetRequestCompleted: false,
  passwordResetRequestRequested: false,
  passwordResetResponse: undefined,
  passwordChangeResponse: undefined,
  convertNonPasswordAccountResponse: undefined,
  convertNonPasswordAccountCompleted: false,
  resetTokenValid: false,
  passwordChangeCompleted: false,
  registerUserStarted: false,
  registerUserCompleted: false,
  registerUserResponse: undefined,
  registerUserTokenValid: false,
  userEmailVerified: false,
  userEmailVerifiedResponse: undefined,
  isLoggedIn: false,
  isLoggingIn: false,
  error: null
};

const authReducer = createReducer(
  initialState,
  on(AuthActions.autoLoginComplete, (state, { user }) => ({
    ...state,
    user,
    isLoggedIn: true,
    isLoggingIn: false,
    error: null
  })),
  on(AuthActions.loginComplete, (state, { user }) => ({
    ...state,
    user,
    isLoggedIn: true,
    isLoggingIn: false,
    error: null
  })),
  on(AuthActions.loginStart, (state) => ({
    ...state,
    isLoggingIn: true,
    error: null
  })),
  on(AuthActions.logout, (state) => ({
    ...state,
    user: null,
    isLoggedIn: false,
    passwordMissing: false,
    error: null
  })),
  on(AuthActions.setResetTokenValid, (state, { resetTokenValid }) => ({
    ...state,
    resetTokenValid
  })),
  on(AuthActions.requestUserIdEmail, (state) => ({
    ...state,
    userIdRequested: true,
    userIdRequestCompleted: false,
  })),
  on(AuthActions.requestUserIdComplete, (state, { userIdRequestResponse }) => ({
    ...state,
    userIdRequestResponse,
    userIdRequested: false,
    userIdRequestCompleted: true,
    error: null,
  })),
  on(AuthActions.clearUserIdRequest, (state) => ({
    ...state,
    userIdRequestResponse: undefined,
    userIdRequested: false,
    userIdRequestCompleted: false,
    error: null,
  })),
  on(AuthActions.registerUser, (state) => ({
    ...state,
    registerUserStarted: true,
    registerUserCompleted: false,
    registerUserResponse: undefined,
    error: null
  })),
  on(AuthActions.registerUserCompleted, (state, { registerUserResponse }) => ({
    ...state,
    registerUserStarted: false,
    registerUserCompleted: true,
    registerUserResponse
  })),
  on(AuthActions.clearRegisterUserRequest, (state) => ({
    ...state,
    registerUserResponse: undefined,
    registerUserStarted: false,
    registerUserCompleted: false,
    error: null
  })),
  on(AuthActions.setRegisterUserTokenValid, (state, { registerUserTokenValid, userEmailVerifiedResponse }) => ({
    ...state,
    registerUserTokenValid,
    userEmailVerifiedResponse
  })),
  on(AuthActions.requestResetPasswordLink, (state) => ({
    ...state,
    passwordResetRequestRequested: true,
    passwordChangeCompleted: false,
  })),
  on(AuthActions.requestResetPasswordLinkComplete, (state, { passwordResetResponse }) => ({
    ...state,
    passwordResetResponse,
    passwordResetRequestCompleted: true,
    passwordResetRequestRequested: false,
    error: null,
  })),
  on(AuthActions.changePassword, (state) => ({
    ...state,
    passwordChangeResponse: null,
    error: null,
  })),
  on(AuthActions.changePasswordComplete, (state, { passwordChangeResponse }) => ({
    ...state,
    passwordChangeResponse,
    passwordChangeCompleted: true,
    error: null,
  })),
  on(AuthActions.convertNonPasswordAccount, (state) => ({
    ...state,
    convertNonPasswordAccountResponse: null,
    convertNonPasswordAccountCompleted: false,
    error: null,
  })),
  on(AuthActions.convertNonPasswordAccountComplete, (state, { convertNonPasswordAccountResponse }) => ({
    ...state,
    convertNonPasswordAccountResponse,
    convertNonPasswordAccountCompleted: true,
    error: null,
  })),
  on(AuthActions.checkPasswordRequired, (state, { username }) => ({
    ...state,
    username,
    error: null
  })),
  on(AuthActions.setPasswordMissing, (state, { passwordMissing }) => ({
    ...state,
    passwordMissing,
    error: null
  })),
  on(AuthActions.clearError, (state) => ({
    ...state,
    error: null
  })),
  on(AuthActions.errorPasswordChange, (state, { error }) => ({
    ...state,
    passwordChangeResponse: undefined,
    passwordChangeCompleted: false,
    error
  })),
  on(AuthActions.errorPasswordResetRequest, (state, { error }) => ({
    ...state,
    passwordResetResponse: undefined,
    passwordResetRequestRequested: false,
    passwordResetRequestCompleted: false,
    error
  })),
  on(AuthActions.clearPasswordResetRequest, (state) => ({
    ...state,
    passwordResetResponse: undefined,
    passwordResetRequestRequested: false,
    passwordResetRequestCompleted: false,
    error: null
  })),
  on(AuthActions.error, (state, { error }) => ({
    ...state,
    user: null,
    error
  }))
);

export function reducer(state: AuthState | undefined, action: Action) {
  return authReducer(state, action);
}
