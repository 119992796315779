import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { empty, Observable, of } from 'rxjs';
import { environment } from 'environments/environment';
import { LanguageModel } from '@trakr-safety/core/language-selector/models/language.model';
import { catchError, filter, first, map, retry, shareReplay, timeout } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class LanguagesHttpClient {
  constructor(private http: HttpClient) { }

  fetchLanguages(): Observable<LanguageModel[]> {
    return this.http.get(`${environment.baseSharedApiURL}languages`).pipe(
      first(),
      timeout(10000),
      retry(4),
      shareReplay(),
      map((response: any) => response.data),
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          console.error(`Error: ${error.error.message}`);
        } else {
          console.error(`Error: ${error.message}`);
        }
        return of([]);
      })
    );
  }
}
