import { Actions, ofType, createEffect } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { AdministrationHttpClient } from '../http-clients/administration.http-client';
import * as AdminActions from './administration.actions';
import { SnackBarServiceService } from '@trakr-safety/core/services/snack-bar-service.service';


@Injectable()
export class AdministrationEffects {

  constructor(
    private _snackBarService: SnackBarServiceService,
    private _actions$: Actions,
    private _http: AdministrationHttpClient) { }

  /** fetch all settings */
  fetchAllSettings$ = createEffect(() => this._actions$.pipe(
    ofType(AdminActions.fetchAllSettings),
    switchMap(({ languageCode }) =>
      forkJoin([
        this._http.fetchManagers(),
        this._http.fetchScreeningContacts(),
        this._http.fetchSystemSettings(),
        this._http.fetchFailedAssessmentMessage(languageCode),
        this._http.fetchContactTracingSettings(),
        this._http.fetchDefaultScreeningLanguage()
      ]).pipe(
        map(([
          managers,
          screeningContacts,
          systemSettings,
          failedAssessmentMessage,
          contactTracingSettings,
          defaultScreeningLanguage,
        ]) => AdminActions.setAllSettings({
          managers,
          screeningContacts,
          systemSettings,
          failedAssessmentMessage,
          contactTracingSettings,
          defaultScreeningLanguage
        })),
        catchError(error => of(AdminActions.error({ error: error.message })))
      )
    ))
  );

  /** screening notifications */
  fetchManagers$ = createEffect(() => this._actions$.pipe(
    ofType(AdminActions.fetchManagers),
    switchMap(_ => this._http.fetchManagers().pipe(
      map(managers => {
        return AdminActions.setManagers({ managers })
      }),
      catchError(error => of(AdminActions.error({ error: error.message }))))
    ))
  );

  fetchScreeningContacts$ = createEffect(() => this._actions$.pipe(
    ofType(AdminActions.fetchScreeningContacts),
    switchMap(_ => this._http.fetchScreeningContacts().pipe(
      map((response: any) => response.data),
      map(records => AdminActions.setScreeningContacts({ screeningContacts: records.map((record: any) => record.user) })),
      catchError(error => of(AdminActions.error({ error: error.message })))
    ))
  ));

  postScreeningContacts$ = createEffect(() => this._actions$.pipe(
    ofType(AdminActions.updateScreeningContacts),
    switchMap((action) => this._http.saveScreeningContacts(action.ids).pipe(
      map((response: any) => response.data),
      map(records => {
        this._snackBarService.popSnack('alerts.saved.text', true);
        const managers = records.map((record: any) => record.user);
        return AdminActions.setScreeningContacts({ screeningContacts: managers });
      }),
      catchError(error => of(AdminActions.error({ error: error.message })))
    ))
  ));

  /** default screening language  */

  /** fetch default screenign language */
  fetchDefaultScreeningLanguage$ = createEffect(() => this._actions$.pipe(
    ofType(AdminActions.fetchDefaultScreeningLanguage),
    switchMap(_ => this._http.fetchDefaultScreeningLanguage().pipe(
      map(defaultScreeningLanguage => AdminActions.setDefaultScreeningLanguage({ defaultScreeningLanguage })),
      catchError(error => of(AdminActions.error({ error: error.message })))
    ))
  ));

  postDefaultScreeningLanguage$ = createEffect(() => this._actions$.pipe(
    ofType(AdminActions.saveDefaultScreeningLanguage),
    switchMap(({ defaultLanguage }) => this._http.saveDefaultScreeningLanguage(defaultLanguage).pipe(
      map(defaultScreeningLanguage => {
        this._snackBarService.popSnack('alerts.saved.text', true);
        return AdminActions.setDefaultScreeningLanguage({ defaultScreeningLanguage })
      }),
      catchError(error => of(AdminActions.error({ error: error.message })))
    ))
  ));

  /** failed screening assessment message */
  fetchFaileAssessmentMessage$ = createEffect(() => this._actions$.pipe(
    ofType(AdminActions.fetchFailedAssessmentMessage),
    switchMap(({ languageCode }) => this._http.fetchFailedAssessmentMessage(languageCode).pipe(
      map(failedAssessmentMessage => AdminActions.setFailedAssessmentMessage({ failedAssessmentMessage })),
      catchError(error => of(AdminActions.error({ error: error.message })))
    ))
  ));

  postFailedAssessmentMessage = createEffect(() => this._actions$.pipe(
    ofType(AdminActions.saveFailedAssessmentMessage),
    switchMap((action) => this._http.saveFailedAssessmentMessage(action.failedAssessmentMessage).pipe(
      map((response: any) => response.data),
      map(failedAssessmentMessage => {
        this._snackBarService.popSnack('alerts.saved.text', true);
        return AdminActions.setFailedAssessmentMessage({ failedAssessmentMessage })
      }),
      catchError(error => of(AdminActions.error({ error: error.message })))
    ))
  ));

  /** contact tracing settings */
  fetchContactTracingSettings$ = createEffect(() => this._actions$.pipe(
    ofType(AdminActions.fetchContactTracingSettings),
    switchMap(_ => this._http.fetchContactTracingSettings().pipe(
      map((response: any) => response.data),
      map(contactTracingSettings => AdminActions.setContactTracingSettings({ contactTracingSettings })),
      catchError(error => of(AdminActions.error({ error: error.message })))
    ))
  ));

  postContactTracingSettings$ = createEffect(() => this._actions$.pipe(
    ofType(AdminActions.saveContactTracingSettings),
    switchMap((action) => this._http.saveContactTracingSettings(action.settings)
      .pipe(
        map((response: any) => response.data),
        map(contactTracingSettings => {
          this._snackBarService.popSnack('alerts.saved.text', true);
          return AdminActions.setContactTracingSettings({ contactTracingSettings });
        }),
        catchError(error => {
          return of(AdminActions.error({ error: error.message }));
        })))
  ));

  /** system/info protection settings */
  postSystemSettings$ = createEffect(() => this._actions$.pipe(
    ofType(AdminActions.saveSystemSettings),
    switchMap((action) => this._http.saveSystemSettings(action.settings).pipe(
      map((response: any) => response.data),
      map(systemSettings => {
        this._snackBarService.popSnack('alerts.saved.text', true);
        return AdminActions.setSystemSettings({ systemSettings });
      }),
      catchError(error => of(AdminActions.error({ error: error.message })))
    ))
  ));

  fetchSystemSettings$ = createEffect(() => this._actions$.pipe(
    ofType(AdminActions.fetchSystemSettings),
    switchMap(_ => this._http.fetchSystemSettings().pipe(
      map((response: any) => response.data),
      map(systemSettings => AdminActions.setSystemSettings({ systemSettings })),
      catchError(error => of(AdminActions.error({ error: error.message })))
    ))
  ));

  /** branding */
  uploadLogo$ = createEffect(() => this._actions$.pipe(
    ofType(AdminActions.uploadLogo),
    switchMap((action) => this._http.saveLogo(action.image).pipe(
      map(response => {
        if (response.result === 'success') {
          this._snackBarService.popSnack('alerts.upload.success.text', true);
        }
        return AdminActions.uploadComplete();
      }),
      catchError(error => of(AdminActions.error({ error: error.message })))
    ))
  ));

  uploadReportLogo$ = createEffect(() => this._actions$.pipe(
    ofType(AdminActions.uploadReportLogo),
    switchMap((action) => this._http.saveReportLogo(action.reportLogo).pipe(
      map(response => {
        if (response.result === 'success') {
          this._snackBarService.popSnack('alerts.upload.success.text', true);
        }
        return AdminActions.uploadComplete();
      }),
      catchError(error => of(AdminActions.error({ error: error.message })))
    ))
  ));
}
