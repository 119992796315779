import { Pipe, PipeTransform } from '@angular/core';
import { LocalstorageService } from '../services/localstorage.service';
@Pipe({
  name: 'tenantKeyPrepend',
})
export class TenantKeyPrependPipe implements PipeTransform {

  constructor(private mLocalStorageService: LocalstorageService) { }

  transform(value: string, ...args: unknown[]): string {
    const tenantKey = this.mLocalStorageService.getNestKeyValue('tenant', 'tenantKey');
    return tenantKey ? `/${tenantKey}${value}` : value;
  }
}
