import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { QRCardModel } from '../models/qr-card.model';
import { first, timeout, retry, shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class QRCardsHttpClient {

  constructor(private http: HttpClient) { }

  fetchQRCardTemplates(): Observable<QRCardModel[]> {
    return this.http.get<QRCardModel[]>(`${environment.baseWebApiURL}scancard/templates`).pipe(
      first(),
      timeout(10000),
      retry(4),
      shareReplay());
  }

  saveQRCardTemplate(cardTemplate: QRCardModel): Observable<QRCardModel[]> {
    if (cardTemplate.id === 0) {
      // save new
      return this.http.post<QRCardModel[]>(`${environment.baseWebApiURL}scancard/templates`, cardTemplate);
    }
    else {
      // update
      return this.http.put<QRCardModel[]>(`${environment.baseWebApiURL}scancard/templates/${cardTemplate.id}`, cardTemplate);
    }
  }
}
