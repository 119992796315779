import { AppSettingsState, appSettingsFeatureName } from './app-settings.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export const getAppSettingsFeatureState = createFeatureSelector(appSettingsFeatureName);

export const selectKioskModeSetting = createSelector(
  getAppSettingsFeatureState,
  (state: AppSettingsState) => state.kioskMode
);

export const selectLanguages = createSelector(
  getAppSettingsFeatureState,
  (state: AppSettingsState) => state.languages
);

export const selectDefaultLanguage = createSelector(
  getAppSettingsFeatureState,
  (state: AppSettingsState) => ({
    language_id: state.language_id,
    language: state.language
  })
);

export const selectFailureMessage = createSelector(
  getAppSettingsFeatureState,
  (state: AppSettingsState) => state.failedScreeningMessage
);

export const selectSystemSettings = createSelector(
  getAppSettingsFeatureState,
  (state: AppSettingsState) => ({
    enableRegistration: state.enableRegistration,
    enableContactTracing: state.enableContactTracing,
    enableVaccinationVerify: state.enableVaccinationVerify,
    enableShowVerifiedOnPass: state.enableShowVerifiedOnPass,
    enableVaccinationAttestation: state.enableVaccinationAttestation,
    enableVaccinationTracking: state.enableVaccinationTracking,
    vaccinationTrackingLink: state.vaccinationTrackingLink
  })
);

export const selectContactTracingSettings = createSelector(
  getAppSettingsFeatureState,
  (state: AppSettingsState) => ({
    healthAffirmation: state.healthAffirmation,
    enableHealthAffirmation: state.enableHealthAffirmation,
    enableAdditionalGuestInfo: state.enableAdditionalGuestInfo
  })
);

export const userCategories = createSelector(
  getAppSettingsFeatureState,
  (state: AppSettingsState) => state.userCategories
);

export const roles = createSelector(
  getAppSettingsFeatureState,
  (state: AppSettingsState) => state.roles
);

export const companies = createSelector(
  getAppSettingsFeatureState,
  (state: AppSettingsState) => state.companies
);

export const userTypes = createSelector(
  getAppSettingsFeatureState,
  (state: AppSettingsState) => state.userTypes
);

export const managers = createSelector(
  getAppSettingsFeatureState,
  (state: AppSettingsState) => state.managers
);

export const selectLoading = createSelector(
  getAppSettingsFeatureState,
  (state: AppSettingsState) => state.loading
);

export const selectLoaded = createSelector(
  getAppSettingsFeatureState,
  (state: AppSettingsState) => state.loaded
);
