import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree, CanLoad, Route, UrlSegment } from '@angular/router';
import { AuthFacade } from '../facades/auth.facade';
import { TenantKeyPrependPipe } from '../../pipes/tenant-key-prepend.pipe';
import { UserModel } from '../models/user.model';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanLoad {

  constructor(
    private pAuthFacade: AuthFacade,
    private tenantKeyPrependPipe: TenantKeyPrependPipe,
    private router: Router) {
  }

  private isLoggedIn(): boolean | UrlTree {
    const userData: UserModel = this.pAuthFacade.authUser;
    const isAuth: boolean = !!userData;

    if (!isAuth) {
      return this.router.createUrlTree([this.tenantKeyPrependPipe.transform('/login')]);
    }

    return true;
  }
  canLoad(route: Route, segments: UrlSegment[]): boolean | UrlTree {
    return this.isLoggedIn();
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    | boolean
    | UrlTree {
    return this.isLoggedIn();
  }
}

