import { SelfAssessmentModel } from './self-assessment.model';

export interface SelfAssessmentsDashModel {
  currentDayResults: {
    totalEmployees: number;
    testedEmployees: number;
    passed: number;
    failed: number;
  };
  weekReviewTested: SelfAssessmentModel[];
  weekReviewPassed: SelfAssessmentModel[];
  weekReviewFailed: SelfAssessmentModel[];
}

export let NewSelfAssessmentsDashModel = {
  currentDayResults: {
    totalEmployees: 0,
    testedEmployees: 0,
    passed: 0,
    failed: 0
  },
  weekReviewTested: [],
  weekReviewPassed: [],
  weekReviewFailed: []
};
