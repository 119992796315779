import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replaceLineBreaks'
})
export class ReplaceLineBreaksPipe implements PipeTransform {

  transform(value: string, arg: string): string {
    return value.replace(/(\r\n|\n\r|\r|\n)/g, arg);
  }

}
