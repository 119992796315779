import { Action, createReducer, on } from '@ngrx/store';
import { FeatureStore } from 'app/store/feature-store.enum';
import * as QuestionActions from './questions.actions';
import { Question } from '../models/question.model';

export const FeatureName = FeatureStore.QUESTIONS;

export interface State {
  questions: Question[];
  selectedQuestion: Question;
  usingDefaultLanguageSet: boolean,
  defaultLanguage: string,
  loading: boolean;
  loaded: boolean;
  error: string;
}

export const initialState: State = {
  questions: undefined,
  selectedQuestion: undefined,
  usingDefaultLanguageSet: false,
  defaultLanguage: null,
  loading: false,
  loaded: false,
  error: null
};

const questionReducer = createReducer(
  initialState,
  on(QuestionActions.fetchAllQuestions, (state) => ({
    ...state,
    loading: true,
    loaded: false
  })),
  on(QuestionActions.fetchQuestion, (state) => ({
    ...state,
    loading: true,
    loaded: false
  })),
  on(QuestionActions.setQuestions, (state, { questions }) => ({
    ...state,
    questions,
    loading: false,
    loaded: true
  })),
  on(QuestionActions.setQuestions, (state, { questions }) => ({
    ...state,
    questions,
    usingDefaultLanguageSet: false,
    defaultLanguage: null,
    loading: false,
    loaded: true
  })),
  on(QuestionActions.setDefaultQuestions, (state, { questions, usingDefaultLanguageSet, defaultLanguage }) => ({
    ...state,
    questions,
    usingDefaultLanguageSet,
    defaultLanguage,
    loading: false,
    loaded: true
  })),
  on(QuestionActions.setQuestion, (state, { question }) => ({
    ...state,
    question,
    loading: false,
    loaded: true
  })),
  on(QuestionActions.unloaded, () => ({
    ...initialState,
  })),
  on(QuestionActions.newQuestion, (state) => ({
    ...state,
    question: {}
  })),
  on(QuestionActions.error, (state, { error }) => ({
    ...state,
    error,
    loading: false,
    loaded: true
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return questionReducer(state, action);
}
