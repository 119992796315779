
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { TenantState, tenantsFeatureName } from './tenant.reducer';

export const getTenantFeatureState = createFeatureSelector(tenantsFeatureName);

export const tenant = createSelector(
  getTenantFeatureState,
  (state: TenantState) => ({
    locationId: state.locationId,
    orgId: state.orgId,
    tenantKey: state.tenantKey
  })
);

export const tenantKey = createSelector(
  getTenantFeatureState,
  (state: TenantState) => state.tenantKey
);

export const orgId = createSelector(
  getTenantFeatureState,
  (state: TenantState) => state.orgId
);
