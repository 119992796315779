import { PagingModel } from '@trakr-safety/core/models/paging.model';
import { UserModel } from '@trakr-safety/core/auth/models/user.model';
import { AssessmentAnswerModel } from '@trakr-safety/health-screening/models/assessment-answer.model';
import { SelfAssessmentsDashModel } from '@trakr-safety/health-screening/models/dash-summary.model';
import { SelfAssessmentModel } from '@trakr-safety/health-screening/models/self-assessment.model';

import { Action } from '@ngrx/store';

// answers
export const SAVE_ANSWERS = '[HealthAssessments] SAVE_ANSWERS';
export const SAVE_ANSWERS_PROXY = '[HealthAssessments] SAVE_ANSWERS_PROXY';
export const POST_ANSWERS = '[HealthAssessments] POST_ANSWERS';
export const FETCH_ALL_ANSWERS = '[HealthAssessments] FETCH_ALL_ANSWERS';

export const TAG_AREA_TO_ASSESSMENT = '[HealthAssessments] TAG_AREA_TO_ASSESSMENT';
export const FETCH_ASSESSMENT = '[HealthAssessments] FETCH_ASSESSMENT';
export const SET_ASSESSMENT = '[HealthAssessments] SET ASSESSMENT';
export const DELETE_ASSESSMENT = '[HealthAssessments] DELETE ASSESSMENT';
export const FETCH_AREA_ASSESSMENT = '[HealthAssessments] FETCH_AREA_ASSESSMENT';

export const FETCH_ASSESSMENTS = '[HealthAssessments] FETCH_ASSESSMENTS';
export const IS_LOADED = '[HealthAssessments] Is Loaded';
export const FETCH_AREA_ASSESSMENTS = '[HealthAssessments] FETCH_AREA_ASSESSMENTS';

export const FETCH_DASH_SUMMARY = '[HealthAssessments]  FETCH_DASH_SUMMARY';
export const SET_DASH_SUMMARY = '[HealthAssessments]  SET_DASH_SUMMARY';
export const CLEAR_ANSWSERS = '[HealthAssessments] CLEAR_ANSWERS';

export const FETCH_PROXY_USER = '[HealthAssessments] FETCH_PROXY_USER';
export const FETCH_ALL_TIME_REPORT_EMPLOYEES = '[HealthAssessments] FETCH_ALL_TIME_REPORT_EMPLOYEES';
export const FETCH_MONTH_REPORT_EMPLOYEES = '[HealthAssessments] FETCH_MONTH_REPORT_EMPLOYEES';

export const FETCH_ASSESSMENT_DETAILS_REPORT = '[HealthAssessments] FETCH_ASSESSMENT_DETAILS_REPORT';
export const FETCH_ASSESSMENT_HISTORY_REPORT = '[HealthAssessments] FETCH_ASSESSMENT_HISTORY_REPORT';

export const FETCH_ALL_TIME_REPORT_VISITORS = '[HealthAssessments] FETCH_ALL_TIME_REPORT_VISITORS';
export const FETCH_MONTH_REPORT_VISITORS = '[HealthAssessments] FETCH_MONTH_REPORT_VISITORS';

export const FETCH_NON_SCREENED_EMPLOYEES = '[HealthAssessments] FETCH_NON_SCREENED_EMPLOYEES';

export const CLEAR_STATE = '[HealthAssessments] CLEAR_STATE';
export const REPLACE_STATE = '[HealthAssessments] REPLACE_STATE';
export const SET_FILTER_STRING = '[HealthAssessments] SET_FILTER_STRING';

export const ERROR = '[HealthAssessments] ERROR';

export const UNLOADED = '[HealthAssessments] UNLOADED';

export class ReplaceState implements Action {
  readonly type = REPLACE_STATE;
  constructor(public payload: {
    selfAssessment?: SelfAssessmentModel,
    selfAssessments?: SelfAssessmentModel[],
    allTimeReportData?: SelfAssessmentModel[],
    monthReportData?: SelfAssessmentModel[],
    detailReportData?: SelfAssessmentModel,
    historyReportData?: SelfAssessmentModel[],
    dashSummary?: SelfAssessmentsDashModel,
    nonScreenedEmployees?: UserModel[],
    proxyUserFullName?: string,
    filterString?: string,
    error?: string,
    pagingData?: PagingModel,
  }) { }
}


export class SetDashSummary implements Action {
  readonly type = SET_DASH_SUMMARY;
  constructor(public dashSummary: SelfAssessmentsDashModel) { }
}

export class SetAssessment implements Action {
  readonly type = SET_ASSESSMENT;
  constructor(public assessmentData: SelfAssessmentModel) { }
}
export class ClearState implements Action {
  readonly type = CLEAR_STATE;
}

export class Error implements Action {
  readonly type = ERROR;
  constructor(public payload: string) { }
}

// answers
export class ClearAnswers implements Action {
  readonly type = CLEAR_ANSWSERS;
}

export class SaveAnswers implements Action {
  readonly type = SAVE_ANSWERS;
  constructor(public payload: SelfAssessmentModel) { }
}

export class SaveAnswersProxy implements Action {
  readonly type = SAVE_ANSWERS_PROXY;
  constructor() { }
}

export class PostAnswers implements Action {
  readonly type = POST_ANSWERS;
  constructor(public payload: {
    assessmentAnswers: AssessmentAnswerModel[],
    areaId: number,
    languageCode: string,
    isProxy: boolean,
    proxyUserId: number,
    proxyManagerId: number
  }) { }
}

export class FetchProxyUser implements Action {
  readonly type = FETCH_PROXY_USER;
  constructor(public payload: number) { }
}

export class FetchAssessment implements Action {
  readonly type = FETCH_ASSESSMENT;
  constructor(public payload: number) { }
}

export class IsLoaded implements Action {
  readonly type = IS_LOADED;
  constructor(public isLoaded: boolean) { }
}

export class DeleteAssessment implements Action {
  readonly type = DELETE_ASSESSMENT;
  constructor(public assessmentId: number, public userType: string) { }
}

export class FetchAreaAssessment implements Action {
  readonly type = FETCH_AREA_ASSESSMENT;
  constructor(public payload: { userId: number, areaId: number }) { }
}

export class FetchAssessments implements Action {
  readonly type = FETCH_ASSESSMENTS;
}

export class FetchAreaAssessments implements Action {
  readonly type = FETCH_AREA_ASSESSMENTS;
  constructor(public payload: { areaId: number }) { }
}

export class FetchMonthReportEmployees implements Action {
  readonly type = FETCH_MONTH_REPORT_EMPLOYEES;
}

export class FetchAllTimeReportEmployees implements Action {
  readonly type = FETCH_ALL_TIME_REPORT_EMPLOYEES;
}

export class FetchAssessmentDetailsReport implements Action {
  readonly type = FETCH_ASSESSMENT_DETAILS_REPORT;
  constructor(public payload: number) { } // assessment id
}

export class FetchAssmessmentsHistoryReport implements Action {
  readonly type = FETCH_ASSESSMENT_HISTORY_REPORT;
  constructor(public payload: number) { } // user id
}

export class FetchDashSummary implements Action {
  readonly type = FETCH_DASH_SUMMARY;
}

export class FetchAllTimeReportVisitors implements Action {
  readonly type = FETCH_ALL_TIME_REPORT_VISITORS;
}

export class FetchMonthReportVisitors implements Action {
  readonly type = FETCH_MONTH_REPORT_VISITORS;
}

export class Unloaded implements Action {
  readonly type = UNLOADED;
}
export class FetchNonScreenedEmployees implements Action {
  readonly type = FETCH_NON_SCREENED_EMPLOYEES;
  constructor(public payload: {
    filter?: string,
    date?: string,
    fromTime?: string,
    toTime?: string,
    search?: string,
    sortCol?: string,
    sortOrder?: string,
    page: number,
    pageSize: number
  }) { }
}

export class TagAreaToAssessment implements Action {
  readonly type = TAG_AREA_TO_ASSESSMENT;
  constructor(public payload: {
    areaId: number,
    isKioskMode: boolean,
    tenantKey: string
  }) { }
}

export class SetFilterString implements Action {
  readonly type = SET_FILTER_STRING;
  constructor(public payload: string) { }
}

export type HealthAssessmentActions = TagAreaToAssessment | FetchNonScreenedEmployees | SetAssessment |
  ReplaceState | ClearState | ClearAnswers |
  SaveAnswers | FetchAssessment | FetchAssessments | FetchAreaAssessment | FetchAreaAssessments |
  FetchMonthReportEmployees | FetchMonthReportVisitors | FetchDashSummary |
  FetchAssessmentDetailsReport | FetchAssmessmentsHistoryReport |
  FetchAllTimeReportEmployees | FetchAllTimeReportVisitors | SetFilterString |
  Error | PostAnswers | Unloaded | IsLoaded | DeleteAssessment | FetchProxyUser | SaveAnswersProxy | SetDashSummary;

