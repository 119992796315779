import { NgModule } from '@angular/core';
import { StripHtmlPipe } from './strip-html.pipe';

const declarations = [StripHtmlPipe];

@NgModule({
  declarations: [...declarations],
  exports: [...declarations]
})
export class StripHtmlPipeModule { }
