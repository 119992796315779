import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, tap, withLatestFrom } from 'rxjs/operators';
import * as fromApp from 'app/store/app.reducer';
import * as AppSettingsSelectors from '@trakr-safety/core/app-settings/store/app-settings.selectors';
import * as AppSettingsActions from '@trakr-safety/core/app-settings/store/app-settings.actions';
import { LanguageModel } from '../../language-selector/models/language.model';
import { LanguageSettingsModel } from '@trakr-safety/administration/models/languageSettings.model';

import { RoleModel } from '../../auth/models/role.model';
import { UserTypeModel } from '../../auth/models/user_type.model';
import { UserCategoryModel } from '../../models/user-category.model';
import { ManageUserModel } from '@trakr-safety/users/models/manage-user.model';
import { CompanyModel } from '../../models/company.model';
import { DataKeys, LocalstorageService } from '../../services/localstorage.service';
import { DefaultLanguageModel } from '@trakr-safety/core/app-settings/models/default-language.model';


@Injectable({
  providedIn: 'root'
})
export class AppSettingsFacade {

  constructor(
    private store: Store<fromApp.AppState>,
    private mLocalStorageService: LocalstorageService) { }

  /** state handling */

  // set state value
  setKioskStateSetting(val: boolean) {
    this.store.dispatch(AppSettingsActions.setKioskMode({ kioskMode: val }));
  }

  /** local storage handling */
  // set storage value
  setKioskModeStorageSetting(val: string) {
    const isKioskMode = val.toLowerCase() === 'true';

    if (!isKioskMode) {
      this.mLocalStorageService.delete(DataKeys.KIOSKMODE);
      return;
    }

    this.mLocalStorageService.set(DataKeys.KIOSKMODE, isKioskMode.toString());
  }

  // get storage value
  get kioskMode(): string {
    if (!this.mLocalStorageService.check(DataKeys.KIOSKMODE)) {
      return 'false';
    }
    return this.mLocalStorageService.get(DataKeys.KIOSKMODE);
  }

  getLanguages(): Observable<LanguageModel[]> {
    return this.store.select(AppSettingsSelectors.selectLanguages);
  }

  roles(): Observable<RoleModel[]> {
    return this.store.select(AppSettingsSelectors.roles).pipe(filter((roles) => roles !== undefined));
  }

  fetchCompanies() {
    this.store.dispatch(AppSettingsActions.fetchCompanies());
  }

  companies(): Observable<CompanyModel[]> {
    return this.store.select(AppSettingsSelectors.companies).pipe(filter((companies) => companies !== undefined));
  }

  managers(): Observable<ManageUserModel[]> {
    return this.store.select(AppSettingsSelectors.managers).pipe(filter((manager) => manager !== undefined));
  }

  userTypes(): Observable<UserTypeModel[]> {
    return this.store.select(AppSettingsSelectors.userTypes).pipe(filter((userTypes) => userTypes !== undefined));
  }

  categories(): Observable<UserCategoryModel[]> {
    return this.store.select(AppSettingsSelectors.userCategories).pipe(filter((category) => category !== undefined));
  }

  getDefaultLanguage(): Observable<DefaultLanguageModel> {
    return this.store.select(AppSettingsSelectors.selectDefaultLanguage);
  }

  getLanguagesAndDefault(): Observable<LanguageSettingsModel> {
    return combineLatest([
      this.getLanguages(),
      this.getDefaultLanguage()
    ]).pipe(
      map(([
        languages,
        defaultLanguage,
      ]) => ({
        languages,
        defaultLanguage
      }))
    )
  }

  getSystemSettings(): Observable<({
    enableShowVerifiedOnPass: boolean,
    enableVaccinationAttestation: boolean,
    enableVaccinationVerify: boolean,
    enableRegistration: boolean,
    enableContactTracing: boolean,
    enableVaccinationTracking: boolean,
    vaccinationTrackingLink: string
  })> {
    return this.store.select(AppSettingsSelectors.selectSystemSettings);
  }

  getFailureMessage(): Observable<string> {
    return this.store.select(AppSettingsSelectors.selectFailureMessage);
  }

  fetchSystemSettings(languageCode: string = 'en-ca', locationId: number) {
    this.store.dispatch(AppSettingsActions.fetchAppSettings({ languageCode, locationId }))
  }

  getSettingsLoaded() {
    return this.store.select(AppSettingsSelectors.selectLoading).pipe(
      withLatestFrom(this.store.select(AppSettingsSelectors.selectLoaded)));
  }
}
