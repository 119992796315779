import { Actions, ofType, Effect } from '@ngrx/effects';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

import { AreasFacade } from '@trakr-safety/areas/facades/areas.facade';
import * as CheckinsActions from './checkins.actions';
import { CheckinsHttpClient } from '@trakr-safety/checkins/httpclients/checkins.http-client';
import { environment } from 'environments/environment';
import { DataKeys, LocalstorageService } from '@trakr-safety/core/services/localstorage.service';
import { SnackBarServiceService } from '@trakr-safety/core/services/snack-bar-service.service';
import { TenantKeyPrependPipe } from '@trakr-safety/core/pipes/tenant-key-prepend.pipe';


@Injectable()
export class CheckinsEffects {

  constructor(
    private pActions$: Actions,
    private pAreasFacade: AreasFacade,
    private pCheckinsHttpClient: CheckinsHttpClient,
    private pCookieService: CookieService,
    private pLocalStorageService: LocalstorageService,
    private pTenantKeyPrependPipe: TenantKeyPrependPipe,
    private pRouter: Router,) { }

  @Effect()
  fetchMonthReport = this.pActions$.pipe(
    ofType(CheckinsActions.FETCH_MONTH_REPORT),
    switchMap(_ => {
      return this.pCheckinsHttpClient.getMonthReport()
        .pipe(
          map((resData: any) => {
            return new CheckinsActions.ReplaceState({ checkins: resData.data });
          }),
          catchError(error => {
            return of(new CheckinsActions.Error(error.message));
          }));
    }),
  );

  @Effect()
  fetchAllTimeReport = this.pActions$.pipe(
    ofType(CheckinsActions.FETCH_ALL_TIME_REPORT),
    switchMap(_ => {
      return this.pCheckinsHttpClient.getAllTimeReport()
        .pipe(
          map((resData: any) => {
            return new CheckinsActions.ReplaceState({ checkins: resData.data });
          }),
          catchError(error => {
            return of(new CheckinsActions.Error(error.message));
          }));
    }),
  );

  @Effect()
  fetchCheckin = this.pActions$.pipe(
    ofType(CheckinsActions.FETCH_CHECKIN),
    switchMap((action: CheckinsActions.FetchCheckin) => {
      return this.pCheckinsHttpClient.getCheckin(action.payload)
        .pipe(
          map((resData: any) => {
            return new CheckinsActions.ReplaceState({ checkin: resData.data });
          }),
          catchError(error => {
            return of(new CheckinsActions.Error(error.message));
          }));
    }),
  );

  @Effect({ dispatch: false })
  saveCheckin = this.pActions$.pipe(
    ofType(CheckinsActions.SAVE_CHECKIN),
    switchMap((action: CheckinsActions.SaveCheckin) => {
      return this.pCheckinsHttpClient.saveCheckin(action.payload.checkinData)
        .pipe(
          map((resData: any) => {
            if (resData.data) {
              const key = `saniTrakr_checkin_${action.payload.checkinData.franchise_type_id}_${action.payload.checkinData.location_id}`;
              const jsonData = JSON.stringify(resData.data);
              this.pCookieService.set(key, jsonData, { path: `/${action.payload.tenantKey}`, expires: 1 });
              this.pRouter.navigate([this.pTenantKeyPrependPipe.transform('/checkin-passport')]);
            }
          }),
          catchError(error => {
            return of(new CheckinsActions.Error(error.message));
          }));
    }),
  );

  @Effect({ dispatch: false })
  tagAreaToCheckin$ = this.pActions$.pipe(
    ofType(CheckinsActions.TAG_AREA_TO_CHECKIN),
    switchMap((action: CheckinsActions.TagAreaToCheckin) => {
      return this.pCheckinsHttpClient.tagAreaToCheckin(
        action.payload.locationId,
        action.payload.areaId,
        action.payload.contactTraceId
      ).pipe(
        map(_ => {
          if (!action.payload.isKioskMode === true) {
            // not in kiosk mode so clear area trace
            this.pLocalStorageService.delete(DataKeys.TRACEAREA);
            this.pAreasFacade.replaceTraceArea(undefined);
          }
        }),
        catchError(error => {
          return of(new CheckinsActions.Error(error.message));
        }));
    }),
  );

  // dash summary
  @Effect()
  fetchDashSummary = this.pActions$.pipe(
    ofType(CheckinsActions.FETCH_DASH_SUMMARY),
    switchMap(_ => {
      return this.pCheckinsHttpClient.getDashSummary()
        .pipe(
          map((resData: any) => {
            return new CheckinsActions.ReplaceState({ weekReviewDash: resData.data.weekReview });
          }),
          catchError(error => {
            return of(new CheckinsActions.Error(error.message));
          }));
    }),
  );
}
