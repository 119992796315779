import { Actions, ofType, createEffect } from '@ngrx/effects';
import * as AreaActions from '../store/areas.actions';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TenantKeyPrependPipe } from '@trakr-safety/core/pipes/tenant-key-prepend.pipe';
import { AreasHttpClient } from '../httpclients/areas.http-client';
import { DataKeys, LocalstorageService } from '@trakr-safety/core/services/localstorage.service';

@Injectable()
export class AreaEffects {
  constructor(
    private mLocalStorageService: LocalstorageService,
    private tenantKeyPrependPipe: TenantKeyPrependPipe,
    private router: Router,
    private actions$: Actions,
    private areasHttpClient: AreasHttpClient) { }

  addArea$ = createEffect(() => this.actions$.pipe(
    ofType(AreaActions.addArea),
    switchMap(({ area }) => this.areasHttpClient.createArea(area).pipe(
      tap(_ => this.router.navigate([this.tenantKeyPrependPipe.transform('/areas')])),
      catchError(error => {
        return of(AreaActions.error({ error: error.error.message }));
      })
    ))
  ), { dispatch: false });

  updateArea$ = createEffect(() => this.actions$.pipe(
    ofType(AreaActions.updateArea),
    switchMap(({ area }) => this.areasHttpClient.updateArea(area).pipe(
      tap(_ => this.router.navigate([this.tenantKeyPrependPipe.transform('/areas')])),
      catchError(error => {
        return of(AreaActions.error({ error: error.error.message }));
      })
    ))
  ), { dispatch: false });

  deleteArea$ = createEffect(() => this.actions$.pipe(
    ofType(AreaActions.deleteArea),
    switchMap(({ id }) => this.areasHttpClient.softDeleteArea(id).pipe(
      map(_ => AreaActions.fetchAreas()),
      catchError(error => {
        return of(AreaActions.error({ error: error.error.message }));
      })
    ))
  ));

  traceArea$ = createEffect(() => this.actions$.pipe(
    ofType(AreaActions.fetchTraceArea),
    switchMap(({ id, locationId, tenantKey }) => this.areasHttpClient.traceArea(id, locationId).pipe(
      map((response: any) => response.data),
      map(area => {
        this.mLocalStorageService.set(DataKeys.TRACEAREA, JSON.stringify({ id: area.id, nameEn: area.nameEn }));
        return AreaActions.replaceState({ payload: { areaTrace: area } });
      }),
      catchError(error => {
        return of(AreaActions.error({ error: error.error.message }));
      })
    ))
  ));

  fetchArea$ = createEffect(() => this.actions$.pipe(
    ofType(AreaActions.fetchArea),
    switchMap(({ id }) => this.areasHttpClient.getArea(id).pipe(
      map((response: any) => response.data),
      map(area => AreaActions.setArea({ area })),
      catchError(error => {
        return of(AreaActions.error({ error: error.error.message }));
      })
    ))
  ));

  fetchAreas$ = createEffect(() => this.actions$.pipe(
    ofType(AreaActions.fetchAreas),
    switchMap(_ => this.areasHttpClient.getAreas().pipe(
      map((response: any) => response.data),
      map(areas => AreaActions.setAreas({ areas })),
      catchError(error => {
        return of(AreaActions.error({ error: error.error.message }));
      })
    ))
  ));

  fetchTraceAreasDashboard$ = createEffect(() => this.actions$.pipe(
    ofType(AreaActions.fetchTraceAreasDashboard),
    switchMap(_ => this.areasHttpClient.getTraceAreasDashboard().pipe(
      map((response: any) => response.data),
      map(areas => AreaActions.replaceState({ payload: { traceAreasDashboard: areas } })),
      catchError(error => {
        return of(AreaActions.error({ error: error.error.message }));
      })
    ))
  ));

  fetchTraceAreasMonthReport$ = createEffect(() => this.actions$.pipe(
    ofType(AreaActions.fetchTraceAreasMonthReport),
    switchMap(({ filter, date, fromTime, toTime, search, sortCol, sortOrder, page, pageSize }) =>
      this.areasHttpClient.getTraceAreasMonthReportPaged(filter, search, sortCol, sortOrder, page, pageSize, date, fromTime, toTime).pipe(
        map((response: any) => {
          if (!response) {
            return AreaActions.isLoaded();
          }

          return AreaActions.setAreasTraceReport({ traceAreaReportData: response.data, pagingData: response.meta })
        }),
        catchError(error => {
          return of(AreaActions.error({ error: error.error.message }));
        })
      ))
  ));

  fetchTraceAreasAllTimeReport$ = createEffect(() => this.actions$.pipe(
    ofType(AreaActions.fetchTraceAreasAllTimeReport),
    switchMap(_ =>
      this.areasHttpClient.getTraceAreasAllTimeReport().pipe(
        map((response: any) => AreaActions.replaceState({ payload: { fullExportReportData: response.data } })),
        catchError(error => {
          return of(AreaActions.error({ error: error.error.message }));
        })
      ))
  ));

  fetchAreasPublic$ = createEffect(() => this.actions$.pipe(
    ofType(AreaActions.fetchAreasPublic),
    switchMap(({ locationId }) => this.areasHttpClient.getAreasPublic(locationId).pipe(
      map((response: any) => response.data),
      map(areas => AreaActions.setAreas({ areas })),
      catchError(error => {
        return of(AreaActions.error({ error: error.error.message }));
      })
    ))
  ));
}

