// hydration.actions.ts
import { createAction, props } from "@ngrx/store";
import * as fromApp from '../../../store/app.reducer';

export const hydrate = createAction("[Hydration] Hydrate");

export const hydrateSuccess = createAction(
  "[Hydration] Hydrate Success",
  props<{ state: fromApp.AppState }>()
);

export const hydrateFailure = createAction("[Hydration] Hydrate Failure");
