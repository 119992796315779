import { AssessmentQuestionModel } from '@trakr-safety/health-screening/models/assessment-question.model';
import { SortAssessmentQuestionModel } from '@trakr-safety/health-screening/models/sort-assessment-question.model';
import { createAction, props } from '@ngrx/store';

/** Health Assessment Questions */
// questions

export const nothing = createAction('[HealthQuestions] Do Nothing');
export const clearQuestions = createAction('[HealthQuestions] Clear Questions');

export const newQuestion = createAction('[HealthQuestions] New Question');

export const addQuestion = createAction('HealthQuestions] Add Question',
  props<{
    questionData: AssessmentQuestionModel
  }>()
);

export const updateQuestion = createAction('HealthQuestions] Update Question',
  props<{
    questionData: AssessmentQuestionModel,
  }>()
);

export const moveQuestion = createAction('HealthQuestions] Move Question',
  props<{
    sortData: SortAssessmentQuestionModel,
    langCode: string
  }>()
);

export const deleteQuestion = createAction('HealthQuestions] Delete Question',
  props<{
    questionId: number
    langCode: string
  }>()
);

export const fetchQuestion = createAction('HealthQuestions] Fetch Question',
  props<{
    questionId: number
  }>()
);

export const fetchAllQuestions = createAction('[HealthQuestions] Fetch All',
  props<{
    langCode: string
  }>()
);

export const setQuestions = createAction('[HealthQuestions] Set Questions',
  props<{
    questions: AssessmentQuestionModel[]
  }>()
);

export const setDefaultQuestions = createAction('[HealthQuestions] Set Default Questions',
  props<{
    questions: AssessmentQuestionModel[],
    usingDefaultLanguageSet: boolean,
    defaultLanguage: string
  }>()
);

export const unloaded = createAction('[HealthQuestions] Un-Loaded');

export const setQuestion = createAction('[HealthQuestions] Set Question',
  props<{
    question: AssessmentQuestionModel
  }>()
);

/** error handling */
export const error = createAction('[HealthQuestions] Error',
  props<{ error: string }>()
);



