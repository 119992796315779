import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { map, tap } from 'rxjs/operators';
import * as fromApp from 'app/store/app.reducer';
import * as AuthSelectors from '@trakr-safety/core/auth/store/auth.selectors';
import * as AuthActions from '@trakr-safety/core/auth/store/auth.actions';
import { UserModel } from '@trakr-safety/core/auth/models/user.model';
import { RegistrationModel } from '../models/registration.model';
import { DataKeys, LocalstorageService } from '@trakr-safety/core/services/localstorage.service';

@Injectable({ providedIn: 'root' })
export class AuthFacade {
  public hasErrorSubject$: BehaviorSubject<boolean>;
  public hasError$: Observable<boolean>;

  get authUser(): UserModel | null {
    const userInfo = this.pLocalStorageService.check(DataKeys.CURRENTUSER) ? JSON.parse(this.pLocalStorageService.get(DataKeys.CURRENTUSER)) : null;

    if (userInfo === null) {
      return null;
    } else {
      const isAdmin = userInfo.roles.filter((role) => 'ROLE_ADMIN'.includes(role.role_key)).length > 0;
      const isManager = userInfo.roles.filter((role) => 'ROLE_MANAGER'.includes(role.role_key)).length > 0;
      const isRegularUser = userInfo.roles.filter((role) => 'ROLE_USER|ROLE_EMPLOYEE'.includes(role.role_key)).length > 0;
      const hasConsoleAccess = userInfo.roles.filter((role) => 'ROLE_ADMIN|ROLE_MANAGER|ROLE_REPORTS'.includes(role.role_key)).length > 0;
      return ({ ...userInfo, isRegularUser, isAdmin, isManager, hasConsoleAccess }) as UserModel;
    }
  }

  constructor(
    private pLocalStorageService: LocalstorageService,
    private pStore: Store<fromApp.AppState>) {

    if (window.Cypress) {
      window.AuthFacade = this;
    }

    this.hasErrorSubject$ = new BehaviorSubject<boolean>(false);
    this.hasError$ = this.hasErrorSubject$.asObservable();
  }

  checkAuth(): Observable<boolean> {
    return this.pStore.pipe(select(AuthSelectors.selectIsAuthenticated));
  }

  autoLogin() {
    this.pStore.dispatch(AuthActions.autoLoginStart());
  }

  loginStart(user: any): void {
    this.pStore.dispatch(AuthActions.loginStart({ ...user }))
  }

  logout(): void {
    this.pStore.dispatch(AuthActions.logout());
  }

  checkIfPasswordRequired(username: string, tenantKey: string): void {
    this.pStore.dispatch(AuthActions.checkPasswordRequired({ tenantKey, username }));
  }

  passwordMissing(): Observable<boolean> {
    return this.pStore.select(AuthSelectors.passwordMissing);
  }

  convertNonPasswordAccount(password: string, username: string, tenantKey: string): void {
    this.pStore.dispatch(AuthActions.convertNonPasswordAccount({ password, username, tenantKey }));
  }

  loginUsername(): Observable<string> {
    return this.pStore.select(AuthSelectors.loginUsername);
  }

  loginStep1(): Observable<({ loginUsername: string, passwordMissing: boolean })> {
    return this.pStore.select(AuthSelectors.loginStep1);
  }

  checkLoginAttempt(): Observable<any> {
    return this.pStore.select(AuthSelectors.checkLoginAttempt);
  }

  changePassword(password: string, token: string): void {
    this.pStore.dispatch(AuthActions.changePassword({ password, token }));
  }

  validateResetToken(token: string) {
    this.pStore.dispatch(AuthActions.validateResetToken({ token }));
  }

  resetTokenIsValid(): Observable<({ isValid: boolean })> {
    return this.pStore.select(AuthSelectors.selectResetTokenValid).pipe(
      map((isValid) => ({ isValid }))
    );
  }

  passwordChangeStatus(): Observable<({ completed: boolean, response: string })> {
    return this.pStore.select(AuthSelectors.selectPasswordChangeStatus);
  }

  requestResetPasswordLink(userinfo): void {
    this.pStore.dispatch(AuthActions.clearPasswordResetRequest());
    this.pStore.dispatch(AuthActions.requestResetPasswordLink(userinfo));
  }

  getPasswordResetRequestStatus(): Observable<{ completed: boolean, requesting: boolean, response: string }> {
    return this.pStore.select(AuthSelectors.selectPasswordResetRequestStatus).pipe(
      tap(result => result)
    );
  }

  clearPasswordResetRequest(): void {
    this.pStore.dispatch(AuthActions.clearPasswordResetRequest());
  }

  requestUserIdEmail(userinfo): void {
    this.pStore.dispatch(AuthActions.clearUserIdRequest());
    this.pStore.dispatch(AuthActions.requestUserIdEmail(userinfo));
  }

  getUserIdRequestStatus(): Observable<{ completed: boolean, requesting: boolean, response: string }> {
    return this.pStore.select(AuthSelectors.selectUserIdRequestStatus).pipe(
      tap(result => result)
    );
  }

  clearUserIdRequest(): void {
    this.pStore.dispatch(AuthActions.clearUserIdRequest());
  }


  registerUser(registration: RegistrationModel): void {
    this.pStore.dispatch(AuthActions.registerUser({ registration }));
  }

  registerUserStatus(): Observable<{ completed: boolean, started: boolean, response: string }> {
    return this.pStore.select(AuthSelectors.registerUserStatus).pipe(
      tap(result => result)
    );
  }

  clearRegisterUserRequest(): void {
    this.pStore.dispatch(AuthActions.clearRegisterUserRequest());
  }

  validateRegisterUserToken(token: string): void {
    this.pStore.dispatch(AuthActions.validateRegisterUserToken({ token }));
  }

  registerUserTokenValid(): Observable<boolean> {
    return this.pStore.select(AuthSelectors.registerUserTokenValid);
  }

  userVerifiedStatus(): Observable<({ verified: boolean, response: string })> {
    return this.pStore.select(AuthSelectors.verifyUserEmailStatus);
  }

  getError(): Observable<string> {
    return this.pStore.select(AuthSelectors.selectError).pipe(tap(error => error));
  }

  clearError(): void {
    this.pStore.dispatch(AuthActions.clearError());
  }
}
