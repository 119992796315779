import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '../../../environments/environment';
@Pipe({
  name: 'remoteImage'
})
export class RemoteImagePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (value === undefined || value === null) { return `https://via.placeholder.com/${args.placeHolderSize}`; }
    const franchiseTypeID = args.franchiseTypeId;

    return `${environment.remoteURLPath}${franchiseTypeID}/${value}`;
  }
}
