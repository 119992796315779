import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { BehaviorSubject } from 'rxjs';
import { DataKeys, LocalstorageService } from './localstorage.service';

@Injectable({ providedIn: 'root' })
export class ThemeService {

  private readonly _themeDarkSource = new BehaviorSubject<boolean>(null);
  readonly themeDark$ = this._themeDarkSource.asObservable();

  constructor(@Inject(DOCUMENT) private mDocument: Document, private mLocalStorageService: LocalstorageService) { }

  init(): void {
    const theme = this.mLocalStorageService.get(DataKeys.THEME);
    theme ? this.switchTheme(theme) : this.switchTheme('theme-light');
  }

  switchTheme(theme: string) {
    const htmlTag = this.mDocument.getElementById('app_theme');
    htmlTag.removeAttribute('class');
    htmlTag.className = theme;

    this.mLocalStorageService.set(DataKeys.THEME, theme);

    if (theme === '' || theme === 'theme-light') {
      this._themeDarkSource.next(false);
    }

    if (theme === 'theme-dark') {
      this._themeDarkSource.next(true);
    }
  }
}
