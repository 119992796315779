
import { ContactTracingSettingsModel } from '@trakr-safety/administration/models/ContactTracingSettings.model';
import { SystemSettingsModel } from '@trakr-safety/administration/models/systemSettings.model';
import { EmployeeModel } from '@trakr-safety/employees/models/employee.model';
import { FailedMessageModel } from '../models/FailedMessageModel';
import { createAction, props } from '@ngrx/store';
import { DefaultLanguageModel, SaveDefaultLanguageModel } from '@trakr-safety/core/app-settings/models/default-language.model';

export const error = createAction('[Admin] Error',
  props<{ error: string }>()
);

export const fetchAllSettings = createAction('[Admin] Fetch All Settings',
  props<{ languageCode: string }>()
);

export const setAllSettings = createAction('[Admin] Set All Settings',
  props<{
    managers: EmployeeModel[],
    screeningContacts: EmployeeModel[],
    systemSettings: SystemSettingsModel,
    failedAssessmentMessage: FailedMessageModel,
    contactTracingSettings: ContactTracingSettingsModel,
    defaultScreeningLanguage: DefaultLanguageModel
  }>()
);

/** health screening */
export const fetchFailedAssessmentMessage = createAction('[Admin Health Screening]] Fetch Failed Message',
  props<{ languageCode: string }>()
);

export const saveFailedAssessmentMessage = createAction('[Admin Health Screening]] Save Failed Message',
  props<{ failedAssessmentMessage: FailedMessageModel }>()
);
export const setFailedAssessmentMessage = createAction('[Admin Health Screening]] Set Failed Message',
  props<{ failedAssessmentMessage: FailedMessageModel }>()
);

export const fetchDefaultScreeningLanguage = createAction('[Admin Health Screening]] Fetch Default Language');
export const saveDefaultScreeningLanguage = createAction('[Admin Health Screening] Save Default Language',
  props<{ defaultLanguage: SaveDefaultLanguageModel }>()
);
export const setDefaultScreeningLanguage = createAction('[Admin Health Screening] Set Default Language',
  props<{ defaultScreeningLanguage: DefaultLanguageModel }>()
);

export const fetchScreeningContacts = createAction('[Admin Health Screening]] Fetch Contacts');
export const updateScreeningContacts = createAction('[Admin Health Screening]] Update Contacts',
  props<{ ids: number[] }>()
);
export const setScreeningContacts = createAction('[Admin Health Screening]] Set Contacts',
  props<{ screeningContacts: EmployeeModel[], }>()
);

/** branding settings */
export const uploadLogo = createAction('[Admin Branding] Upload Logo', props<{ image: any }>());

export const previewLogo = createAction('[Admin Branding] Preview Logo', props<{ image: any }>());

/** report logo */
export const uploadReportLogo = createAction('[Admin Branding] Upload Report Logo', props<{ reportLogo: any }>());

export const isUploading = createAction('[Admin Branding] Is Uploading', props<{ isUploading: boolean }>());

export const uploadComplete = createAction('[Admin Branding] Upload Completed');

/** get managers */
export const fetchManagers = createAction('[Admin] Fetch Managers');
export const setManagers = createAction('[Admin] Set Managers',
  props<{
    managers: EmployeeModel[]
  }>()
);

/** contact tracing settings */
export const fetchContactTracingSettings = createAction('[Admin Contact Tracing] Fetch Settings');
export const saveContactTracingSettings = createAction('[Admin Contact Tracing] Save Settings',
  props<{
    settings: ContactTracingSettingsModel;
  }>()
);
export const setContactTracingSettings = createAction('[Admin Contact Tracing] Set Settings',
  props<{
    contactTracingSettings: ContactTracingSettingsModel;
  }>()
);

/** system settings*/
export const fetchSystemSettings = createAction('[Admin System] Fetch Settings');

export const setSystemSettings = createAction('[Admin System] Set Settings',
  props<{ systemSettings: SystemSettingsModel }>()
);

export const saveSystemSettings = createAction('[Admin System] Save Settings',
  props<{ settings: SystemSettingsModel }>()
);

