import { Action, createReducer, on } from '@ngrx/store';
import { QuestionType } from '@trakr-safety/question-types/models/question-type.model';
import * as QuestionTypesActions from '@trakr-safety/question-types/store/question-types.actions';

export const questionTypesFeatureName = 'questionTypes';

export interface QuestionTypesState {
  questionTypes: QuestionType[];
  loading: boolean;
  loaded: boolean;
  error: string;
}

export const initialState: QuestionTypesState = {
  questionTypes: [],
  loading: false,
  loaded: false,
  error: null
};

const questionTypesReducer = createReducer(
  initialState,
  on(QuestionTypesActions.unloaded, (state) => ({
    ...state,
    loading: false,
    loaded: false
  })),
  on(QuestionTypesActions.fetchQuestionTypes, (state) => ({
    ...state,
    loading: true,
    loaded: false
  })),
  on(QuestionTypesActions.setQuestionTypes, (state, { questionTypes }) => ({
    ...state,
    questionTypes,
    loading: false,
    loaded: true
  })),
  on(QuestionTypesActions.error, (state, { error }) => ({
    ...state,
    error,
    loading: false,
    loaded: true
  }))
);

export function reducer(state: QuestionTypesState | undefined, action: Action) {
  return questionTypesReducer(state, action);
}
