import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SubSink } from 'subsink';

import { TenantModel } from 'app/tenants/models/tenant.model';

import { AreasFacade } from '@trakr-safety/areas/facades/areas.facade';
import { AppSettingsFacade } from '@trakr-safety/core/app-settings/facades/app-settings.facade';
import { DataKeys, LocalstorageService } from '@trakr-safety/core/services/localstorage.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styles: [],
})

export class MainComponent implements OnInit, OnDestroy {

  private subs = new SubSink();
  private tenantData: TenantModel;

  constructor(
    private pAreasFacade: AreasFacade,
    private _appSettingsFacade: AppSettingsFacade,
    private pLocalStorageService: LocalstorageService,
    private pRoute: ActivatedRoute
  ) {
    if (this.pLocalStorageService.check(DataKeys.TENANT)) {
      this.tenantData = JSON.parse(this.pLocalStorageService.get(DataKeys.TENANT));
    }
  }

  ngOnInit(): void {

    // get current kioskmode cookie if available
    const kioskModeStorageVal = this._appSettingsFacade.kioskMode;
    const traceAreaStorageVal = this.getTraceAreaStorageVal();

    // check querystring params for kiosk mode
    this.subs.add(this.pRoute.queryParamMap.subscribe((params) => {

      // kioskmode
      const kioskMode = params.get('kioskmode');

      if (!kioskMode && kioskModeStorageVal) {
        this._appSettingsFacade.setKioskStateSetting(kioskModeStorageVal === 'true');
      }
      else if (kioskMode) {
        this._appSettingsFacade.setKioskModeStorageSetting(kioskMode);
        this._appSettingsFacade.setKioskStateSetting(kioskMode === 'true');
      }

      // area tracing
      const areaId = params.get('area');

      if (!areaId && traceAreaStorageVal) {
        this.pAreasFacade.replaceTraceArea(traceAreaStorageVal);
      }
      else if (areaId) {
        if (areaId === '0') {
          this.pAreasFacade.replaceTraceArea(null);
          this.pLocalStorageService.delete(DataKeys.TRACEAREA);
        } else {
          this.pAreasFacade.fetchTraceArea(Number(areaId), this.tenantData.locationId, this.tenantData.tenantKey)
        }
      }

    }));
  }

  private getTraceAreaStorageVal() {

    if (!this.pLocalStorageService.check(DataKeys.TRACEAREA)) {
      return null;
    }

    return JSON.parse(this.pLocalStorageService.get(DataKeys.TRACEAREA));
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
