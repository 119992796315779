import { Injectable } from '@angular/core';
import { CanActivate, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { filter, map, withLatestFrom } from 'rxjs/operators';
import * as fromApp from 'app/store/app.reducer';
import * as AppSettingsSelectors from '@trakr-safety/core/app-settings/store/app-settings.selectors';
import * as AppSettingsActions from '@trakr-safety/core/app-settings/store/app-settings.actions';
import { LanguageFacade } from '../../language-selector/facades/language.facade';
import { DataKeys, LocalstorageService } from '../../services/localstorage.service';

@Injectable({
  providedIn: 'root'
})
export class LoadAppSettingsGuard implements CanActivate {
  constructor(
    private mStore: Store<fromApp.AppState>,
    private mLocalStorageService: LocalstorageService,
    private mLanguageFacade: LanguageFacade) { }

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const languageCode = this.mLanguageFacade.getLangCode();
    return this.mStore.select(AppSettingsSelectors.selectLoading).pipe(
      withLatestFrom(this.mStore.select(AppSettingsSelectors.selectLoaded)),
      filter(([loading, loaded], index) => {
        if (!loaded && !loading && index === 0) {
          const locationId = JSON.parse(this.mLocalStorageService.get(DataKeys.TENANT)).locationId;
          this.mStore.dispatch(AppSettingsActions.fetchAppSettings({ languageCode, locationId }));
          return false;
        }

        return !loading;
      }),
      map(([, loaded]) => loaded as boolean)
    );
  }
}
