import { NgModule } from '@angular/core';
import { SafePipe } from './safe.pipe';

const declarations = [SafePipe];

@NgModule({
  declarations: [...declarations],
  exports: [...declarations]
})
export class SafePipeModule { }
