import { Actions, ofType, createEffect } from '@ngrx/effects';
import * as LocationsActions from './locations.actions';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Injectable } from '@angular/core';
import { LocationsHttpClient } from '../httpclients/locations.http-client';

@Injectable()
export class LocationsEffects {
  constructor(
    private _actions$: Actions,
    private _locationsHttpClient: LocationsHttpClient) { }

  fetchLocation$ = createEffect(() => this._actions$.pipe(
    ofType(LocationsActions.fetchLocation),
    switchMap(({ id }) => this._locationsHttpClient.getLocation(id).pipe(
      map((response: any) => response.data),
      map(location => LocationsActions.setLocation({ location })),
      catchError(error => {
        return of(LocationsActions.error({ error: error.error.message }));
      })
    ))
  ));
}

