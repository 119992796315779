import { TenantModel } from 'app/tenants/models/tenant.model';
import { createAction, props } from '@ngrx/store';
import { FeatureStore } from 'app/store/feature-store.enum';

const featureName = FeatureStore.TENANTS
export const setTenant = createAction(`[${featureName}] Set Tenant`,
  props<{ tenantData: TenantModel }>()
);

export const fetchTenant = createAction(`[${featureName}] Fetch Tenant`,
  props<{ tenantKey: string }>()
);

export const failedTenantLookup = createAction(`[${featureName}] Failed Tenant Lookup`);

export const error = createAction(`[${featureName}] Error`,
  props<{ errorMessage: string }>()
);

