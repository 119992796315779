import { Actions, ofType, createEffect } from '@ngrx/effects';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Injectable } from '@angular/core';
import { SnackBarServiceService } from '@trakr-safety/core/services/snack-bar-service.service';
import * as HealthQuestionsActions from '@trakr-safety/health-screening/store/questions/health-questions.actions';
import { HealthQuestionsHttpClient } from '@trakr-safety/health-screening/httpclients/health-questions.http-client';

@Injectable()
export class HealthQuestionsEffects {
  constructor(
    private snackBarService: SnackBarServiceService,
    private actions$: Actions,
    private healthQuestionsHttpClient: HealthQuestionsHttpClient) { }

  /** move question */
  postMoveQuestion$ = createEffect(() => this.actions$.pipe(
    ofType(HealthQuestionsActions.moveQuestion),
    switchMap(({ sortData, langCode }) =>
      this.healthQuestionsHttpClient.updateAssessmentQuestionOrderIndex(sortData, langCode)
        .pipe(
          map((resData: any) => resData.data),
          map((questions) => {
            return HealthQuestionsActions.setQuestions({ questions });
          }),
          catchError(error => {
            return of(HealthQuestionsActions.error({ error: error.error.message }));
          })
        ))
  ));

  /** add question */
  addQuestion$ = createEffect(() => this.actions$.pipe(
    ofType(HealthQuestionsActions.addQuestion),
    switchMap(({ questionData }) =>
      this.healthQuestionsHttpClient.createAssessmentQuestion(questionData)
        .pipe(
          map((resData: any) => resData.data),
          map((questions) => {
            this.snackBarService.popSnack('alerts.created.success.text', true);
            return HealthQuestionsActions.setQuestions({ questions });
          }),
          catchError(error => {
            return of(HealthQuestionsActions.error({ error: error.error.message }));
          })
        ))
  ));

  /** update question */
  updateQuestion$ = createEffect(() => this.actions$.pipe(
    ofType(HealthQuestionsActions.updateQuestion),
    switchMap(({ questionData }) =>
      this.healthQuestionsHttpClient.updateAssessmentQuestion(questionData)
        .pipe(
          map(_ => {
            this.snackBarService.popSnack('alerts.updated.success.text', true);
            return HealthQuestionsActions.fetchAllQuestions({ langCode: questionData.languageCode });
          }),
          catchError(error => {
            return of(HealthQuestionsActions.error({ error: error.error.message }));
          })
        ))
  ));

  /** delete question */
  deleteQuestion$ = createEffect(() => this.actions$.pipe(
    ofType(HealthQuestionsActions.deleteQuestion),
    switchMap(({ questionId, langCode }) =>
      this.healthQuestionsHttpClient.softDeleteAssessmentQuestion(questionId)
        .pipe(
          map(_ => {
            this.snackBarService.popSnack('alerts.deleted.success.text', true);
            return HealthQuestionsActions.fetchAllQuestions({ langCode });
          }),
          catchError(error => {
            return of(HealthQuestionsActions.error({ error: error.error.message }));
          })
        ))
  ));

  /** fetch questions */
  fetchAllQuestions$ = createEffect(() => this.actions$.pipe(
    ofType(HealthQuestionsActions.fetchAllQuestions),
    switchMap(({ langCode }) =>
      this.healthQuestionsHttpClient.fetchAllQuestions(langCode)
        .pipe(
          map((resData: any) => resData.data),
          map(({ questions, usingDefaultLanguageSet, defaultLanguage }) => {
            return HealthQuestionsActions.setDefaultQuestions({ questions, usingDefaultLanguageSet, defaultLanguage });
          }),
          catchError(error => {
            if (error.status && error.status === 401) {
              return of(HealthQuestionsActions.nothing());
            } else {
              return of(HealthQuestionsActions.error({ error: error.error.message }));
            }
          })
        ))
  ));
}
