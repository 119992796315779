import { UserModel } from '@trakr-safety/core/auth/models/user.model';
import { AssessmentAnswerModel } from './assessment-answer.model';

export interface SelfAssessmentModel {
  id: number;
  user_id: number;
  area_id: number;
  location_id: number;
  isPass: boolean;
  completed_at: Date;
  dayCompleted: string;
  user: UserModel;
  answers?: AssessmentAnswerModel[];
  languageCode: string;
}

export let NewSelfAssessmentModel = {
  id: 0,
  user_id: 0,
  area_id: 0,
  location_id: 0,
  isPass: false,
  completed_at: null,
  dayCompleted: '',
  user: null,
  answers: [],
  languageCode: 'en-ca'
};
