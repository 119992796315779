import {
  trigger,
  transition,
  style,
  query,
  group,
  animateChild,
  animate,
  keyframes,
  animation,
} from '@angular/animations';


export const loginLayoutFader = trigger('routeAnimations', [
  transition('* <=> *', [
    query(':enter, :leave', [
      style({
        position: 'absolute',
        left: 0,
        width: '100%',
        opacity: 0,
        // transform: 'scale(0) translateY(100%)',
      })
    ], { optional: true }),
    query(':enter', [
      animate('1000ms ease',
        style({
          opacity: 1,
          //  transform: 'scale(1) translateY(0)'
        })
      )
    ], { optional: true })
  ])
]);

// basic global fader animation
export const fader = trigger('routeAnimations', [
  transition('* <=> *', [
    query(':enter, :leave', [
      style({
        position: 'absolute',
        left: 0,
        width: '100%',
        opacity: 0,
        // transform: 'scale(0) translateY(100%)',
      })
    ], { optional: true }),
    query(':enter', [
      animate('300ms ease',
        style({
          opacity: 1,
          //  transform: 'scale(1) translateY(0)'
        })
      )
    ], { optional: true })
  ])
]);

export const fadeAnimation =
  trigger('routeAnimations', [
    transition('* <=> *', [
      style({ position: 'relative' }),
      query(':enter, :leave',
        style({ position: 'absolute', width: '100%', left: 0, opacity: 0 }),
        { optional: true }),
      group([
        query(':enter', [
          style({ opacity: 0 }),
          animate('0.3s ease-in-out',
            style({ opacity: 1 }))
        ], { optional: true }),
        query(':leave', [
          animate('0.3s ease-in-out',
            style({ opacity: 0 }))
        ], { optional: true }),
      ])
    ])
  ]);

export const slider =
  trigger('routeAnimations', [
    transition('* <=> *', [
      style({ position: 'relative' }),
      query(':enter, :leave',
        style({ position: 'absolute', width: '100%', left: 0 }),
        { optional: true }),
      group([
        query(':enter', [
          style({ left: '-100%', opacity: 0 }),
          animate('0.5s ease-in-out',
            style({ left: '0%', opacity: 1 }))
        ], { optional: true }),
        query(':leave', [
          style({ left: '0%' }),
          animate('0.5s ease-in-out',
            style({ left: '100%', opacity: 0 }))
        ], { optional: true }),
      ])
    ])
  ]);


export const transAnimation = animation([
  style({
    height: '{{ height }}',
    opacity: '{{ opacity }}',
    backgroundColor: '{{ backgroundColor }}'
  }),
  animate('{{ time }}')
]);

// Routable animations
export const slideInAnimation = trigger('routeSideNavAnimations', [
  transition('* => home', [
    style({ position: 'relative' }),
    query(':enter, :leave', [
      style({
        position: 'absolute',
        top: 0,
        right: 0,
        width: '100%'
      })
    ]),
    query(':enter', [style({ right: '-100%', opacity: 0 })]),
    query(':leave', animateChild()),
    group([
      query(':leave', [animate('0.25s ease-out', style({ right: '100%', opacity: 0 }))]),
      query(':enter', [animate('0.25s ease-out', style({ right: '0%', opacity: 1 }))])
    ]),
    query(':enter', animateChild())
  ]),
  transition('home => *, Two => One, Three => One', [
    style({ position: 'relative' }),
    query(':enter, :leave', [
      style({
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%'
      })
    ]),
    query(':enter', [style({ left: '-100%', opacity: 0 })]),
    query(':leave', animateChild()),
    group([
      query(':leave', [animate('0.25s ease-out', style({ left: '100%', opacity: 0 }))]),
      query(':enter', [animate('0.25s ease-out', style({ left: '0%', opacity: 1 }))])
    ]),
    query(':enter', animateChild())
  ])
]);

export const navigationAnimation = trigger('routeNavigationAnimation', [
  transition(':enter, * => -1', []),
  transition(':increment', [
    style({ position: 'relative' }),
    query(':enter, :leave', [
      style({
        position: 'absolute',
        top: 0,
        right: 0,
        width: '100%'
      })
    ]),
    query(':enter', [style({ right: '-100%', opacity: 0 })]),
    query(':leave', animateChild()),
    group([
      query(':leave', [animate('0.125s ease-out', style({ right: '100%', opacity: 0 }))]),
      query(':enter', [animate('0.125s ease-out', style({ right: '0%', opacity: 1 }))])
    ]),
    query(':enter', animateChild())
  ]),
  transition(':decrement', [
    style({ position: 'relative' }),
    query(':enter, :leave', [
      style({
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%'
      })
    ]),
    query(':enter', [style({ left: '-100%', opacity: 0 })]),
    query(':leave', animateChild()),
    group([
      query(':leave', [animate('0.125s ease-out', style({ left: '100%', opacity: 0 }))]),
      query(':enter', [animate('0.125s ease-out', style({ left: '0%', opacity: 1 }))])
    ]),
    query(':enter', animateChild())
  ])
]);



export const slideInAnimation2 = trigger('routeSideNavAnimations2', [
  transition(':enter, * => -1', []),
  transition(':increment', [
    style({ position: 'relative' }),
    query(':enter, :leave', [
      style({
        position: 'absolute',
        top: 0,
        right: 0,
        width: '100%'
      })
    ]),
    query(':enter', [style({ right: '-100%', opacity: 0 })]),
    query(':leave', animateChild()),
    group([
      query(':leave', [animate('0.25s ease-out', style({ right: '100%', opacity: 0 }))]),
      query(':enter', [animate('0.25s ease-out', style({ right: '0%', opacity: 1 }))])
    ]),
    query(':enter', animateChild())
  ]),
  transition(':decrement', [
    style({ position: 'relative' }),
    query(':enter, :leave', [
      style({
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%'
      })
    ]),
    query(':enter', [style({ left: '-100%', opacity: 0 })]),
    query(':leave', animateChild()),
    group([
      query(':leave', [animate('0.25s ease-out', style({ left: '100%', opacity: 0 }))]),
      query(':enter', [animate('0.25s ease-out', style({ left: '0%', opacity: 1 }))])
    ]),
    query(':enter', animateChild())
  ])
]);
