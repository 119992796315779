import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler } from '@angular/common/http';
import { DataKeys, LocalstorageService } from '../services/localstorage.service';

@Injectable()
export class CustomHeadersInterceptor implements HttpInterceptor {
  constructor(private mLocalStorageService: LocalstorageService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler) {

    return next.handle(
      req.clone({
        setHeaders: {
          'Cache-Control': 'no-cache',
          Pragma: 'no-cache',
          'csrf-token': this.mGetCSRFToken() || '',
          'source': 'web client'
        }
      })
    );
  }

  private mGetCSRFToken(): string {
    return this.mLocalStorageService.check(DataKeys.CURRENTUSER) ?
      this.mLocalStorageService.getNestKeyValue(DataKeys.CURRENTUSER, 'csrf_token') : null;
  }
}

