import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromApp from 'app/store/app.reducer';
import * as AuthActions from '@trakr-safety/core/auth/store/auth.actions';
import { LoginFacade } from './facades/login.facade';
@Component({
  selector: 'app-logout',
  template: ``,
  styles: [],
  providers: [LoginFacade]
})
export class LogoutComponent implements OnInit {

  constructor(
    private store: Store<fromApp.AppState>) {
  }

  ngOnInit(): void {
    this.store.dispatch(AuthActions.logout());
  }
}
