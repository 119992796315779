import { ContactTracingSettingsModel } from '@trakr-safety/administration/models/ContactTracingSettings.model';
import { DefaultLanguageModel, SaveDefaultLanguageModel } from '@trakr-safety/core/app-settings/models/default-language.model';
import { SystemSettingsModel } from '@trakr-safety/administration/models/systemSettings.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EmployeeModel } from '@trakr-safety/employees/models/employee.model';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { first, map, retry, shareReplay, timeout } from 'rxjs/operators';
import { FailedMessageModel } from '../models/FailedMessageModel';

@Injectable({ providedIn: 'root' })

export class AdministrationHttpClient {

  constructor(private _http: HttpClient) { }

  fetchManagers(): Observable<EmployeeModel[]> {
    return this._http.get(`${environment.baseWebApiURL}administration/managers`).pipe(
      map((response: any) => response.data as EmployeeModel[])
    ).pipe(
      first(),
      timeout(10000),
      retry(4),
      shareReplay());
  }

  // screening settings
  fetchScreeningContacts(): Observable<EmployeeModel[]> {
    return this._http.get<EmployeeModel[]>(`${environment.baseWebApiURL}administration/screening_contacts`).pipe(
      map((response: any) => response.data),
      map(records => records.map((record: any) => record.user)),
    ).pipe(
      first(),
      timeout(10000),
      retry(4),
      shareReplay());
  }

  saveScreeningContacts(contactIds: number[]): Observable<any> {
    return this._http.post<number[]>(`${environment.baseWebApiURL}administration/screening_contacts`, contactIds);
  }

  fetchFailedAssessmentMessage(languageCode: string): Observable<FailedMessageModel> {
    return this._http.get(`${environment.baseWebApiURL}administration/failed_assessment_message/${languageCode}`).pipe(
      map((response: any) => response.data)
    ).pipe(
      first(),
      timeout(10000),
      retry(4),
      shareReplay());
  }

  saveFailedAssessmentMessage(message: FailedMessageModel): Observable<any> {
    return this._http.post<FailedMessageModel>(`${environment.baseWebApiURL}administration/failed_assessment_message`, message);
  }

  fetchDefaultScreeningLanguage(): Observable<DefaultLanguageModel> {
    return this._http.get(`${environment.baseWebApiURLV2}default-language`).pipe(
      map((response: any) => response.data)
    ).pipe(
      first(),
      timeout(10000),
      retry(4),
      shareReplay());
  }

  saveDefaultScreeningLanguage(defaultLanguage: SaveDefaultLanguageModel): Observable<DefaultLanguageModel> {
    return this._http.post(`${environment.baseWebApiURLV2}default-language`, defaultLanguage).pipe(
      map((response: any) => response.data)
    );
  }

  /** contact tracing Settings */
  saveContactTracingSettings(settings: ContactTracingSettingsModel): Observable<any> {
    return this._http.post<ContactTracingSettingsModel>(`${environment.baseWebApiURL}administration/sanipass_settings`, settings);
  }

  fetchContactTracingSettings(): Observable<ContactTracingSettingsModel> {
    return this._http.get(`${environment.baseWebApiURL}administration/sanipass_settings`).pipe(
      map((response: any) => response.data)
    ).pipe(
      first(),
      timeout(10000),
      retry(4),
      shareReplay());
  }

  /** System Settings */
  saveSystemSettings(settings: SystemSettingsModel): Observable<any> {
    return this._http.post<SystemSettingsModel>(`${environment.baseWebApiURLV2}company-settings`, settings);
  }

  fetchSystemSettings(): Observable<SystemSettingsModel> {
    return this._http.get(`${environment.baseWebApiURLV2}company-settings`).pipe(
      map((response: any) => response.data as SystemSettingsModel)
    ).pipe(
      first(),
      timeout(10000),
      retry(4),
      shareReplay());
  }

  /** branding */
  saveLogo(data: any): Observable<any> {
    return this._http.post<any>(`${environment.baseWebApiURL}administration/upload_logo`, data);
  }

  saveReportLogo(data: any): Observable<any> {
    return this._http.post<any>(`${environment.baseWebApiURL}administration/upload_report_logo`, data);
  }
}
