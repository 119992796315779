import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { QuicklinkModule } from 'ngx-quicklink';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthFacade } from './core/auth/facades/auth.facade';
import { CredentialsInterceptor } from './core/httpinterceptors/credentials.interceptor';
import { CustomHeadersInterceptor } from './core/httpinterceptors/customheaders.interceptor';
import { ErrorInterceptor } from './core/httpinterceptors/error.Interceptor';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppRoutingModule } from './app-routing.module';
import { PipesModule } from './core/pipes/pipes.module';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromApp from './store/app.reducer';
import { appEffects } from './store/app.effects';
import { environment } from '../environments/environment';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { MainComponent } from './main/main.component';
import { CookieService } from 'ngx-cookie-service';
import { NgcCookieConsentModule, NgcCookieConsentConfig } from 'ngx-cookieconsent';
import { AnimationWrapperComponent } from './layouts/login-layout/animation-wrapper/animation-wrapper.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { ToastrModule } from 'ngx-toastr';
import { AppInstallDialogComponent } from './core/components/dialogs/app-install-dialog/app-install-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { FlexLayoutModule } from '@angular/flex-layout';

import { NgxZendeskWebwidgetModule, NgxZendeskWebwidgetConfig } from 'ngx-zendesk-webwidget';
import { ApplicationErrorDialogComponent } from './core/components/dialogs/application-error-dialog/application-error-dialog.component';
import { UpdateAppComponent } from './core/components/dialogs/update-app/update-app.component';
import { PortalModule } from '@angular/cdk/portal';
import { GlobalErrorHandler } from './core/httpinterceptors/global-error-handler';
import { initializeApp } from "firebase/app";

initializeApp(environment.firebase);

export class ZendeskConfig extends NgxZendeskWebwidgetConfig {
  accountUrl = 'trakrsuite.zendesk.com';
  lazyLoad = true;
  timeOut = 10000;
  callback(zE) {
    // You can call every command you want in here
    zE('webWidget', 'hide');
  }
  beforePageLoad(zE) {
    zE.setLocale('en');
    zE.hide();
  }
}



const defaultCookieDomain = environment.defaultCookieDomain;
const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: defaultCookieDomain // or 'your.domain.com' // it is mandatory to set a domain, for cookies to work properly (see https://goo.gl/S2Hy2A)
  },
  palette: {
    popup: {
      background: '#0097dc'
    }

  },
  theme: 'edgeless',
  type: 'info'
};

const appModules = [
  StoreModule.forRoot(fromApp.appReducer, { metaReducers: fromApp.metaReducers }),
  StoreDevtoolsModule.instrument({
    maxAge: 25, // Retains last 25 states
    logOnly: environment.production, // Restrict extension to log-only mode
  }),
  EffectsModule.forRoot(appEffects),
  AppRoutingModule,
  BrowserModule,
  BrowserAnimationsModule,
  HttpClientModule,
  QuicklinkModule,
  MatCardModule,
  MatDialogModule,
  MatButtonModule,
  MatIconModule,
  FlexLayoutModule,
  PipesModule,
  MatSnackBarModule,
  NgcCookieConsentModule.forRoot(cookieConfig),
  ToastrModule.forRoot({ positionClass: 'toast-bottom-full-width' }),
  TranslateModule.forRoot({
    loader: {
      provide: TranslateLoader,
      useFactory: httpTranslateLoader,
      deps: [HttpClient]
    }
  }),
  NgxZendeskWebwidgetModule.forRoot(ZendeskConfig),
  PortalModule
];
const appDeclaration = [
  AppComponent,
  MainComponent
];

const appProviders = [
  AuthFacade,
  CookieService,
  {
    provide: ErrorHandler,
    useClass: GlobalErrorHandler
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: CustomHeadersInterceptor,
    multi: true
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: CredentialsInterceptor,
    multi: true
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: ErrorInterceptor,
    multi: true
  }
];

@NgModule({
  imports: [...appModules, ServiceWorkerModule.register('service-worker.js', { enabled: true }), BrowserAnimationsModule],
  declarations: [...appDeclaration, MainComponent, AnimationWrapperComponent, AppInstallDialogComponent, ApplicationErrorDialogComponent, UpdateAppComponent],
  providers: [...appProviders],
  bootstrap: [AppComponent]
})
export class AppModule { }
// AOT compilation support
export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

