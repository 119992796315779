import { Action, createReducer, on } from '@ngrx/store';
import { FeatureStore } from 'app/store/feature-store.enum';
import * as TenantActions from './tenant.actions';

export const tenantsFeatureName = FeatureStore.TENANTS

export interface TenantState {
  locationId: number;
  orgId: number;
  tenantKey: string;
  errorMessage: string;
}

export const initialState: TenantState = {
  locationId: undefined,
  orgId: undefined,
  tenantKey: undefined,
  errorMessage: undefined
};

const tenantReducer = createReducer(initialState,
  on(TenantActions.setTenant, (state, { tenantData }) => ({
    ...state,
    ...tenantData
  }))
);

export function reducer(state: TenantState | undefined, action: Action) {
  return tenantReducer(state, action);
}
