import { ApplicationRef, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SwUpdate, SwPush } from '@angular/service-worker';
import { UpdateAppComponent } from '@trakr-safety/core/components/dialogs/update-app/update-app.component';
import { interval } from 'rxjs';
import { switchMap, first, map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ServiceWorkerService {

  private readonly publicKey = 'BBCuKmWzk6jGuXaoplxrm8Af5aQMYGgz0CH9_u6fZA2SkPbO0nnTiM5eqm1lEqWi535KTq0NQ55iXdhMjaZNxmc';

  constructor(
    private _swUpdate: SwUpdate,
    private _swPush: SwPush,
    public dialog: MatDialog,
    appRef: ApplicationRef
  ) {
    this._swUpdate.available.subscribe(() => {

      this.dialog.open(UpdateAppComponent, {
        disableClose: true,
        width: '350px',
      }).afterClosed().pipe(
        take(1),
        map(result => {
          if (result) {
            window.location.reload();
          }
        })
      ).subscribe();
    });

    // Poll logic after isStable, otherwise isStable never fires
    appRef.isStable.pipe(
      first(stable => stable),
      switchMap(() => interval(6 * 60 * 60 * 1000)) // Every 6h
    ).subscribe(() => {
      this._swUpdate.checkForUpdate();
    });
  }

  pushSubscription() {

    /* if (!this._swPush.isEnabled) {
       console.log('Notifications are not enabled');
       return;
     }

     this._swPush.requestSubscription({
       serverPublicKey: this.publicKey,
     }).then(sub => console.log('can setup for notifications')).catch(ex => console.log(ex));
     */
  }
}
