
import { ContactTracingSettingsModel } from '@trakr-safety/administration/models/ContactTracingSettings.model';
import { CheckinModel, NewCheckinModel } from '@trakr-safety/checkins/models/checkin.model';
import * as CheckinActions from './checkins.actions';

export interface State {
  checkins: CheckinModel[];
  checkin: CheckinModel;
  error: string;
  contactTracingSettings: ContactTracingSettingsModel;
  weekReviewDash: CheckinModel[];
  filterString: string;
}

const initialState: State = {
  checkins: [],
  checkin: undefined,
  error: null,
  contactTracingSettings: undefined,
  weekReviewDash: [],
  filterString: ''
};

export function checkinsReducer(state = initialState, action: CheckinActions.CheckinActions) {
  switch (action.type) {
    case CheckinActions.REPLACE_STATE:
      return {
        ...state,
        ...action.payload
      };
    case CheckinActions.CLEAR_STATE:
      return {
        ...initialState
      };
    case CheckinActions.ERROR:
      return {
        ...state,
        error: action.payload
      };
    case CheckinActions.NEW_CHECKIN:
      return {
        ...state,
        checkin: { ...NewCheckinModel }
      };
    case CheckinActions.SET_FILTER_STRING:
      return {
        ...state,
        filterString: action.payload
      };
    default:
      return state;
  }
}
