import { Actions, Effect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as fromApp from 'app/store/app.reducer';
import { TenantKeyPrependPipe } from '@trakr-safety/core/pipes/tenant-key-prepend.pipe';
import { SnackBarServiceService } from '@trakr-safety/core/services/snack-bar-service.service';
import { HealthAssessmentsHttpClient } from '@trakr-safety/health-screening/httpclients/health-assessments.http-client';
import * as HealthAssessmentActions from '@trakr-safety/health-screening/store/assessments/heallth-assessments.actions';
import { DataKeys, LocalstorageService } from '@trakr-safety/core/services/localstorage.service';
import { AreasFacade } from '@trakr-safety/areas/facades/areas.facade';

@Injectable()
export class HealthAssessmentsEffects {

  constructor(
    private pAreasFacade: AreasFacade,
    private pLocalStorageService: LocalstorageService,
    private pHealthAssessmentsHttpClient: HealthAssessmentsHttpClient,
    private pStore: Store<fromApp.AppState>,
    private ptenantKeyPrependPipe: TenantKeyPrependPipe,
    private pRouter: Router,
    private pSnackBarService: SnackBarServiceService,
    private pActions$: Actions,
  ) {
  }


  @Effect()
  fetchProxyUser = this.pActions$.pipe(
    ofType(HealthAssessmentActions.FETCH_PROXY_USER),
    switchMap((action: HealthAssessmentActions.FetchProxyUser) => {
      return this.pHealthAssessmentsHttpClient.fetchProxyUser(action.payload)
        .pipe(
          map((response: any) => new HealthAssessmentActions.ReplaceState({ proxyUserFullName: `${response.data.firstName} ${response.data.lastName}` })),
          catchError(error => of(new HealthAssessmentActions.Error(error.message))));
    }),
  );

  /** reports */
  @Effect()
  fetchEmployeeMonthReport = this.pActions$.pipe(
    ofType(HealthAssessmentActions.FETCH_MONTH_REPORT_EMPLOYEES),
    switchMap(_ => {
      return this.pHealthAssessmentsHttpClient.getEmployeeMonthReport()
        .pipe(
          map((resData: any) => {
            return new HealthAssessmentActions.ReplaceState({ selfAssessments: resData, monthReportData: resData.data });
          }),
          catchError(error => {
            return of(new HealthAssessmentActions.Error(error.message));
          }));
    }),
  );


  @Effect()
  fetchEmployeeAllTimeReport = this.pActions$.pipe(
    ofType(HealthAssessmentActions.FETCH_ALL_TIME_REPORT_EMPLOYEES),
    switchMap(_ => {
      return this.pHealthAssessmentsHttpClient.getEmployeeAllTimeMonthReport()
        .pipe(
          map((resData: any) => {
            return new HealthAssessmentActions.ReplaceState({ allTimeReportData: resData.data });
          }),
          catchError(error => {
            return of(new HealthAssessmentActions.Error(error.message));
          }));
    }),
  );

  @Effect()
  fetchVisitorMonthReport = this.pActions$.pipe(
    ofType(HealthAssessmentActions.FETCH_MONTH_REPORT_VISITORS),
    switchMap(_ => {
      return this.pHealthAssessmentsHttpClient.getVisitorsMonthReport()
        .pipe(
          map((resData: any) => {
            return new HealthAssessmentActions.ReplaceState({ selfAssessments: resData, monthReportData: resData.data });
          }),
          catchError(error => {
            return of(new HealthAssessmentActions.Error(error.message));
          }));
    }),
  );

  @Effect()
  fetchVisitorAllTimeReport = this.pActions$.pipe(
    ofType(HealthAssessmentActions.FETCH_ALL_TIME_REPORT_VISITORS),
    switchMap(_ => {
      return this.pHealthAssessmentsHttpClient.getVisitorsAllTimeReport()
        .pipe(
          map((resData: any) => {
            return new HealthAssessmentActions.ReplaceState({ allTimeReportData: resData.data });
          }),
          catchError(error => {
            return of(new HealthAssessmentActions.Error(error.message));
          }));
    }),
  );

  @Effect()
  fetchAssessmentDetailsReport = this.pActions$.pipe(
    ofType(HealthAssessmentActions.FETCH_ASSESSMENT_DETAILS_REPORT),
    switchMap((action: HealthAssessmentActions.FetchAssessmentDetailsReport) => {
      return this.pHealthAssessmentsHttpClient.getAssessmentDetailsReport(action.payload)
        .pipe(
          map((resData: any) => {
            return new HealthAssessmentActions.ReplaceState({ detailReportData: resData });
          }),
          catchError(error => {
            return of(new HealthAssessmentActions.Error(error.message));
          }));
    }),
  );

  @Effect()
  fetchAssessmentsHistoryReport = this.pActions$.pipe(
    ofType(HealthAssessmentActions.FETCH_ASSESSMENT_HISTORY_REPORT),
    switchMap((action: HealthAssessmentActions.FetchAssessmentDetailsReport) => {
      return this.pHealthAssessmentsHttpClient.getAssessmentsHistoryReport(action.payload)
        .pipe(
          map((resData: any) => {
            return new HealthAssessmentActions.ReplaceState({ historyReportData: resData.data });
          }),
          catchError(error => {
            return of(new HealthAssessmentActions.Error(error.message));
          }));
    }),
  );

  // dash summary
  @Effect()
  fetchDashSummary = this.pActions$.pipe(
    ofType(HealthAssessmentActions.FETCH_DASH_SUMMARY),
    switchMap(_ => {
      return this.pHealthAssessmentsHttpClient.getDashSummary()
        .pipe(
          map((resData: any) => {
            return new HealthAssessmentActions.SetDashSummary(resData.data);
          }),
          catchError(error => {
            return of(new HealthAssessmentActions.Error(error.message));
          }));
    }),
  );

  // screening/screening management
  @Effect()
  fetchAssessment = this.pActions$.pipe(
    ofType(HealthAssessmentActions.FETCH_ASSESSMENT),
    switchMap((action: HealthAssessmentActions.FetchAssessment) => {
      return this.pHealthAssessmentsHttpClient.getAssessment(action.payload)
        .pipe(
          map((resData: any) => {
            if (!resData) {
              this.pStore.dispatch(new HealthAssessmentActions.IsLoaded(true));
            }

            return resData.data
          }),
          map((assessmentData) => {
            return new HealthAssessmentActions.SetAssessment(assessmentData);
          }),
          catchError(error => {
            return of(new HealthAssessmentActions.Error(error.message));
          }));
    }),
  );

  @Effect({ dispatch: false })
  tagAreaToAssessment$ = this.pActions$.pipe(
    ofType(HealthAssessmentActions.TAG_AREA_TO_ASSESSMENT),
    switchMap((action: HealthAssessmentActions.TagAreaToAssessment) => {
      return this.pHealthAssessmentsHttpClient.tagAreaToAssessment(action.payload.areaId).pipe(
        map(_ => {
          if (!action.payload.isKioskMode === true) {
            // not in kiosk mode so clear area trace
            this.pLocalStorageService.delete(DataKeys.TRACEAREA);
            this.pAreasFacade.replaceTraceArea(undefined);
          }
        }),
        catchError(error => {
          return of(new HealthAssessmentActions.Error(error.message));
        }));;
    }),
  );

  @Effect()
  deleteArea$ = this.pActions$.pipe(
    ofType(HealthAssessmentActions.DELETE_ASSESSMENT),
    switchMap((action: HealthAssessmentActions.DeleteAssessment) => this.pHealthAssessmentsHttpClient.deleteAssessment(action.assessmentId).pipe(
      map(_ => {
        this.pSnackBarService.popSnack('alerts.deleted.success.text', true);
        if (action.userType === 'employee') {
          return new HealthAssessmentActions.FetchMonthReportEmployees();
        }
        else {
          return new HealthAssessmentActions.FetchMonthReportVisitors();
        }
      }),
      catchError(error => {
        return of(new HealthAssessmentActions.Error(error.message));
      })
    ))
  );

  @Effect()
  postAnswers = this.pActions$.pipe(
    ofType(HealthAssessmentActions.POST_ANSWERS),
    switchMap((action: HealthAssessmentActions.PostAnswers) => {
      return this.pHealthAssessmentsHttpClient.saveAssessmentAnswers(action.payload)
        .pipe(
          map((resData: any) => {
            if (action.payload.isProxy) {
              return new HealthAssessmentActions.SaveAnswersProxy();
            }
            else {
              return new HealthAssessmentActions.SaveAnswers(resData.data);
            }
          }),
          catchError(error => {
            return of(new HealthAssessmentActions.Error(error.message));
          }));
    }),
  );

  @Effect({ dispatch: false })
  savedAnswers$ = this.pActions$.pipe(
    ofType(HealthAssessmentActions.SAVE_ANSWERS),
    tap(() => {
      this.pRouter.navigate([this.ptenantKeyPrependPipe.transform('/screening-passport')]);
    }));

  @Effect({ dispatch: false })
  savedProxyAnswers$ = this.pActions$.pipe(
    ofType(HealthAssessmentActions.SAVE_ANSWERS_PROXY),
    tap(() => {
      this.pRouter.navigate([this.ptenantKeyPrependPipe.transform('/reports/employee-screenings')]);
    }));

  @Effect()
  fetchNonScreenedEmployees$ = this.pActions$.pipe(
    ofType(HealthAssessmentActions.FETCH_NON_SCREENED_EMPLOYEES),
    switchMap((action: HealthAssessmentActions.FetchNonScreenedEmployees) => {
      return this.pHealthAssessmentsHttpClient.getNonScreenedEmployees(
        action.payload.filter,
        action.payload.search,
        action.payload.sortCol,
        action.payload.sortOrder,
        action.payload.page,
        action.payload.pageSize,
        action.payload.date,
        action.payload.fromTime,
        action.payload.toTime
      )
        .pipe(
          map((resData: any) => {
            return new HealthAssessmentActions.ReplaceState({ nonScreenedEmployees: resData.data, pagingData: resData.meta });
          }),
          catchError(error => {
            return of(new HealthAssessmentActions.Error(error.message));
          }));
    }),
  );
}
