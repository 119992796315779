import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'appStripHtml'
})
export class StripHtmlPipe implements PipeTransform {

  transform(value: string): string {
    return value.replace(/<\/h1>|<\/h2>|<\/h3>/g, '\n\n').replace(/<\/p>/g, '\n').replace(/<li>/g, '\n\n\t').replace(/<[^>]*>/g, '').replace(/&nbsp;/g, '');
  }

}
