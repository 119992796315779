import { AdministrationEffects } from '@trakr-safety/administration/store/administration.effects';
import { AuthEffects } from '@trakr-safety/core/auth/store/auth.effects';
import { HealthAssessmentsEffects } from '@trakr-safety/health-screening/store/assessments/health-assessments.effects';
import { HealthQuestionsEffects } from '@trakr-safety/health-screening/store/questions/health-questions.effects';

import { CheckinsEffects } from '@trakr-safety/checkins/store/checkins.effects';
import { TenantEffects } from '@trakr-safety/tenants/store/tenant.effects';
import { AreaEffects } from '@trakr-safety/areas/store/areas.effects';
import { LocationsEffects } from 'app/locations/store/locations.effects';
import { CriteriaTypesEffects } from '@trakr-safety/criteria-types/store/criteria-types.effects';
import { QuestionTypesEffects } from '@trakr-safety/question-types/store/question-types.effects';
import { ReportsEffects } from '@trakr-safety/reports/store/reports.effects';
import { QRCardsEffects } from '@trakr-safety/qr-cards/store/qr-cards.effects';
import { AppSettingsEffects } from '@trakr-safety/core/app-settings/store/app-settings.effects';

import { HydrationEffects } from '@trakr-safety/core/store/hydration/hydration.effects';
import { QuestionsEffects } from '@trakr-safety/questions/store/questions.effects';

export const appEffects: any[] = [
  AdministrationEffects,
  AppSettingsEffects,
  AreaEffects,
  AuthEffects,
  CheckinsEffects,
  CriteriaTypesEffects,

  HealthAssessmentsEffects,
  HealthQuestionsEffects,
  LocationsEffects,
  QRCardsEffects,
  QuestionsEffects,
  QuestionTypesEffects,
  ReportsEffects,
  TenantEffects,

  HydrationEffects
];
