import { createAction, props } from '@ngrx/store';
import { QRCardModel } from '../models/qr-card.model';

/** qr card templates */
export const unloaded = createAction('[QR Cards] Un-Loaded');
export const fetchQRCardTemplates = createAction('[QR Card Templates] Fetch Card Templates');
export const setQRCardTemplates = createAction('[QR Card Templates] Set Card Templates',
  props<{
    qrCardTemplates: QRCardModel[]
  }>()
);

/** 5x7 template */
export const saveQRCardTemplate5x7 = createAction('[QR Card Template 5x7] Save',
  props<{
    qrCardTemplate: QRCardModel
  }>()
);

export const setSavedQRCardTemplate5x7 = createAction('[QR Card Template 5x7] Saved',
  props<{
    qrCardTemplates: QRCardModel[]
  }>()
);

/** 8x11 v1 */
export const saveQRCardTemplate8x11 = createAction('[QR Card Template 8x11] Save',
  props<{
    qrCardTemplate: QRCardModel
  }>()
);

export const setSavedQRCardTemplate8x11 = createAction('[QR Card Template 8x11] Saved',
  props<{
    qrCardTemplates: QRCardModel[]
  }>()
);

/** error handling */
export const error = createAction('[QR Card Templates] Error',
  props<{ error: string }>()
);



