export interface PagingModel {
  current_page: number;
  from: number;
  last_page: number;
  path: string;
  per_page: number;
  to: number;
  total: number;
}

export let NewPagingModel = {
  current_page: 0,
  from: 0,
  last_page: 0,
  path: null,
  per_page: 0,
  to: 0,
  total: 0
};
