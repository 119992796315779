import { Action, createReducer, on } from '@ngrx/store';
import { FeatureStore } from 'app/store/feature-store.enum';
import { LocationModel } from '../models/location.model';
import * as LocationsActions from './locations.actions';

export const locationsFeatureName = FeatureStore.LOCATIONS;

export interface LocationsState {
  location: LocationModel;
  loading: boolean;
  loaded: boolean;
  error: string;
}

export const initialState: LocationsState = {
  location: undefined,
  loading: false,
  loaded: false,
  error: null
};

const locationsReducer = createReducer(
  initialState,
  on(LocationsActions.fetchLocation, (state) => ({
    ...state,
    loading: true,
    loaded: false
  })),
  on(LocationsActions.isLoaded, (state) => ({
    ...state,
    loading: false,
    loaded: true
  })),
  on(LocationsActions.setLocation, (state, { location }) => ({
    ...state,
    location,
    loading: false,
    loaded: true
  })),
  on(LocationsActions.unloaded, (state) => ({
    ...state,
    loading: false,
    loaded: false
  })),
  on(LocationsActions.error, (state, { error }) => ({
    ...state,
    error,
    loading: false,
    loaded: true
  }))
);

export function reducer(state: LocationsState | undefined, action: Action) {
  return locationsReducer(state, action);
}
