import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { MyTeamScreeningReportModel } from '@trakr-safety/reports/models/my-team-screening-report.model';
import { first, timeout, retry, shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ReportHttpClient {

  constructor(private http: HttpClient) { }

  fetchMyTeamScreeningsData(): Observable<MyTeamScreeningReportModel> {
    return this.http.get<MyTeamScreeningReportModel>(environment.baseWebApiURL + 'reports/my-team/screenings').pipe(
      first(),
      timeout(10000),
      retry(4),
      shareReplay());
  }
}
