export interface AssessmentQuestionModel {
  id: number;
  parent_id: number;
  location_id: number;
  user_id: number;
  question_type_id: number;
  criteria_type_id: number;
  passValue: string;
  question: string;
  isActive: boolean;
  sequenceNo: number;
  language_id: number;
  languageCode: string;
  hasLogic: boolean;
}

export let NewAssessmentQuestionModel = {
  id: 0,
  parent_id: 0,
  location_id: 0,
  user_id: 0,
  question_type_id: 0,
  criteria_type_id: 0,
  passValue: undefined,
  question: undefined,
  isActive: false,
  sequenceNo: 0,
  language_id: 0,
  languageCode: undefined,
  hasLogic: false
};
