import { Actions, ofType, createEffect } from '@ngrx/effects';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Injectable } from '@angular/core';
import * as CriteriaTypesActions from '@trakr-safety/criteria-types/store/criteria-types.actions';
import { CriteriaTypesHttpClient } from '@trakr-safety/criteria-types/httpclients/criteria-types.http-client';
import { CriteriaTypeModel } from '@trakr-safety/criteria-types/models/criteria-type.model';

@Injectable()
export class CriteriaTypesEffects {
  constructor(
    private actions$: Actions,
    private CriteriasHttpClient: CriteriaTypesHttpClient) { }

  fetchCriteriaTypes$ = createEffect(() => this.actions$.pipe(
    ofType(CriteriaTypesActions.fetchCriteriaTypes),
    switchMap(_ =>
      this.CriteriasHttpClient.getCriteriaTypes()
        .pipe(
          map((criteriaTypes: CriteriaTypeModel[]) => {
            return CriteriaTypesActions.setCriteriaTypes({ criteriaTypes });
          }),
          catchError(error => {
            return of(CriteriaTypesActions.error({ error: error.error.message }));
          })
        ))
  ));
}
