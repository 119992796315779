import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { QuestionType } from 'app/question-types/models/question-type.model';
import { first, timeout, retry, shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class QuestionTypesHttpClient {

  constructor(private http: HttpClient) { }

  /** question types */
  getQuestionTypes(): Observable<QuestionType[]> {
    return this.http.get<QuestionType[]>(environment.baseWebApiURL + 'question_types').pipe(
      first(),
      timeout(10000),
      retry(4),
      shareReplay());
  }
}
