import { AuthState, authFeatureName } from './auth.reducer';

import { createFeatureSelector, createSelector } from '@ngrx/store';

export const getAuthFeatureState = createFeatureSelector(authFeatureName);

export const selectIsAuthenticated = createSelector(
  getAuthFeatureState,
  (state: AuthState) => state.isLoggedIn
);

export const selectIsLoggedIn = createSelector(
  getAuthFeatureState,
  (state: AuthState) => state.isLoggedIn
);

export const selectIsLoggingIn = createSelector(
  getAuthFeatureState,
  (state: AuthState) => state.isLoggingIn
);

export const selectError = createSelector(
  getAuthFeatureState,
  (state: AuthState) => state.error
);

export const checkLoginAttempt = createSelector(
  selectIsLoggingIn,
  selectIsLoggedIn,
  selectError,
  (isLoggingIn, isLoggedIn, error) => ({
    isLoggingIn, isLoggedIn, error
  })
);

export const selectPasswordResetRequestCompleted = createSelector(
  getAuthFeatureState,
  (state: AuthState) => state.passwordResetRequestCompleted
);


export const selectPasswordResetRequested = createSelector(
  getAuthFeatureState,
  (state: AuthState) => state.passwordResetRequestRequested
);

export const registerUserResponse = createSelector(
  getAuthFeatureState,
  (state: AuthState) => state.registerUserResponse
);

export const registerUserStarted = createSelector(
  getAuthFeatureState,
  (state: AuthState) => state.registerUserStarted
);

export const registerUserCompleted = createSelector(
  getAuthFeatureState,
  (state: AuthState) => state.registerUserCompleted
);

export const registerUserStatus = createSelector(
  registerUserCompleted,
  registerUserStarted,
  registerUserResponse,
  (completed, started, response) => ({ completed, started, response })
);

export const registerUserTokenValid = createSelector(
  getAuthFeatureState,
  (state: AuthState) => state.registerUserTokenValid
);

export const userEmailVerified = createSelector(
  getAuthFeatureState,
  (state: AuthState) => state.userEmailVerified
);

export const userEmailVerifiedResponse = createSelector(
  getAuthFeatureState,
  (state: AuthState) => state.userEmailVerifiedResponse
);

export const verifyUserEmailStatus = createSelector(
  userEmailVerified,
  userEmailVerifiedResponse,
  (verified, response) => ({ verified, response })
);

export const selectPasswordResetResponse = createSelector(
  getAuthFeatureState,
  (state: AuthState) => state.passwordResetResponse
);

export const selectResetTokenValid = createSelector(
  getAuthFeatureState,
  (state: AuthState) => state.resetTokenValid
);

export const selectPasswordChangeCompleted = createSelector(
  getAuthFeatureState,
  (state: AuthState) => state.passwordChangeCompleted
);

export const selectPasswordChangeResponse = createSelector(
  getAuthFeatureState,
  (state: AuthState) => state.passwordChangeResponse
);


export const selectPasswordResetRequestStatus = createSelector(
  selectPasswordResetRequestCompleted,
  selectPasswordResetRequested,
  selectPasswordResetResponse,
  (completed, requesting, response) => ({ completed, requesting, response })
);

export const selectPasswordChangeStatus = createSelector(
  selectPasswordChangeCompleted,
  selectPasswordChangeResponse,
  (completed, response) => ({ completed, response })
);

export const currentUserInfo = createSelector(
  getAuthFeatureState,
  (state: AuthState) => state.user
);


export const selectUserIdRequestCompleted = createSelector(
  getAuthFeatureState,
  (state: AuthState) => state.userIdRequestCompleted
);

export const selectUserIdRequested = createSelector(
  getAuthFeatureState,
  (state: AuthState) => state.userIdRequested
);

export const selectUserIdRequestResponse = createSelector(
  getAuthFeatureState,
  (state: AuthState) => state.userIdRequestResponse
);

export const selectUserIdRequestStatus = createSelector(
  selectUserIdRequestCompleted,
  selectUserIdRequested,
  selectUserIdRequestResponse,
  (completed, requesting, response) => ({ completed, requesting, response })
);


export const loginUsername = createSelector(
  getAuthFeatureState,
  (state: AuthState) => state.username
);

export const passwordMissing = createSelector(
  getAuthFeatureState,
  (state: AuthState) => state.passwordMissing
);

export const loginStep1 = createSelector(
  loginUsername,
  passwordMissing,
  (loginUsername, passwordMissing) => ({ loginUsername, passwordMissing })
);
