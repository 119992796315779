import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, Route, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';
import { UserModel } from '../models/user.model';
import { TenantKeyPrependPipe } from '../../pipes/tenant-key-prepend.pipe';
import { AuthFacade } from '../facades/auth.facade';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate, CanLoad {

  constructor(
    private router: Router,
    private pAuthFacade: AuthFacade,
    private tenantKeyPrependPipe: TenantKeyPrependPipe) { }

  private passRoleCheck(roles: string[]): boolean | UrlTree {
    const matchRoles = roles.join('|');
    const userData: UserModel = this.pAuthFacade.authUser;
    let a = undefined;

    if (!!userData && !!userData.roles) {
      const userRoles = userData.roles;

      if (!!roles) {
        const result = userRoles.filter((role) => matchRoles.includes(role.role_key));
        if (result.length > 0) {
          return true;
        } else {
          return this.router.parseUrl(this.tenantKeyPrependPipe.transform('/home'));
        }
      } else {
        return this.router.parseUrl(this.tenantKeyPrependPipe.transform('/home'));
      }
    }
    else {
      // no user but check for public access
      return matchRoles.includes('ROLE_PUBLIC');
    }
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean | UrlTree {
    const roles = route.data.roles as Array<string>;
    const result = this.passRoleCheck(roles);
    return result;
  }
  canLoad(
    route: Route,
    segments: UrlSegment[]): boolean | UrlTree {
    const roles = route.data.roles as Array<string>;
    const result = this.passRoleCheck(roles);
    return result;
  }
}
