import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { AppSettingsFacade } from '../../app-settings/facades/app-settings.facade';
import { LanguageFacade } from '../facades/language.facade';

@Injectable({
  providedIn: 'root'
})
export class LoadLanguagesGuard implements CanActivate {
  constructor(
    private _appSettingsFacade: AppSettingsFacade,
    private _languageFacade: LanguageFacade
  ) {

  }

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return this._appSettingsFacade.getSettingsLoaded().pipe(
      filter(([loading, loaded], index) => {
        if (!loaded && !loading && index === 0) {
          this._languageFacade.fetchLanguages();
          return false;
        }

        return !loading;
      }),
      map(([, loaded]) => loaded as boolean)
    );
  }
}
