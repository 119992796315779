import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { Router } from '@angular/router';
import { catchError, debounceTime, distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';

import { DataKeys, LocalstorageService } from '@trakr-safety/core/services/localstorage.service';
import { TenantModel } from 'app/tenants/models/tenant.model';
import * as TenantActions from './tenant.actions';
import { TenantHttpClient } from '@trakr-safety/tenants/httpclients/tenant.http-client';

@Injectable()
export class TenantEffects {

  constructor(
    private _localStorageService: LocalstorageService,
    private _router: Router,
    private _actions$: Actions,
    private _tenantHttpClient: TenantHttpClient) { }

  tenantData$ = createEffect(() => this._actions$.pipe(
    ofType(TenantActions.fetchTenant),
    switchMap((action) => this._tenantHttpClient.fetchTenant(action.tenantKey).pipe(
      map(response => response.data),
      debounceTime(300),
      distinctUntilChanged((a: TenantModel, b: TenantModel) => ((a !== null && b !== null) && (a.locationId === b.locationId))),
      map((tenantData: TenantModel) => {
        if (tenantData && tenantData.tenantKey) {

          this._localStorageService.set(DataKeys.TENANT, JSON.stringify(tenantData));
          return TenantActions.setTenant({ tenantData });
        }
        else {
          return TenantActions.failedTenantLookup();
        }

      }),
      catchError(error => of(TenantActions.error({ errorMessage: error.message })))
    )
    )
  ));

  failedTenantLookup$ = createEffect(() => this._actions$.pipe(
    ofType(TenantActions.failedTenantLookup),
    tap(() => {
      this._router.navigate(['']);
    })),
    { dispatch: false });


}
