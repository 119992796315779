import { ActionReducerMap } from '@ngrx/store';
import * as fromHealthAssessments from '@trakr-safety/health-screening/store/assessments/health-assessments.reducer';
import * as fromHealthQuestions from '@trakr-safety/health-screening/store/questions/health-questions.reducer';
import * as fromQuestionTypes from '@trakr-safety/question-types/store/question-types.reducer';
import * as fromCriteriaTypes from '@trakr-safety/criteria-types/store/criteria-types.reducer';
import * as fromAuth from '@trakr-safety/core/auth/store/auth.reducer';
import * as fromAdministration from '@trakr-safety/administration/store/administration.reducer';
import * as fromCheckins from '@trakr-safety/checkins/store/checkins.reducer';
import * as fromTenants from '@trakr-safety/tenants/store/tenant.reducer';
import * as fromAreas from '@trakr-safety/areas/store/areas.reducer';
import * as fromLocations from 'app/locations/store/locations.reducer';
import * as fromAppSettings from '@trakr-safety/core/app-settings/store/app-settings.reducer';
import * as fromReports from '@trakr-safety/reports/store/reports.reducer';
import * as fromQRCards from '@trakr-safety/qr-cards/store/qr-cards.reducer';
import * as fromQuestions from '@trakr-safety/questions/store/questions.reducer';
import { clearState, debug } from '@trakr-safety/core/store/meta.reducer';
// import { hydrationMetaReducer } from '@trakr-safety/core/store/hydration/hydration.reducer';

// global AppState
export interface AppState {
  healthAssessments: fromHealthAssessments.HealthAssessmentsState;
  healthQuestions: fromHealthQuestions.HealthQuestionsState;
  questions: fromQuestions.State;
  questionTypes: fromQuestionTypes.QuestionTypesState;
  criteriaTypes: fromCriteriaTypes.CriteriaTypesState;
  auth: fromAuth.AuthState;
  administration: fromAdministration.AdministrationState;
  checkins: fromCheckins.State;
  tenants: fromTenants.TenantState;
  areas: fromAreas.AreasState;
  locations: fromLocations.LocationsState;
  appSettings: fromAppSettings.AppSettingsState;
  reports: fromReports.ReportsState;
  qrCards: fromQRCards.QRCardsState;
}

export const appReducer: ActionReducerMap<AppState> = {
  administration: fromAdministration.reducer,
  appSettings: fromAppSettings.reducer,
  areas: fromAreas.reducer,
  auth: fromAuth.reducer,
  checkins: fromCheckins.checkinsReducer,
  criteriaTypes: fromCriteriaTypes.reducer,
  healthAssessments: fromHealthAssessments.healthAssessmentsReducer,
  healthQuestions: fromHealthQuestions.reducer,
  locations: fromLocations.reducer,
  qrCards: fromQRCards.reducer,
  questions: fromQuestions.reducer,
  questionTypes: fromQuestionTypes.reducer,
  reports: fromReports.reducer,
  tenants: fromTenants.reducer,
};

/** meta reducers */
/* these are hooks and we use one to clear the state on logout */
export const metaReducers = [
  debug,
  clearState,
  // hydrationMetaReducer
]


