import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { AssessmentQuestionModel } from '../models/assessment-question.model';
import { SortAssessmentQuestionModel } from '../models/sort-assessment-question.model';
import { QuestionType } from 'app/question-types/models/question-type.model';
import { first, timeout, retry, shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class HealthQuestionsHttpClient {

  private baseUrl: string = environment.baseWebApiURL;

  constructor(private http: HttpClient) { }

  getAssessmentQuestion(id: number): Observable<AssessmentQuestionModel> {
    return this.http.get<AssessmentQuestionModel>(`${this.baseUrl}assessment_questions/${id}`).pipe(
      first(),
      timeout(10000),
      retry(4),
      shareReplay());
  }

  fetchAllQuestions(langCode: string): Observable<AssessmentQuestionModel[]> {
    return this.http.get<AssessmentQuestionModel[]>(`${this.baseUrl}self_assessments/questions/lang/${langCode}`).pipe(
      first(),
      timeout(10000),
      retry(4),
      shareReplay());
  }

  updateAssessmentQuestion(questionToUpdate: AssessmentQuestionModel) {
    return this.http.put(`${this.baseUrl}assessment_questions/${questionToUpdate.id}`, questionToUpdate);
  }

  updateAssessmentQuestionOrderIndex(sortAssessmentQuestionModel: SortAssessmentQuestionModel, languageCode: string): Observable<AssessmentQuestionModel[]> {
    return this.http.post<AssessmentQuestionModel[]>
      (`${this.baseUrl}assessment_questions/update_sequence`, { sortAssessmentQuestionModel, languageCode });
  }

  softDeleteAssessmentQuestion(id: number) {
    return this.http.delete<AssessmentQuestionModel>(`${this.baseUrl}assessment_questions/${id}`);
  }

  createAssessmentQuestion(questionToCreate: AssessmentQuestionModel): Observable<AssessmentQuestionModel[]> {
    return this.http.post<AssessmentQuestionModel[]>(`${this.baseUrl}assessment_questions`, questionToCreate);
  }

  /** question types */
  getQuestionTypes(): Observable<QuestionType[]> {
    return this.http.get<QuestionType[]>(`${this.baseUrl}question_types`).pipe(
      first(),
      timeout(10000),
      retry(4),
      shareReplay());
  }
}
