import { createAction, props } from '@ngrx/store';
import { AppSettingsModel } from '@trakr-safety/core/app-settings/models/app-settings.model';
import { CompanyModel } from '@trakr-safety/core/models/company.model';
import { LanguageModel } from '@trakr-safety/core/language-selector/models/language.model';

export const error = createAction('[AppSettings] Error',
  props<{ error: string }>()
);

export const setKioskMode = createAction('[AppSettings] Set KioskMode',
  props<{
    kioskMode: boolean
  }>()
);

export const fetchAppSettings = createAction('[AppSettings] Fetch Settings',
  props<{
    languageCode: string,
    locationId: number
  }>()
);

export const fetchCompanies = createAction('[AppSettings] Fetch Companies');
export const setCompanies = createAction('[AppSettings] Set Companies',
  props<{ companies: CompanyModel[] }>()
);

export const fetchLanguages = createAction('[AppSettings] Fetch Languages');

export const setAppSettings = createAction('[AppSettings] Set Settings',
  props<{ settings: AppSettingsModel }>()
);

export const setLanguages = createAction('[AppSettings] Set Languages',
  props<{ languages: LanguageModel[] }>()
);

export const dummy = createAction('[AppSettings] dummy');



