import { Component, ChangeDetectionStrategy, OnInit, OnDestroy } from '@angular/core';
import { NavigationStart, Router, RouterEvent, RouterOutlet } from '@angular/router';
import { fader } from './route-animations';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { SubSink } from 'subsink';
import { NgcCookieConsentService } from 'ngx-cookieconsent';
import { AuthFacade } from '@trakr-safety/core/auth/facades/auth.facade';
import { IconsService } from '@trakr-safety/core/services/icons.service';
import { LanguageFacade } from './core/language-selector/facades/language.facade';
import { TenantFacade } from '@trakr-safety/tenants/facades/tenant.facade';
import { ThemeService } from './core/services/theme.service';
import { UserModel } from '@trakr-safety/core/auth/models/user.model';
import { MatDialog } from '@angular/material/dialog';
import { DataKeys, LocalstorageService } from './core/services/localstorage.service';
import { ServiceWorkerService } from './core/services/service-worker/service-worker.service';

import { getMessaging, getToken, onMessage, } from "firebase/messaging";

import { environment } from 'environments/environment';
import { SnackBarServiceService } from './core/services/snack-bar-service.service';
import { FcmTokenService } from './core/services/fcm-token.service';
import { FCMToken } from './core/models/fcm-token.model';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styles: [],
  animations: [
    fader,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit, OnDestroy {

  private _subs = new SubSink();
  private _currentUser: UserModel;

  constructor(

    private _authFacade: AuthFacade,
    private _cookieConsentService: NgcCookieConsentService,
    private _fcmTokenService: FcmTokenService,
    private _iconService: IconsService,
    private _languageFacade: LanguageFacade,
    private _localStorageService: LocalstorageService,
    private _router: Router,
    private _serviceWorkerService: ServiceWorkerService,
    private _snackBarService: SnackBarServiceService,
    private _tenantFacade: TenantFacade,
    private _themeService: ThemeService,

    public dialog: MatDialog
  ) { }

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData;
  }

  requestPermission() {
    const messaging = getMessaging();
    getToken(messaging,
      { vapidKey: environment.firebase.vapidKey }).then(
        (token) => {
          if (token) {
            const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

            this._fcmTokenService.saveFCMToken({ token, timezone } as FCMToken).subscribe((fcmToken: FCMToken) => {
              this._localStorageService.set('fcm_token', fcmToken.token);
            });
          } else {
            console.log('No registration token available. Request permission to generate one.');
          }
        }).catch((err) => {
          console.log('An error occurred while retrieving token. ', err);
        });
  }

  listen() {
    const messaging = getMessaging();

    onMessage(messaging, (payload) => {
      this._snackBarService.popSnack(payload.notification.body, true);
    });
  }

  ngOnInit() {

    if (!environment.isDev) {
      this.requestPermission();
      this.listen();
    }

    this._currentUser = this._authFacade.authUser;

    this._languageFacade.init();
    this._themeService.init();
    this._iconService.registerIcons();

    if (this._currentUser && this._currentUser.id) {
      this._subs.add(this._router.events.pipe(
        filter((event: RouterEvent) => event instanceof NavigationStart),
        take(1),
        map((event: NavigationStart) => {

          const pathMap: string[] = this._buildPathMap(event.url);

          // first element is an empty string, second element of the path segments is the tenant
          const incomingTenantKey = pathMap[1];

          if (incomingTenantKey) {
            if (pathMap.length === 2) {
              this._redirect(incomingTenantKey, 'home');
            }
            this._checkUserKeyAgainstIncomingKey(this._currentUser.tenantKey, incomingTenantKey);
          }
        })).subscribe());
    } else {
      this._subs.add(this._router.events.pipe(
        filter((event: RouterEvent) => event instanceof NavigationStart),
        map((event: NavigationStart) => {

          const pathMap: string[] = this._buildPathMap(event.url);

          // first element is an empty string, second element of the path segments is the tenant
          const incomingTenantKey = pathMap[1];
          if (incomingTenantKey) {
            if (pathMap.length === 2) {
              this._redirect(incomingTenantKey, 'login');
            }

            // not logged in
            if (this._localStorageService.check('tenant') && this._localStorageService.getNestKeyValue('tenant', 'tenantKey') === incomingTenantKey) {
              // keys match just pull data from session store
              this._dispatchTenantSessionData();
            }
            else {
              // change tenant key to incoming key
              this._tenantFacade.fetchTenant(incomingTenantKey);
            }

          }
        })).subscribe());


      /*  await this.pZEWidget.initZendesk(new ZendeskConfig());
        this.pZEWidget.zE('webWidget', 'show');
        this.pZEWidget.zE('webWidget', 'updateSettings', {
          position: {
            horizontal: "right",
            vertical: "bottom",
          },
          chat: {
            suppress: true
          },
          contactForm: {
            suppress: true
          },
          helpCenter: {
            suppress: false,
            filter: {
              label_names: "sanitrakr",
            },
          },
          talk: {
            suppress: true
          },
          answerBot: {
            title: {
              "*": "trakr Support",
            },
            avatar: {
              url: "assets/site/images/chatbot/chatbot5.png",
              name: {
                "*": "Trakr",
              },
            },
          },

        });

        this.pZEWidget.zE('webWidget', 'helpCenter:setSuggestions', { search: 'password' });*/
      this._serviceWorkerService.pushSubscription();
    }

    // translate cookie consent
    // listener for language selector to translate cookie consent
    this._subs.add(this._languageFacade.translateService.onLangChange.pipe(
      switchMap(() => this._translateCookieConsent())
    ).subscribe());

    // cookie consent
    // subscribe to cookieconsent observables to react to main events
    this._subs.add(this._cookieConsentService.popupOpen$.subscribe(
      () => {
        // you can use this._cookieConsentService.getConfig() to do stuff...
      }));

    this._subs.add(this._cookieConsentService.popupClose$.subscribe(
      () => {
        // you can use this._cookieConsentService.getConfig() to do stuff...
      }));

    this._subs.add(this._cookieConsentService.initialize$.subscribe(
      () => {
        // you can use this._cookieConsentService.getConfig() to do stuff...
      }));

    this._subs.add(this._cookieConsentService.statusChange$.subscribe(
      () => {
        // you can use this._cookieConsentService.getConfig() to do stuff...
      }));

    this._subs.add(this._cookieConsentService.revokeChoice$.subscribe(
      () => {
        // you can use this._cookieConsentService.getConfig() to do stuff...
      }));

    this._subs.add(this._cookieConsentService.noCookieLaw$.subscribe(
      () => {
        // you can use this._cookieConsentService.getConfig() to do stuff...
      }));
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }

  private _redirect(tenantKey: string, url: string): void {
    this._router.navigateByUrl(`/${tenantKey}/${url}`);
  }

  private _buildPathMap(eventUrl: string): string[] {
    const url = eventUrl === '/' ? '' : eventUrl;
    const urlAndQuery = url.split('?');
    const pathMap = urlAndQuery[0].split('/');
    return pathMap;
  }
  /** updates app state with tenant data already stored in session */
  private _dispatchTenantSessionData() {
    this._tenantFacade.setTenant(JSON.parse(this._localStorageService.get(DataKeys.TENANT)));
  }

  // check current logged in users tenant key against incoming
  private _checkUserKeyAgainstIncomingKey(userKey: string, incomingKey: string) {

    if (userKey !== '' && userKey === incomingKey) {
      // users key and incoming match pull data from session
      this._dispatchTenantSessionData();
      this._authFacade.autoLogin(); // autologin
    } else {
      // mismatch force logout and redirect for new key
      this._authFacade.logout();
      this._tenantFacade.fetchTenant(incomingKey);
      this._router.navigate([`/${incomingKey}/login`]);
    }
  }

  private _translateCookieConsent() {
    this._subs.add(this._languageFacade.translateService
      .get(['cookie.header', 'cookie.message', 'cookie.dismiss', 'cookie.allow', 'cookie.deny', 'cookie.link', 'cookie.policy'])
      .subscribe(data => {

        this._cookieConsentService.getConfig().content = this._cookieConsentService.getConfig().content || {};
        // Override default messages with the translated ones
        this._cookieConsentService.getConfig().content.header = data['cookie.header'];
        this._cookieConsentService.getConfig().content.message = data['cookie.message'];
        this._cookieConsentService.getConfig().content.dismiss = data['cookie.dismiss'];
        this._cookieConsentService.getConfig().content.allow = data['cookie.allow'];
        this._cookieConsentService.getConfig().content.deny = data['cookie.deny'];
        this._cookieConsentService.getConfig().content.link = data['cookie.link'];
        this._cookieConsentService.getConfig().content.policy = data['cookie.policy'];
        this._cookieConsentService.getConfig().position = "bottom";
        this._cookieConsentService.getConfig().theme = "classic";
        this._cookieConsentService.destroy(); // remove previous cookie bar (with default messages)
        this._cookieConsentService.init(this._cookieConsentService.getConfig()); // update config with translated messages
      }));
    return [];
  }
}
