import { Action } from '@ngrx/store';
import { CheckinModel } from '@trakr-safety/checkins/models/checkin.model';
import { ContactTracingSettingsModel } from '@trakr-safety/administration/models/ContactTracingSettings.model';

/** checkin actions */
export const CHECKIN_LOOKUP = '[Checkin] Lookup';
export const NEW_CHECKIN = '[Checkin] New';
export const SAVE_CHECKIN = '[Checkin] Save';
export const FETCH_CHECKIN = '[Checkin] Fetch';

export const TAG_AREA_TO_CHECKIN = 'TAG_AREA_TO_CHECKIN';

export const FETCH_DASH_SUMMARY = '[Checkins] FETCH_DASH_SUMMARY';
export const ERROR = '[Checkins] Error';

/** shared actions */
export const REPLACE_STATE = '[Checkins] REPLACE_STATE';
export const CLEAR_STATE = '[Checkins] CLEAR_STATE';
export const SET_FILTER_STRING = '[Checkins] SET_FILTER_STRING';

/** report actions  */

export const FETCH_ALL_TIME_REPORT = '[Checkins] Fetch All Time Report Data';
export const FETCH_MONTH_REPORT = '[Checkins] Fetch Month Data';

/** shared action methods */
export class ReplaceState implements Action {
  readonly type = REPLACE_STATE;
  constructor(public payload: {
    checkins?: CheckinModel[],
    checkin?: CheckinModel,
    error?: string,
    contactTracingSettings?: ContactTracingSettingsModel,
    weekReviewDash?: CheckinModel[],
    filterString?: string,
  }) { }
}

export class ClearState implements Action {
  readonly type = CLEAR_STATE;
}

/** checkin action methods */
export class NewCheckin implements Action {
  readonly type = NEW_CHECKIN;
}

export class SaveCheckin implements Action {
  readonly type = SAVE_CHECKIN;
  constructor(public payload: { checkinData: CheckinModel, tenantKey: string }) { }
}

export class FetchCheckin implements Action {
  readonly type = FETCH_CHECKIN;
  constructor(public payload: number) { }
}

export class FetchMonthReport implements Action {
  readonly type = FETCH_MONTH_REPORT;
}

export class FetchAllTimeReport implements Action {
  readonly type = FETCH_ALL_TIME_REPORT;
}

export class CheckinLookup implements Action {
  readonly type = CHECKIN_LOOKUP;
}

export class Error implements Action {
  readonly type = ERROR;
  constructor(public payload: string) { }
}

export class FetchDashSummary implements Action {
  readonly type = FETCH_DASH_SUMMARY;
}

export class TagAreaToCheckin implements Action {
  readonly type = TAG_AREA_TO_CHECKIN;
  constructor(public payload: {
    locationId: number,
    areaId: number,
    contactTraceId: number,
    isKioskMode: boolean,
    tenantKey: string,
    timezone: string
  }) { }
}

export class SetFilterString implements Action {
  readonly type = SET_FILTER_STRING;
  constructor(public payload: string) { }
}
export type CheckinActions =
  FetchDashSummary | SetFilterString | TagAreaToCheckin |
  FetchCheckin | FetchMonthReport | FetchAllTimeReport | NewCheckin | SaveCheckin |
  ReplaceState | ClearState | Error;
