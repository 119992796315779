import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ToastrService } from 'ngx-toastr';
import { SubSink } from 'subsink';
import { LanguageFacade } from '../language-selector/facades/language.facade';

@Injectable({
  providedIn: 'root'
})
export class SnackBarServiceService {
  private subs = new SubSink();
  snackBar: MatSnackBar
  constructor(
    public toasterService: ToastrService,
    private _languageFacade: LanguageFacade,
    _snackBar: MatSnackBar) {
    this.snackBar = _snackBar;
  }
  popSnack(message: string, translate = false) {
    if (!translate) {
      this.toasterService.success(message);
    }
    else {
      this.subs.add(this._languageFacade.translate(message).subscribe(
        res => {
          this.toasterService.success(res);
        }
      ));
    }
  }
}
