import { Action, createReducer, on } from '@ngrx/store';
import { CriteriaTypeModel } from '@trakr-safety/criteria-types/models/criteria-type.model';
import * as CriteriaTypesActions from '@trakr-safety/criteria-types/store/criteria-types.actions';

export const criteriaTypesFeatureName = 'criteriaTypes';

export interface CriteriaTypesState {
  criteriaTypes: CriteriaTypeModel[];
  loading: boolean;
  loaded: boolean;
  error: string;
}

export const initialState: CriteriaTypesState = {
  criteriaTypes: [],
  loading: false,
  loaded: false,
  error: null
};

const criteriaTypesReducer = createReducer(
  initialState,
  on(CriteriaTypesActions.unloaded, (state) => ({
    ...state,
    loading: false,
    loaded: false
  })),
  on(CriteriaTypesActions.fetchCriteriaTypes, (state) => ({
    ...state,
    loading: true,
    loaded: false
  })),
  on(CriteriaTypesActions.setCriteriaTypes, (state, { criteriaTypes }) => ({
    ...state,
    criteriaTypes,
    loading: false,
    loaded: true
  })),
  on(CriteriaTypesActions.error, (state, { error }) => ({
    ...state,
    error,
    loading: false,
    loaded: true
  }))
);

export function reducer(state: CriteriaTypesState | undefined, action: Action) {
  return criteriaTypesReducer(state, action);
}
