import { LanguageModel } from '@trakr-safety/core/language-selector/models/language.model';
import { UserCategoryModel } from '@trakr-safety/core/models/user-category.model';
import { Action, createReducer, on } from '@ngrx/store';
import * as SettingsActions from './app-settings.actions';
import { RoleModel } from '@trakr-safety/core/auth/models/role.model';
import { UserTypeModel } from '@trakr-safety/core/auth/models/user_type.model';
import { ManageUserModel } from '@trakr-safety/users/models/manage-user.model';
import { CompanyModel } from '@trakr-safety/core/models/company.model';

export const appSettingsFeatureName = 'appSettings';

export interface AppSettingsState {
  kioskMode: boolean;
  languages: LanguageModel[];
  userCategories: UserCategoryModel[];
  managers: ManageUserModel[];
  userTypes: UserTypeModel[];
  roles: RoleModel[];
  companies: CompanyModel[];
  language_id: number;
  language: string;
  enableRegistration: boolean;
  enableContactTracing: boolean;
  enableShowVerifiedOnPass: boolean;
  enableVaccinationAttestation: boolean;
  enableVaccinationVerify: boolean;
  enableVaccinationTracking: boolean;
  vaccinationTrackingLink: string | undefined | null;
  healthAffirmation: string;
  enableHealthAffirmation: boolean;
  enableAdditionalGuestInfo: boolean;
  failedScreeningMessage: string;
  loaded: boolean;
  loading: boolean;
  error: string;
}

export const initialState: AppSettingsState = {
  kioskMode: false,
  languages: [],
  userCategories: [],
  managers: [],
  userTypes: [],
  roles: undefined,
  companies: undefined,
  language_id: 0,
  language: undefined,
  enableRegistration: false,
  enableContactTracing: false,
  enableShowVerifiedOnPass: false,
  enableVaccinationVerify: false,
  enableVaccinationAttestation: false,
  enableVaccinationTracking: false,
  vaccinationTrackingLink: undefined,
  healthAffirmation: undefined,
  enableHealthAffirmation: false,
  enableAdditionalGuestInfo: false,
  failedScreeningMessage: undefined,
  loaded: false,
  loading: false,
  error: undefined
};

const appSettingsReducer = createReducer(
  initialState,
  on(SettingsActions.setKioskMode, (state, { kioskMode }) => ({
    ...state,
    kioskMode
  })),
  on(SettingsActions.setAppSettings, (state, { settings }) => ({
    ...state,
    ...settings,
    loading: false,
    loaded: true
  })),
  on(SettingsActions.setCompanies, (state, { companies }) => ({
    ...state,
    ...companies,
    loading: false,
    loaded: true
  })),
  on(SettingsActions.fetchLanguages, (state) => ({
    ...state,
    loading: true,
    loaded: false
  })),
  on(SettingsActions.setLanguages, (state, { languages }) => ({
    ...state,
    languages,
    loading: false,
    loaded: true
  })),
  on(SettingsActions.fetchAppSettings, (state) => ({
    ...state,
    loading: true,
    loaded: false
  })),
  on(SettingsActions.error, (state, { error }) => ({
    ...state,
    error
  }))
);

export function reducer(state: AppSettingsState | undefined, action: Action) {
  return appSettingsReducer(state, action);
}
