import { UserModel } from '@trakr-safety/core/auth/models/user.model';
import { createAction, props } from '@ngrx/store';
import { RegistrationModel } from '@trakr-safety/core/auth/models/registration.model';

export const loginStart = createAction('[Auth] Login Start',
  props<{ username: string, password: string, tenantKey: string }>()
);

export const autoLoginStart = createAction('[Auth] Auto Login Start');
export const autoLogin = createAction('[Auth] Auto Login',
  props<{ user: UserModel }>()
);
export const autoLoginComplete = createAction('[Auth] Auto Login Complete',
  props<{ user: UserModel }>()
);

export const loginComplete = createAction('[Auth] Login Complete',
  props<{ user: UserModel }>()
);

export const error = createAction('[Auth] Error',
  props<{ error: string }>()
);

export const errorPasswordChange = createAction('[Auth] change password error',
  props<{ error: string }>()
);

export const errorPasswordResetRequest = createAction('[Auth] Password Request Error',
  props<{ error: string }>()
);

export const logout = createAction('[Auth] Logout');
export const clearError = createAction('[Auth] Clear Error');

export const registerUser = createAction('[Auth] Register User',
  props<{ registration: RegistrationModel }>()
);

export const registerUserCompleted = createAction('[Auth] Register User Complete',
  props<{ registerUserResponse: string }>()
);

export const clearRegisterUserRequest = createAction('[Auth] clear Register User Request');

export const validateRegisterUserToken = createAction('[Auth] Validate Register User Token',
  props<{ token: string }>()
);

export const setRegisterUserTokenValid = createAction('[Auth] set register user token valid',
  props<{ registerUserTokenValid: boolean, userEmailVerifiedResponse: string }>()
);


export const checkPasswordRequired = createAction('[Auth] Check Password Required',
  props<{ tenantKey: string, username: string }>()
);

export const setPasswordMissing = createAction('[Auth] Set Password Missing',
  props<{ passwordMissing: boolean }>()
);

export const validateResetToken = createAction('[Auth] Validate Reset Token',
  props<{ token: string }>()
);

export const checkAuth = createAction('[Auth] check auth');
export const checkAuthComplete = createAction('[Auth] check auth complete',
  props<{ isLoggedIn: boolean }>()
);

export const clearPasswordResetRequest = createAction('[Auth] clear Password Reset Request');

export const requestUserIdEmail = createAction('[Auth] request user id email',
  props<{ email: string, tenantKey: string }>()
);

export const requestUserIdComplete = createAction('[Auth] userId completed',
  props<{ userIdRequestResponse: string }>()
);

export const clearUserIdRequest = createAction('[Auth] clear UserId request');

export const requestResetPasswordLink = createAction('[Auth] request reset password link',
  props<{ username: string, tenantKey: string }>()
);

export const requestResetPasswordLinkComplete = createAction('[Auth] request reset password link completed',
  props<{ passwordResetResponse: string }>()
);

export const changePassword = createAction('[Auth] change password',
  props<{ password: string, token: string }>()
);

export const changePasswordComplete = createAction('[Auth] change password complete',
  props<{ passwordChangeResponse }>()
);

export const setResetTokenValid = createAction('[Auth] set reset token valid',
  props<{ resetTokenValid: boolean }>()
);

export const convertNonPasswordAccount = createAction('[Auth] convert non password account',
  props<{ password: string, username: string, tenantKey: string }>()
);

export const convertNonPasswordAccountComplete = createAction('[Auth] convert non password account complete',
  props<{ convertNonPasswordAccountResponse }>()
);


export const dummy = createAction('[Auth] dummy');
