import { Action, createReducer, on } from '@ngrx/store';
import * as ReportsAction from '@trakr-safety/reports/store/reports.actions';
import { MyTeamScreeningReportModel } from '@trakr-safety/reports/models/my-team-screening-report.model';

export const reportsFeatureName = 'reports';

export interface ReportsState {
  myTeamScreeningReport: MyTeamScreeningReportModel;
  loading: boolean;
  loaded: boolean;
  error: string;
}

export const initialState: ReportsState = {
  myTeamScreeningReport: undefined,
  loading: undefined,
  loaded: undefined,
  error: undefined
};

const reportsReducer = createReducer(
  initialState,
  on(ReportsAction.unloaded, (state) => ({
    ...state,
    loading: false,
    loaded: false
  })),
  on(ReportsAction.fetchMyTeamReport, (state) => ({
    ...state,
    loading: true,
    loaded: false
  })),
  on(ReportsAction.setMyTeamReport, (state, { myTeamScreeningReport }) => ({
    ...state,
    myTeamScreeningReport,
    loading: false,
    loaded: true
  })),
  on(ReportsAction.error, (state, { error }) => ({
    ...state,
    error,
    loading: false,
    loaded: true
  }))
);

export function reducer(state: ReportsState | undefined, action: Action) {
  return reportsReducer(state, action);
}
