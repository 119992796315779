import { createAction, props } from '@ngrx/store';
import { LocationModel } from '../models/location.model';

export const fetchLocation = createAction('[Organizations] Fetch Location',
  props<{
    id: number
  }>()
);

export const setLocation = createAction('[Organizations] Set Location',
  props<{
    location: LocationModel
  }>()
);

export const isLoaded = createAction('[Organizations] Is Loaded');
export const unloaded = createAction('[Organizations] Un-Loaded');

export const error = createAction('[Organizations] Error',
  props<{ error: string }>()
);
