import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppErrorModel } from '@trakr-safety/core/models/app-error.model';

@Component({
  selector: 'app-application-error-dialog',
  template: `
    <h1 mat-dialog-title>{{ 'dialogs.app_error.title' | translate }}</h1>
<mat-dialog-content>
<ng-container *ngIf="(appError.message | typeof) === 'string'">
    <mat-icon class="color_orange mr-10">warning</mat-icon
    ><span [innerHtml]="appError.message"></span>
  </ng-container>
  <ng-container *ngIf="(appError.message | typeof) === 'object'">
    <p
      fxLayout="row"
      fxLayoutAlign="center center"
      *ngFor="let item of appError.message | keyvalue"
    >
      <mat-icon class="color_orange">warning</mat-icon>
      {{ item.value }}
    </p></ng-container
  >
</mat-dialog-content>
<mat-dialog-actions
  fxLayout="row"
  fxLayoutAlign="center center"
  fxLayoutGap="20px"
  class="pb-30"
>
  <button mat-raised-button color="accent" [mat-dialog-close]="true">
    {{ "form.buttons.ok.text" | translate }}
  </button>
</mat-dialog-actions>

  `,
  styles: [
  ]
})
export class ApplicationErrorDialogComponent implements OnInit {

  appError: AppErrorModel;

  constructor(private dialogRef: MatDialogRef<ApplicationErrorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public incomingData: any) {
    this.appError = incomingData;
  }

  onCloseClick(): void {
    this.handleCloseDialog();
  }

  private handleCloseDialog() {
    this.dialogRef.close();
  }

  ngOnInit(): void {
  }

}
