import { Actions, ofType, createEffect } from '@ngrx/effects';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Injectable } from '@angular/core';
import * as ReportsActions from '@trakr-safety/reports/store/reports.actions';
import { ReportHttpClient } from '@trakr-safety/reports/httpclient/reports.http-client';
import { MyTeamScreeningReportModel } from '@trakr-safety/reports/models/my-team-screening-report.model';


@Injectable()
export class ReportsEffects {
  constructor(
    private actions$: Actions,
    private httpClient: ReportHttpClient) { }

  fetchCriteriaTypes$ = createEffect(() => this.actions$.pipe(
    ofType(ReportsActions.fetchMyTeamReport),
    switchMap(_ =>
      this.httpClient.fetchMyTeamScreeningsData()
        .pipe(
          map((myTeamScreeningReport: any) => myTeamScreeningReport.data),
          map((reportData: MyTeamScreeningReportModel) => {
            return ReportsActions.setMyTeamReport({ myTeamScreeningReport: reportData });
          }),
          catchError(error => {
            return of(ReportsActions.error({ error: error.error.message }));
          })
        ))
  ));
}
