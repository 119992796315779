/*import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { AppErrorModel } from '../models/app-error.model';

@Injectable({
  providedIn: 'root'
})
export class AppErrorService {
  private appErrorSubject$: Subject<AppErrorModel>;
  public appError$: Observable<AppErrorModel>;
  constructor() {
    this.appErrorSubject$ = new Subject<AppErrorModel>();
    this.appError$ = this.appErrorSubject$.asObservable();
  }

  clearError() {
    this.appErrorSubject$.next(null);
  }

  emitError(appError: AppErrorModel) {
    this.appErrorSubject$.next(appError);
  }

}*/







import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, Observable } from 'rxjs';
import { SubSink } from 'subsink';
import { ApplicationErrorDialogComponent } from '../components/dialogs/application-error-dialog/application-error-dialog.component';
import { AppErrorModel } from '../models/app-error.model';

@Injectable({
  providedIn: 'root'
})
export class AppErrorService {

  private appErrorSubject$: BehaviorSubject<AppErrorModel> = new BehaviorSubject(null);

  public readonly appError$: Observable<AppErrorModel> = this.appErrorSubject$.asObservable();

  private subs = new SubSink();

  constructor(private dialog: MatDialog) {
  }

  clearError() {
    this.appErrorSubject$.next(null);
  }

  emitError(appError: AppErrorModel) {
    //  this.appErrorSubject$.next(appError);
    this.openDialog(appError);
  }

  private openDialog(appError: AppErrorModel): void {

    const dialogRef = this.dialog.open(ApplicationErrorDialogComponent, {
      data: appError,
      width: 'auto',
      minHeight: '250px',
      height: 'auto'
    });

  }
}








