import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { AreaModel } from '../models/area.model';
import { AttendAreaModel } from '@trakr-safety/areas/models/attend-area.model';
import { first, timeout, retry, shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AreasHttpClient {

  constructor(private http: HttpClient) { }

  getArea(id: number): Observable<AreaModel> {
    return this.http.get<AreaModel>(environment.baseWebApiURL + 'areas/' + id).pipe(
      first(),
      timeout(10000),
      retry(4),
      shareReplay());
  }

  traceArea(id: number, locationId: number): Observable<AreaModel> {
    return this.http.post<AreaModel>(`${environment.baseWebApiURL}area/trace`, { id, location_id: locationId });
  }

  getAreas(): Observable<AreaModel[]> {
    return this.http.get<AreaModel[]>(environment.baseWebApiURL + 'areas').pipe(
      first(),
      timeout(10000),
      retry(4),
      shareReplay());
  }

  getAreasPublic(locationId: number): Observable<AreaModel[]> {
    return this.http.get<AreaModel[]>(`${environment.baseWebApiURL}area/selector/${locationId}`).pipe(
      first(),
      timeout(10000),
      retry(4),
      shareReplay());
  }

  getTraceAreasDashboard(): Observable<AreaModel[]> {
    return this.http.get<AreaModel[]>(environment.baseWebApiURL + 'areas/trace/dashboard').pipe(
      first(),
      timeout(10000),
      retry(4),
      shareReplay());
  }

  getTraceAreasAllTimeReport(): Observable<AttendAreaModel[]> {
    return this.http.get<AttendAreaModel[]>(environment.baseWebApiURL + 'areas/trace/all-time-report').pipe(
      first(),
      timeout(10000),
      retry(4),
      shareReplay());
  }

  getTraceAreasMonthReportPaged(
    filter = '', search = '', sortCol = '',
    sortOrder = 'desc', page = 0, pageSize = 10, date = '', fromTime = '', toTime = '') {
    return this.http.get(`${environment.baseWebApiURL}areas/trace/month-report`,
      {
        params: new HttpParams()
          .set('filter', filter)
          .set('search', search)
          .set('sortcol', sortCol)
          .set('sortorder', sortOrder)
          .set('page', page.toString())
          .set('pagesize', pageSize.toString())
          .set('date', date)
          .set('fromtime', fromTime)
          .set('totime', toTime)
      }).pipe(
        first(),
        timeout(10000),
        retry(4),
        shareReplay());
  }

  updateArea(area: AreaModel) {
    return this.http.put(environment.baseWebApiURL + 'areas/' + area.id, area);
  }

  softDeleteArea(id: number) {
    return this.http.delete<AreaModel>(environment.baseWebApiURL + 'areas/' + id);
  }

  createArea(area: AreaModel): Observable<AreaModel> {
    return this.http.post<AreaModel>(environment.baseWebApiURL + 'areas', area);
  }

  deleteArea(area: AreaModel) {
    area.isEnabled = false;
    return this.updateArea(area);
  }
}
